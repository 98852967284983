.account-subtitle {
    @include font-gotham-medium;

    font-size: rem-calc(14);
    margin-bottom: 1.75rem;

    @include breakpoint(large up) {
        font-size: rem-calc(18);
        margin-bottom: 3rem;
    }

    .link {
        @include font-gotham-bold;

        font-size: rem-calc(13);
    }
}

.account-section-heading {
    @include font-gotham-bold;
    display: inline-block;
    margin: 25px 0;

    &.top-heading {
        margin-top: 10px;
    }

    + .back {
        margin-top: -1.3rem;
    }
}

.modify-appointment-button {
    margin-top: 30px;
    max-width: 208px;

    .modify-account {
        align-items: center;
        display: flex;
        height: 28px;
        text-align: center;
    }
}

.account-section {
    padding-bottom: 3rem;

    @include breakpoint(medium up) {
        display: flex;
        padding-top: 3rem;
    }

    .nav-wrapper.columns {
        @include breakpoint(small only) {
            padding: 0;
        }
    }

    .content-wrapper {
        @include breakpoint(small only) {
            padding: 25px 15px;
        }
    }

    .my-garage-page-title {
        margin-bottom: 15px;
    }

    .order-history-description {
        font-size: rem-calc(16);
        margin-bottom: 25px;

        @include breakpoint(medium up) {
            max-width: 75%;
        }
    }

    .back {
        @include font-gotham-bold;

        font-size: rem-calc(13);
        margin-bottom: 1.75rem;

        a::before {
            content: "\f053";
            display: inline-block;
            font-family: fontawesome;
            font-size: 0.5rem;
            line-height: 0.9375rem;
            vertical-align: middle;
        }
    }

    .button-group {
        align-items: center;
        display: flex;
        flex-basis: 25%;
        width: 100%;

        .button-link {
            color: map-get($foundation-palette, secondary);
            margin-left: 15px;
            text-align: center;
        }

        .button-link,
        .button {
            flex-basis: 30%;
            margin-top: 0;
            width: auto;

            @include breakpoint(medium up) {
                flex-basis: 20%;
            }
        }
    }

    .order-history-footer {
        margin-top: 20px;

        .here-to-help {
            display: flex;
            flex-wrap: wrap;
        }

        .action-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .orderSummaryLiveChat,
        .phone-number {
            @include font-gotham-bold;

            color: map-get($foundation-palette , primary);
        }

        .action-icon {
            .card-icon {
                max-height: 16px;
            }
        }

        .main-section-title {
            font-size: 18px;
        }
    }

    .modify-appointment-important {
        @include font-gotham-bold;

        color: map-get($foundation-palette, primary);
    }

    .modify-appointment-info {
        padding: 1rem 1.25rem 0.1rem;

        @include breakpoint(medium up) {
            padding: 1.875rem;
        }
    }

    .no-orders-selectors {
        margin: 0 -10px;

        .selector-container {
            align-self: stretch;
            padding: 10px;
        }

        .no-order-selector-tab {
            align-items: center;
            background: map-get($foundation-palette, light-gray);
            cursor: pointer;
            display: flex;
            height: 100%;
            margin-top: 15px;
            padding: 15px;
            position: relative;

            &:hover::before {
                border-left: 10px solid map-get($foundation-palette, primary);
                bottom: 0;
                content: "";
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .selector-image {
            float: left;
            min-width: 120px;
            overflow: hidden;
            padding-right: 15px;
            width: 120px;
        }

        .selector-tab-title {
            font-size: 20px;
            text-transform: uppercase;
        }
    }
}

.account-block-border {
    margin-bottom: 1.25rem;

    &.personal-info {
        border-bottom: 1px solid #f2f2f3;
        position: relative;

        @include breakpoint(medium up) {
            padding-top: 0;
        }
    }

    &.order-block {
        border: 1px solid map-get($foundation-palette, bright-grey);
    }

    .account-info-title {
        @include font-gotham-bold;

        margin-bottom: 0;
    }

    label {
        @include font-gotham-bold;

        line-height: 1.5;
        margin: 0;

        .top-label {
            font-size: 15px;

            a {
                font-size: 13px;
            }
        }
    }

    .account-info {
        @include font-gotham-book;

        font-size: 0.875rem;
    }

    .register-form {
        display: none;
        margin: 2rem 0 0;

        label {
            @include font-gotham-medium;
        }
    }

    &.expanded {
        .register-form {
            display: block;
        }

        .account-info {
            display: none;
        }

        .register-form-toggle {
            display: none;
        }
    }

    .ps-results-image-tip {
        border: 0;
        display: block;
        font-weight: normal;

        
    }

    .email-address-info {
        border: 1px solid $medium-gray;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 10px 15px;
        position: relative;
        width: 100%;

        @include breakpoint(medium up) {
            width: 80%;
        }
    }
}

.account-email-tooltip {
    .tooltip-phone {
        color: map-get($foundation-palette, primary);
        font-weight: bold;
    }
}

.secondary-navigation {
    @include breakpoint(small only) {
        &.account-page .mobile-account-label .account-page,
        &.order-page .mobile-account-label .order-page {
            display: block;
        }
    }

    .menu-items-wrapper {
        display: flex;
        flex-direction: column;

        @include breakpoint(small only) {
            &.expanded {
                background-color: map-get($foundation-palette, light-gray);
                padding-bottom: 30px;
            }
        }
    }

    .menu.vertical > li.menu-item {
        @include breakpoint(medium up) {
            &.highlighted a {
                color: map-get($foundation-palette, primary-accessible);
                font-weight: 700;
                text-decoration: underline;
            }
        }

        @include breakpoint(small only) {
            background-color: map-get($foundation-palette, white-default);
            border-bottom: 1px solid map-get($foundation-palette, border-grey);
            display: none;
            padding: 0 20px 0 30px;

            .toggle-icon {
                display: none;
            }

            &.highlighted {
                align-items: center;
                background-color: map-get($foundation-palette, primary);
                display: flex;
                justify-content: space-between;

                .toggle-icon {
                    display: block;
                }
            }
        }
    }

    .menu li {
        a {
            @include font-gotham-medium;

            color: map-get($foundation-palette, secondary);
            font-size: 16px;
            margin: 10px 0;
            padding: 0;

            @include breakpoint(small only) {
                margin: 15px 0;
            }

            &:hover {
                background: transparent;
                text-decoration: underline;
            }
        }
    }

    &.account-page {
        .menu-item {
            @include breakpoint(small only) {
                order: 2;

                &.account-page {
                    order: 1;
                }
            }
        }
    }

    &.order-page {
        .menu-item {
            @include breakpoint(small only) {
                order: 2;

                &.order-page {
                    order: 1;
                }
            }
        }
    }

    &.carfax-history-page {
        .menu-item {
            @include breakpoint(small only) {
                order: 2;

                &.carfax-history-page {
                    order: 1;
                }
            }
        }
    }
}

.mobile-account-menu {
    border: 1px solid $medium-gray;
    font-size: 13px;
    margin-bottom: 1.25rem;
    padding: 0.75rem;

    li a {
        color: map-get($foundation-palette, secondary);
        padding: 0.3125rem 0 0.625rem;
    }

    &:hover,
    .active-submenu {
        border-color: map-get($foundation-palette, secondary);
    }
}

.menu.mobile-account-list {
    margin: 0.75rem 0.3125rem 0;
}

.menu li .mobile-account-label {
    padding: 0;

    span {
        display: none;
    }

    &::after {
        color: map-get($foundation-palette, secondary);
        content: "\f0dc";
        font-family: fontawesome;
        font-size: 14px;
        right: -2px;
        top: 0.25rem;
    }
}

.button-group {
    &.no-bot-margin {
        @include breakpoint(small only) { 
            margin: 0;
        }
    }
}

.account-block-grey {
    background: map-get($foundation-palette, light-gray);
    margin-bottom: 1.25rem;
    padding: 1rem 1.25rem 0.1rem;

    @include breakpoint(medium up) {
        padding: 1.875rem 1.875rem 0.5rem;
    }

    .openLiveChatButton {
        b {
            font-weight: bold !important;
        }
    }

    b {
        @include breakpoint(small only) { 
            @include font-gotham-medium;

            font-weight: normal;
        }
    }

    h5 {
        margin-bottom: 0.75rem;

        @include breakpoint(small only) { 
            font-size: 16px;
            line-height: 21px;
        }

        @include breakpoint(medium up) {
            margin-bottom: 0.375rem;
        }
    }

    p {
        font-size: 14px;

        a {
            @include breakpoint(medium up) {
                margin: 1.75rem 0 0.25rem;
            }

            &:not(.phone-link) {
                display: block;

                @include breakpoint(small only) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.bottom-space-mobile {
    @include breakpoint(small only) {
        margin-bottom: 1.25rem;
    }
}

.button-link {
    @include font-gotham-bold;

    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    margin-top: 0.625rem;
    padding: 0.75rem 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;

    @include breakpoint(medium up) {
        margin: 0 0 0 1.25rem;
        text-align: left;
        width: auto;
    }
}

.pt_account,
.pt_order {
    .phone-link {
        @include font-gotham-medium;
        margin: 0;


        @include breakpoint(medium up) {
            @include font-gotham-book;

            color: map-get($foundation-palette, secondary);

            &:hover {
                color: map-get($foundation-palette, secondary);
            }
        }
    }
}

// Common styles for Order history and Order details pages
.orderdetails .order-shipments,
.order-history-list {
    .product-brand {
        @include font-gotham-bold;
        display: block;

        font-size: rem-calc(14);
        line-height: 1;
    }

    .product-name {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1;

        @include breakpoint(large up) {
            font-size: rem-calc(18);
        }
    }
}

// Order history
.order-history-list {
    margin-left: 0;

    .label {
        @include font-gotham-bold;
        background: transparent;
        color: map-get($foundation-palette, secondary);

        display: block;
        font-size: 15px;
        padding: 0;

        .fa {
            font-size: 18px;
        }

        .fa-calendar-o {
            margin: 0 0.3125rem 0 0;
        }
    }

    .accordion-order-header {
        @include breakpoint(small down) {
            margin: 15px 0;
        }
    }

    .order-history-items {
        padding-top: 20px;

        @include breakpoint(medium up) {
            padding-top: 25px;
        }

        .row {
            @include breakpoint(medium up) {
                width: 100%;
            }
        }

        .columns {
            @include breakpoint(small only) {
                padding: 0;
            }
        }

        .order-totals {
            border-top: 1px solid $medium-gray;
            margin: 0;

            .order-value {
                display: flex;
            }

            .total-value-row {
                @include font-gotham-bold;
            }

            .order-totals-row,
            .row {
                margin: 0;
            }
        }

        .order-info {

            @include breakpoint(medium up) {
                padding-top: 25px;
            }

            .order-info-row {
                margin: 0;

                .row {
                    margin: 0;
                }
            }

            .main-section-header {
                display: none;
            }

            .line-items {
                padding: 0;

                .product-row {
                    margin-left: 0;
                    margin-right: 0;
                }

                .orderdetails-productvalues,
                .orderdetails-productname {
                    padding: 0;
                }

                .row {
                    align-items: center;
                    margin: 10px 0;
                }
            }

            .order-payment-summary {
                border-bottom: 0;
                border-left: 0;
                border-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .order-info,
        .order-totals {
            @include font-gotham-book;
            color: map-get($foundation-palette, secondary);

            padding-top: 20px;
        }

        .value-column {
            text-align: right;
        }
    }

    .order-history-header {
        display: flex;
        flex-wrap: wrap;

        button {
            margin-top: 1.625rem;
            width: 100%;

            @include breakpoint(medium up) {
                width: auto;
            }
        }

        .label {
            margin-bottom: 0.375rem;
        }

        .value {
            @include font-gotham-book;
            color: map-get($foundation-palette, secondary);

            display: block;
            font-size: 14px;
            margin-bottom: 1rem;

            &.paid-online {
                margin: 0;
            }
        }
        
        .order-header-info {
            border-bottom: 1px solid $medium-gray;
            color: map-get($foundation-palette, secondary);
            margin: 0;
            padding-bottom: 5px;
            width: 100%;

            @include breakpoint(medium up) {
                .row {
                    margin: 0;
                }
            }

            .columns {
                padding: 0;
            }
        }

        .order-location {
            text-transform: capitalize;
        }

        .order-information-client,
        .order-location {
            .value {
                margin-bottom: 0;
            }
        }

        .cancelled-order,
        .modified-order {
            @include font-gotham-bold;
        }

        .modified-order {
            color: map-get($foundation-palette, primary);
            font-size: 12px;
            margin-bottom: 0;
        }

        .cancelled-order {
            color: map-get($foundation-palette, error);
        }
    }

    .order-history-table {
        @include font-gotham-book;

        font-size: 15px;

        tbody {
            border: 0;

            tr {
                background: transparent;
            }
        }

        th,
        td {
            line-height: 1.25rem;
            padding: 0 0 1.375rem;

            &:first-child {
                width: 56%;
            }

            &:last-child {
                text-align: right;
                width: 10%;
            }
        }

        th {
            @include font-gotham-medium;

            font-weight: normal;
            text-align: left;
        }

        .old-price {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: rem-calc(12);
            text-decoration: line-through;
        }
    }

    .order-block {
        .order-title {
            @include font-gotham-bold;
            align-items: center;
            background: map-get($foundation-palette, white-default);
            color: map-get($foundation-palette, secondary);
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            font-weight: 500;
            height: 64px;
            justify-content: space-between;
            padding: 0 20px;

            &::after {
                border: 0;
                color: $old-silver;
                content: "\f107";
                display: block;
                font-family: "fontello";
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                height: auto;
                line-height: 1em;
                position: absolute;
                right: 20px;
                text-align: center;
                text-transform: none;
                top: 8px;
                width: 1em;

                @include breakpoint(medium up) {
                    font-size: rem-calc(20);
                    top: 50%;
                    transform: translateY(-30%);
                }
            }

            &[aria-expanded="true"]::after {
                transform: scaleY(-1);
                transform-origin: 50% 50%;
            }

            .order-total {
                @include font-gotham-book;

                color: $button-dark;
                font-size: rem-calc(16);
                font-weight: bold;
                margin-right: 30px;

                @include breakpoint(medium up) {
                    font-size: rem-calc(18);
                }
            }

            .order-number-status {
                display: block;
                @include breakpoint(medium up) {
                    font-size: 18px;
                }
            }

            .order-vehicle-name {
                display: block;
                padding: 10px 0 15px;

                @include breakpoint(medium up) {
                    padding: 10px 0 0;
                }

                &.unknown {
                    color: map-get($foundation-palette, nobel);
                }
            }
        }
     
        .accordion-content {
            border: 0;
            margin: 0;
            min-height: 70px;
            padding: 16px 10px 0;
            position: relative;

            @include breakpoint(medium up) {
                padding: 16px 23px 0;
            }
        }

        .car-appointment-info {
            @include font-gotham-book;
            background: map-get($foundation-palette, light-gray);
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(14);
            line-height: 22px;
            margin-bottom: 20px;

            padding: 10px;

            @include breakpoint(medium up) {
                margin-bottom: 30px;
                padding: 20px;
            }
        }
    }

    .load-more-container {
        position: relative;
        text-align: center;
        width: 100%;
    }

    .productvalues-column {
        font-size: 14px;

        @include breakpoint(medium up) {
            font-size: 16px;
        }

        .mobile-titles {
            font-family: "gotham-bold", Arial;
            font-weight: bold;
        }
    }
}

// Order Details page
.orderdetails {
    .order-information {
        @include font-gotham-book;

        font-size: 14px;
        line-height: 1rem;
        margin: 0 0 25px;
        padding-top: 0.625rem;

        @include breakpoint(medium up) {
            padding-top: 1.625rem;
        }

        .order-number {
            @include font-gotham-bold;

            margin: 0 0 5px;

            @include breakpoint(medium up) {
                margin: 0 0 10px;
            }

            .value {
                color: map-get($foundation-palette, secondary);
            }
        }
    }

    .account-block-grey {
        .label {
            @include font-gotham-medium;
        }
    }

    .label {
        background: transparent;
        color: map-get($foundation-palette, secondary);
        font-size: 1em;
        padding: 0;
    }

    .account-block-border {
        border-width: 2px;
        margin-bottom: 1.875rem;

        @include breakpoint(medium up) {
            margin-bottom: 2.8125rem;
        }
    }

    .account-section-heading {
        margin-bottom: 1.875rem;
    }

    .mini-address-location,
    .account-info,
    .appointment-info {
        p {
            font-size: rem-calc(14);
            margin: 0;

            &.email {
                display: none;
            }
        }
    }

    .appointment-time {
        color: map-get($foundation-palette, midgrey);
    }

    .line-sections-separator {
        &::before {
            background: #f2f2f3;
        }
    }
}

.account-info-link {
    @include font-gotham-bold;

    font-size: rem-calc(13);
}

.account-info-message {
    color: map-get($foundation-palette, midgrey);
    display: block;
}

// Order Summary section
.order-payment-summary {
    @include clearfix;
    border: 2px solid $medium-gray;
    margin: 0;
    padding: 1rem 1.25rem 0;

    @include breakpoint(medium up) {
        padding: 1.5625rem 1.875rem 0;
    }

    .order-summary-title {
        @include font-gotham-bold;

        font-size: rem-calc(18);
        margin: 0 0 1.5625rem;

        @include breakpoint(medium up) {
            display: none;
        }
    }

    .order-detail-summary {
        @include breakpoint(medium up) {
            float: right;
            padding-left: 0.625rem;
            width: 58.33333%;
        }

        @include breakpoint(large up) {
            width: 41.66667%;
        }
    }

    .vehicle-info {
        display: none;
    }

    .order-totals-table {
        margin: 0;

        @include breakpoint(small only) {
            .amount-total-paid {
                border-top: 1px solid #f2f2f3;

                td {
                    padding-top: 15px;
                }
            }

            .order-total {
                td {
                    padding-bottom: 15px;
                }
            }
        }

        tbody {
            border: 0;

            tr:nth-child(even) {
                background: transparent;
            }

            td {
                padding: 0;

                @include breakpoint(medium up) {
                    padding: 5px 0;
                }
            }
        }

        tr {
            @include font-gotham-book;

            font-size: rem-calc(14);

            td:first-child {
                padding-right: 0.625rem;
                text-align: left;
            }

            td:last-child {
                text-align: right;
            }

            &.bold {
                @include font-gotham-bold;

            }

            &.order-total {
                @include breakpoint(medium up) {
                    @include font-gotham-book;
                }
            }

            &.amount-total-paid,
            &.amount-store-total {
                &.bold {
                    font-size: rem-calc(15);
                }
            } 
        }
    }

    .currency-code {
        font-size: rem-calc(9);
    }
}

.cart-summary-box {
    .product-name {
        text-transform: uppercase;
    }

    .line-item {
        .product-brand,
        .product-name,
        .line-item-price,
        .item-quantity,
        .price-total {
            @include font-gotham-book;

            font-size: rem-calc(15);
        }

        .front-back-set-label {
            @include font-gotham-book;
        }

        .currency-code {
            font-size: rem-calc(9);
        }
    }
}
