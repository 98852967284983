.reveal {
    outline: none;
}

//Location Search Suggestions
.pac-container {
    @include font-gotham-medium;

    color: map-get($foundation-palette, secondary);
    font-size: 12px;
    padding: 20px 10px;

    @include breakpoint(medium up) {
        margin-left: -22px;
    }

    .pac-item {
        border: 0;
        padding: 0 15px;

        .pac-icon {
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &::after {
        display: none;
    }
}

//Blog Card

.bottom-spaced-colums {
    > div.columns {
        margin-bottom: 20px;
    }
}

.blog-card {
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: cover;
    max-width: 100%;
    min-height: 320px;
    overflow: hidden;
    position: relative;
    width: auto;

    .blog-card-info-wrapper {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        color: map-get($foundation-palette, white-default);

        position: absolute;
        width: 100%;

        .blog-card-info {
            padding: 21px;

            @include breakpoint(small only) {
                h4 {
                    font-size: 17px;
                    line-height: 1.3em;
                }
            }

            @include breakpoint(medium up) {
                h4 {
                    height: 4.25rem;
                    line-height: 1.375rem;
                    margin-bottom: 0;
                    overflow: hidden;
                }
            }

            .tiny-headings {
                color: map-get($foundation-palette, primary);
                margin: 0;

                @include breakpoint(small only) {
                    display: none;
                }
            }
        }

        .blog-card-cta {
            @include font-gotham-bold;

            background: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, secondary);
            font-size: 12px;
            line-height: 45px;
            margin: 0;
            padding: 0 21px;
            text-transform: uppercase;
        }
    }
}

.blog-card-wrapper {
    @include breakpoint(medium down) {
        .blog-card-info-wrapper {
            bottom: 0;

            .blog-card-cta {
                display: none;
            }
        }
    }

    @include breakpoint(large up) {
        .blog-card-info-wrapper {
            bottom: -45px;
            transition: all 0.2s ease;
        }   

        &:hover {
            .blog-card-info-wrapper {
                bottom: 0;
            }
        }   
    }
}


//Hello Bar
.hello-bar {
    background: $darker-gray;
    color: map-get($foundation-palette, white-default);
    min-height: 30px;
    overflow: hidden;
    padding: 10px 45px 10px 15px;
    position: relative;
    text-align: center;

    @include breakpoint(medium up) {
        height: 40px;
    }

    p {
        box-sizing: border-box;
        display: inline;
        margin: 0;
        padding: 0;
    }

    a {
        color: map-get($foundation-palette, white-default);
        text-decoration: underline;

        &:hover {
            opacity: 0.5;
        }
    }

    .fa-times {
        cursor: pointer;
        font-size: 18px;
        margin-top: -8px;
        position: absolute;
        right: 20px;
        top: 23px;

        @include breakpoint(medium up) {
            top: 50%;
        }
    }
}

.custom-menu-overlay {
    background: map-get($foundation-palette, secondary);
    display: none;
    height: 100%;
    opacity: 0.6;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.header-banner,
.header-nav {
    position: relative;
    z-index: 4;
}

//Header Nav
.header-nav {
    @include font-gotham-medium;

    background: map-get($foundation-palette, light-gray);
    font-size: 12px;
    height: rem-calc(40);
    position: relative;
    z-index: 4;

    a {
        color: map-get($foundation-palette, secondary);

        &:hover {
            text-decoration: underline;
        }
    }

    #mainNavActionsWrapper {
        @include flex;
        height: 40px;
    }

    #headerNavLocationCTA {
        color: map-get($foundation-palette, secondary);
        font-weight: bold;

        span {
            bottom: 3px;
            display: none;
            font-size: 15px;
            position: absolute;
            right: -20px;
        }

        &:hover {
            text-decoration: underline;

            span {
                display: inline-block;
            }
        }
    }
}

ul#headerNavActions {
    @include flex;
    @include flex-align(right, middle);
    margin: 0;

    li {
        display: inline-block;
        margin-left: 25px;

        &:first-child {
            margin-left: 0;
            min-width: 68px;
        }
    }

    li.headerNavLocationWrapper {
        @include flex;

        position: relative;

        @include breakpoint(medium only) {
            margin-right: 25px;
        }

        i.icon-location {
            @include flex;
            @include flex-align(right, middle);
            font-size: 16px;
        }

        &.active {
            border-bottom: 1px solid #dfe0e0;

            i.icon-location {
                color: map-get($foundation-palette, primary);
            }
        }
    }
}

//Main Nav

#mainNavWrapper {
    @include breakpoint(small only) {
        padding: 0;
    }

    .top-bar-left,
    .top-bar-right {
        @include breakpoint(medium up) {
            @include flex;
            @include flex-align(null, middle);
            height: 40px;
        }
    }

    #mainNav {
        transition: 0.3s ease all;

        @include breakpoint(medium up) {
            @include flex;
            @include flex-align(right, middle);

            >li a.l1MenuItem {
                position: relative;
            }

            >li.is-active a.l1MenuItem {
                color: map-get($foundation-palette, primary-accessible);
            }
        }

        @include breakpoint(small only) {
            display: block;
            width: 100%;

            >li[aria-expanded="true"] {
                a.l1MenuItem {
                    color: map-get($foundation-palette, primary-accessible);
                }
            }

            >li a.l1MenuItem {
                color: map-get($foundation-palette, secondary);
                position: relative;
            }
        }

        .dropdown-pane {
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
            border-top: 1px solid #e5e5e5;
        }
    }

    .top-bar #mainNav > li,
    .top-bar #mainNav > li > a {
        @include font-gotham-bold;

        color: map-get($foundation-palette, secondary);
        font-size: 14px;
        text-transform: uppercase;

        &::after {
            @include breakpoint(medium up) {
                display: none;
            }
        }

        &:hover {
            color: map-get($foundation-palette, primary);
        }

        ul,
        li,
        li a {
            text-transform: none;
        }
    }

    .top-bar #mainNav > li > a,
    .top-bar #mainNav > li .mobile-menu-item-link {
        display: block;
        line-height: 40px;
        padding: 0;

        @include breakpoint(large up) {
            span {
                border-left: 2px solid map-get($foundation-palette, primary);
                padding: 0 19px;
            }
        }

        @include breakpoint(medium only) {
            span {
                padding: 0 20px;
            }
        }

        span {
            color: map-get($foundation-palette, secondary);
        }
    }

    .top-bar #mainNav li {
        @include breakpoint(small only) {
            border-top: 1px solid map-get($foundation-palette, midgrey);

            &.is-submenu-item {
                display: block;
            }
        }

        a {
            @include breakpoint(small only) {
                font-size: 22px;
                line-height: 67px;
                margin: 0;
                padding: 0 30px;
                width: 100%;
            }

            &.mobile-submenu-trigger {
                @include breakpoint(small only) {
                    padding-right: 1rem;
                }
            }
        }

        &.js-drilldown-back > a {
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(22);
            line-height: 67px;
            text-transform: uppercase;

            &::before {
                color: map-get($foundation-palette, primary);
                margin-top: -5px;
            }
        }

        .mobile-menu-wrapper {
            padding: 10px 0 25px;

            @include breakpoint(small only) {
                ul,
                li {
                    border: 0 !important;
                    list-style-type: none;
                    margin: 0;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    padding: 23px 45px 0;
                }

                li,
                li a {
                    @include font-gotham-medium;
                    color: map-get($foundation-palette, secondary);

                    font-size: 14px;
                    line-height: 32px;
                    padding: 0;
                }

                li {
                    padding: 0 45px;
                }
            }
        }

        &:first-child a span {
            border: 0;
        }
    }

    .mobile-submenu-trigger {
        height: 67px;
        position: absolute !important;
        text-align: right;
        width: 100%;
    }

    ul#mainNav .mobile-menu-wrapper {
        h5 {
            color: map-get($foundation-palette, primary);

            a {
                font-size: rem-calc(17);
                line-height: 1em;
                padding: 0;
            }
        }
    }

    .top-bar-right {

        @include breakpoint(large up) {
            float: none;
            justify-content: space-between;
        }

        ul#mainNav {
            float: left;
            z-index: 1;

            @include breakpoint(large up) {
                margin: 0;
            }
        }
    }


}

#mainMenuWrapper {
    @include breakpoint(small only) {
        position: relative;
        z-index: 1;
    }
}

ul.mainNavActions {
    @include flex;
    @include flex-align(right, middle);
    margin: 0;
    min-width: 213px;

    position: relative;
    z-index: 100;

    @include breakpoint(medium only) {
        min-width: auto;
    }

    >li {
        padding: 0 8px;

        @include breakpoint(large only) {
            padding: 0 3px;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    li {
        @include flex;
    }

    a {
        @include font-gotham-bold;

        color: map-get($foundation-palette, secondary);
        display: block;
        font-size: 12px;
        line-height: 20px;

        i {
            font-size: 20px;
        }

        i:hover {
            color: map-get($foundation-palette, primary-accessible);
        }

        &:hover {
            color: map-get($foundation-palette, $button-dark);
        }
    }

    h5 a {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(18);
    }

    .header-cta-button {
        .button {
            align-items: center;
            display: flex;
            height: 28px;
            margin: 0;

            @include breakpoint(large only) {
                white-space: nowrap;
            }
        }
    }

}

.mobile-header-cta {
    text-align: center;

    .button {
        align-items: center;
        display: flex;
        height: 28px;
        justify-content: center;
        margin: 10px auto;
        width: 80%;
    }
}

.header-main-nav {
    background-color: map-get($foundation-palette, white-default);
    min-height: 40px;
    padding: 28px 0;
    position: sticky;
    position: sticky;
    top: 0;
    z-index: 99;

    @include breakpoint(small only) {
        min-height: 60px;
        padding: 0;

        &::after {
            height: 1px;
        }
    }

    &::after {
        background-color: map-get($foundation-palette, light-gray);
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .row.atroot {

        @include breakpoint(small only) {
            padding: 0;
            width: 100%;
        }
    }

    &.no-js {
        height: 60px;
    }

    &-sticked {
        .header-logo-image {
            @include breakpoint(medium up) {
                max-width: 90px;
            }

            @include breakpoint(large up) {
                max-width: 110px;
            }
        }
    }
}

.header-logo-image {
    height: auto;
    max-width: 90px;
    transition: all 0.5s ease;

    @include breakpoint(medium up) {
        max-width: 100px;
        padding: 20px 0;
    }

    @include breakpoint(large up) {
        max-width: 125px;
    }
}


#locationChange {
    @include flex;

    position: relative;
}

#locationChangeTriggerWrapper {
    @include flex;
    @include flex-align(right, middle);

    transition: 0.3s ease all;
}

#locationChangeFormWrapper {
    overflow: hidden;
    transition: 0.3s ease all;
    width: 0;
}

#locationChangeInput {
    @include font-gotham-medium;
    background: transparent;
    border: 0;
    box-shadow: none;

    font-size: 12px;
    height: 28px;
    line-height: 28px;
    margin: 0;
    max-width: 200px;
    padding: 0 25px 0 0;

    &::-ms-clear {
        display: none;
    }
}

#locationChangeFormClose {
    color: #dfe0e0;
    cursor: pointer;
    display: none;
    font-size: 18px;
    line-height: 28px;
    position: absolute;
    right: 0;
    top: 0;
}

.title-bar {
    padding: 0;
    position: relative;
}

#mobileMenuLogoWrapper {
    float: left;
    padding: 18px 0 0 30px;
}

#mobileMenuTriggerWrapper {
    float: right;
    position: relative;

    ul {
        @include flex;
        @include flex-align(right, middle);

        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            font-size: 21px;

            a {
                align-items: center;
                color: map-get($foundation-palette, secondary);
                display: flex;
                height: 60px;
                justify-content: center;
                width: 36px;
            }
        }
    }

    li a.active {
        color: map-get($foundation-palette, primary);
    }

    .menu-icon {
        fill: map-get($foundation-palette, secondary);
        height: 60px;
        margin: 0 0 0 10px;
        outline: none;
        position: relative;
        -webkit-tap-highlight-color: transparent;
        transition: 0.3s ease all;
        width: 60px;
        z-index: 2;

        .mobileNav-svg {
            bottom: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 60px;
        }

        .mobileNav-rect {
            transition: 0.3s ease all;
        }

        &.is-active {
            .mobileNav-rect:first-child {
                transform: rotate(43deg) translate(11px, -21px);
                transform: rotate(43deg) translate(11px, -21px);
                transform: rotate(43deg) translate(11px, -21px);
            }

            .mobileNav-rect:nth-child(2) {
                opacity: 0;
            }

            .mobileNav-rect:last-child {
                transform: rotate(-45deg) translate(-30px, 1px);
                transform: rotate(-45deg) translate(-30px, 1px);
                transform: rotate(-45deg) translate(-30px, 1px);
            }
        }

        &::after {
            display: none;
        }
    }
}


#mobileNavActions {
    background: map-get($foundation-palette, light-gray);
    border-bottom: 1px solid map-get($foundation-palette, midgrey);
    border-top: 1px solid map-get($foundation-palette, midgrey);
    display: block;
    float: left;
    list-style-type: none;
    margin: 0;
    padding: 5px 30px;
    width: 100%;

    li {
        a {
            color: map-get($foundation-palette, secondary);
            display: block;
            line-height: 40px;
            width: 100%;

            span {
                color: map-get($foundation-palette, primary);
            }

            &.active {
                color: map-get($foundation-palette, primary);
            }
        }

        a#mobileLocationChangeFormClose {
            color: #dfe0e0;
            display: block;
            font-size: 18px;
            line-height: 40px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 45px;
        }

    }
}

#mobileLocationChange {
    position: relative;
}

#mobileLocationChangeInput {
    margin: 0;
    max-width: 100%;
    padding: 0 45px 0 15px;
    width: 100%;
}

#mobileLocationChangeFormWrapper {
    height: 0;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease all;
}


//minicart
.mini-cart-link {
    position: relative;

    .minicart-quantity {
        @include font-gotham-bold;
        background: map-get($foundation-palette, white-default);
        border-radius: 50%;
        color: map-get($foundation-palette, white-default);
        font-size: 10px;
        height: 24px;

        position: absolute;
        right: -10px;
        text-align: center;
        top: -9px;
        width: 24px;

        @include breakpoint(small only) {
            line-height: 20px;
            right: -4px;
            top: 5px;
        }

        .minicart-quantity-inner {
            background: map-get($foundation-palette, secondary);
            border-radius: 50%;
            display: block;
            height: 18px;
            line-height: 18px;
            margin: 0 auto;
            width: 18px;
        }
    }
}

.mega-menu {
    @include breakpoint(medium up) {
        position: relative;
        z-index: 999;

        .links-type {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: map-get($foundation-palette, primary-accessible);
                padding-top: 10px;

                a {
                    color: map-get($foundation-palette, primary-accessible);
                }

                a:hover,
                &:hover {
                    color: map-get($foundation-palette, primary-dark-hover);
                    text-decoration: underline;
                }
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            li,
            li a {
                @include font-gotham-medium;

                color: map-get($foundation-palette, secondary);
                font-size: 15px;
            }

            li {
                padding: 5px 0;

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        .editorial-type {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: map-get($foundation-palette, primary-accessible);
                margin: 0;
                padding: 10px 0 5px;

                &:hover {
                    color: map-get($foundation-palette, primary-dark-hover);
                }
            }

            p {
                @include font-gotham-book;
                color: map-get($foundation-palette, midgrey);

                font-size: 14px;
                text-transform: none;
            }

            .content-custom-btn {
                color: map-get($foundation-palette, secondary);

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .mega {
            box-shadow: 0 13px 20px -13px rgba(0, 0, 0, 0.15);
            left: 0% !important;
            margin: 0 auto !important;
            max-width: 1800px;
            padding: 60px 0;
            right: 0% !important;
            width: auto !important;

            .row {
                margin: 0 auto;
                max-width: $grid-row-width;
            }

            .columns {
                border-left: 1px solid #e5e5e5;
                padding: 0 35px;

                &:first-child {
                    border: 0;
                }
            }

            .image-type {
                a {
                    display: block;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

    }
}

.is-dropdown-submenu-parent {
    position: relative;

    @include breakpoint(medium up) {
        position: static;

        &.is-active {
            a.l1MenuItem {
                &::before {
                    background: map-get($foundation-palette, primary);
                    content: " ";
                    height: 6px;
                    left: 0;
                    position: absolute;
                    top: 60px;
                    width: 100%;
                }
            }
        }
    }
}

.is-accordion-submenu-parent > a::after {
    @include breakpoint(small only) {
        border: 0;
        color: map-get($foundation-palette, primary);
        content: "\f107";
        display: block;
        font-family: "fontello";
        font-size: 30px;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        height: auto;
        line-height: 1em;
        position: absolute;
        right: 20px;
        text-align: center;
        text-transform: none;
        top: 24px;
        width: 1em;
    }
}

.header-main-nav {
    .is-drilldown .mega-menu {
        display: flex !important;
        justify-content: space-between;
    }

    .is-drilldown-submenu {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
    }

    .is-drilldown-submenu-parent > a::after {
        @include breakpoint(small only) {
            border: 0;
            color: map-get($foundation-palette, primary);
            content: "\f105";
            display: inline;
            font-family: "fontello";
            position: initial;
        }
    }

    .is-drilldown-submenu .js-drilldown-back > a::before {
        @include breakpoint(small only) {
            border: 0;
            content: "\f104";
            font-family: "fontello";
            height: auto;
            width: auto;
        }
    }
}

.is-accordion-submenu-parent[aria-expanded="true"] > a::after {
    @include breakpoint(small only) {
        content: "\f106";
        transform: initial;
        transform-origin: initial;
    }
}

#mobileSearchWrapper {
    float: left;
    position: relative;
    top: 1px;
    width: 100%;
    z-index: 9;

    .header-search {
        display: none;
    }

    &.active {
        .header-search {
            display: block;
        }
    }

}


.header-search {
    @include breakpoint(medium up) {
        overflow: hidden;
        transition: 0.3s ease all;
        width: 0;
    }

    @include breakpoint(small only) {
        background: map-get($foundation-palette, light-gray);

        padding: 15px;
        top: -1px;
        width: 100%;

        fieldset {
            position: relative;
        }

        .seachFormInput {
            padding: 0 15px;
        }

        .seachFormInput {
            @include font-gotham-medium;
            border: 1px solid #dfe0e0;
            box-shadow: none;

            color: map-get($foundation-palette, secondary);
            font-size: 12px;
            margin: 0;
            padding: 0 25px 0 15px;
        }

        .searchFormClose {
            color: #dfe0e0;
            cursor: pointer;
            font-size: 18px;
            line-height: 40px;
            position: absolute;
            right: 10px;
            top: 0;
        }
    }
}

.headerSearchWrapper {
    @include flex;
    @include flex-align(null, middle);

    &.active {
        background: map-get($foundation-palette, white-default);
        border-bottom: 1px solid #dfe0e0;
        padding: 0;
        right: 0;
        top: -8px;
        z-index: 1;

        @include breakpoint(medium only) {
            background: map-get($foundation-palette, light-gray);
            position: relative;
            top: 0;
        }

        @include breakpoint(small only) {
            border: 0;
            position: relative;
            top: 0;
        }

        .searchFormTrigger {
            color: map-get($foundation-palette, primary) !important;
            display: inline;

            i.icon-search::before {
                margin-left: 0;
            }
        }

        .searchFormClose {
            display: block;
        }

        .header-search {
            @include breakpoint(medium up) {
                width: 220px;
            }

            @include breakpoint(large up) {
                transition-duration: 0.3s;
            }
        }
    }

    .header-search .searchFormTrigger {
        @include flex;
        @include flex-align(left, middle);
    }

    .searchFormTrigger {
        @include flex;
        @include flex-align(right, middle);
    }

    .header-search fieldset {
        position: relative;
    }

    .seachFormButton {
        bottom: 0;
        position: absolute;
        right: 5px;

        &:hover {
            color: #ff6900;
        }
    }

    .seachFormInput {
        @include font-gotham-medium;
        background: transparent;
        border: 1px solid map-get($foundation-palette, light-gray);
        box-shadow: none;

        font-size: 12px;
        height: 28px;
        line-height: 28px;
        margin: 0;
        max-width: 100%;
        padding: 0 25px 0 10px;

        &::-ms-clear {
            display: none;
        }
    }

    .searchFormClose {
        color: #dfe0e0;
        cursor: pointer;
        display: none;
        font-size: 18px;
        line-height: 28px;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.search-suggestions {
    background: map-get($foundation-palette, white-default);
    box-shadow: 0 8px 15px -2px rgba(0, 0, 0, 0.15);
    padding: 20px 0;
    position: absolute;
    right: 0;
    width: 585px;

    @include breakpoint(medium up) {
        top: 64px;
    }

    @include breakpoint(large up) {
        top: 67px;
    }

    @include breakpoint(small only) {
        padding: 30px;
        top: 70px;
        width: 100%;
    }

    .mobile-search & {
        overflow-y: scroll;
    }

    .hitgroup {
        margin: 0 0 30px;
    }

    .generated-hitgroup {
        border-bottom: 1px solid $medium-gray;
        padding-bottom: 20px;
    }

    h5 {
        color: map-get($foundation-palette, primary);
        margin: 0;
        padding-bottom: 20px;
        text-transform: uppercase;

        &.popular-searches {
            font-size: rem-calc(14);
            text-transform: none;
        }
    }

    .direct-page-link {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(18);
        margin: 0;
        padding-bottom: 20px;
        text-transform: uppercase;
    }

    .search-product-wrapper {
        border-left: 1px solid #dfe0e0;
        float: right;
        overflow-x: hidden;
        padding: 0 30px 0 40px;
        width: 340px;

        @include breakpoint(small only) {
            border: 0;
            padding: 0 0 20px;
            width: 100%;
        }
    }

    .search-suggestion-wrapper {
        float: left;
        overflow: hidden;
        padding: 0 30px 0 40px;
        width: 245px;

        @include breakpoint(small only) {
            border: 0;
            border-top: 1px solid #d4d4d4;
            margin-bottom: 0;
            padding: 30px 0;
            width: 100%;
        }

        a.hit {
            @include font-gotham-medium;
            color: map-get($foundation-palette, secondary);

            display: block;
            font-size: rem-calc(14);
            padding-bottom: 10px;
        }
    }

    .product-suggestion {
        margin-bottom: 15px;
    }

    .product-suggestion:hover {
        background-color: #f4f4f4;
    }

    .product-link {
        @include flex;
        @include flex-align(null, middle);

        .product-image {
            border: 1px solid #dfe0e0;
            width: 60px;
        }

        .product-details {
            @include font-gotham-medium;
            color: map-get($foundation-palette, secondary);

            font-size: rem-calc(13);
            padding-left: 20px;

            .product-brand {
                @include font-gotham-bold;
            }
        }
    }
}

.footer-social-media-wrapper {
    font-size: 28px;
    list-style-type: none;
    margin: 0;
    margin-top: 15px;
    padding: 0;
    text-align: center;

    @include breakpoint(medium up) {
        font-size: 20px;
        margin-top: 0;
        text-align: left;
    }

    li {
        display: inline;
        padding: 0 20px;

        @include breakpoint(medium up) {
            padding: 0 0 0 20px;

            &:first-child {
                padding: 0;
            }
        }

        a {
            color: map-get($foundation-palette, secondary);
        }


    }
}

.footer-copy-wrapper {
    background: map-get($foundation-palette, primary);
    min-height: 96px;

    .row {
        @include flex-align(null, middle);

        min-height: 96px;
        padding-bottom: 15px;
        padding-top: 15px;

        @include breakpoint(medium up) {
            height: 96px;
        }
    }
}

#footerLogoContainer {
    display: block;
    margin: 0 auto 8px;
    max-width: 108px;
    text-align: center;

    @include breakpoint(medium up) {
        float: left;
        margin: 0 auto;
        max-width: 108px;
        text-align: left;
    }

    img {
        display: block;
        height: auto;
        width: 100%;
    }
}

#footerCopyContainer {
    @include font-gotham-book;
    color: map-get($foundation-palette, secondary);
    display: block;
    font-size: 10px;
    margin: 0;
    padding: 0;
    text-align: center;

    width: 100%;

    @include breakpoint(medium up) {
        float: right;
        font-size: 11px;
        margin: 0;
        padding: 0;
        text-align: right;
    }

    @include breakpoint(small only) {
        span {
            display: block;
            width: 100%;
        }
    }

    a {
        color: map-get($foundation-palette, secondary);
        text-decoration: underline;
    }
}

#footerLiveChatCTA {
    color: map-get($foundation-palette, primary);
}

.footer-wrapper {
    @extend .fullbleed; /* stylelint-disable-line */
    border-top: 1px solid map-get($foundation-palette, light-gray);
    padding: 55px 0 25px;

    @include breakpoint(small only) {
        padding: 45px 0 35px;
    }

    .footer-logo {
        display: flex;
        justify-content: center;
    }
}

ul.footer-links {
    color: map-get($foundation-palette, midgrey);
    list-style-type: none;
    margin: 0 0 30px;

    span {
        color: map-get($foundation-palette, primary);
    }

    a {
        color: map-get($foundation-palette, midgrey);

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        margin-bottom: 1.375rem;

        @include breakpoint(medium up) {
            margin-bottom: 5px;

            &:first-child {
                margin-bottom: 10px;

                a {
                    @include font-gotham-bold;
                    color: map-get($foundation-palette, secondary);

                    font-size: 15px;
                }
            }

        }

        @include breakpoint(small only) {
            a {
                color: map-get($foundation-palette, secondary);
            }
        }
    }
}

.show-self-service {
    .show-entry-point {
        display: block;
    }
}

.show-entry-point {
    display: none;
}

.footer-email-wrapper {
    background: map-get($foundation-palette, light-gray);
    padding: 35px 0;

    form fieldset {
        text-align: center;

        @include breakpoint(large up) {
            @include flex;
            @include flex-align(center, middle);
        }
    }

    label {
        @include font-gotham-bold;

        color: map-get($foundation-palette, secondary);
        font-size: 13px;
        padding: 0 0 15px;

        @include breakpoint(large up) {
            font-size: 15px;
            padding: 0 70px 0 0;
        }
    }

    .footer-email-input-wrapper,
    .footer-email-label-wrapper {
        @include flex;
        @include flex-align(center, null);
        width: 100%;

        @include breakpoint(large up) {
            width: auto;
        }
    }

    .footer-email-label-wrapper {
        @include breakpoint(large up) {
            @include flex-align(right, null);
        }

        @include breakpoint(large up) {
            label {
                margin: 0;
            }
        }
    }

    .footer-email-input-wrapper {
        display: flex;
        padding: 0 15px;
        position: relative;

        @include breakpoint(large up) {
            @include flex-align(left, null);
        }

        input {
            float: left;
            margin: 0;
        }

        button {
            height: 40px;
            margin: 0;

            @include breakpoint(small only) {
                padding: 0;
                width: 40px;

                i {
                    font-size: 22px;
                }
            }
        }

        span.error {
            left: 0;
            padding: 0 15px;
            position: absolute;
            text-align: left;
            top: -18px;

            @include breakpoint(medium down) {
                text-align: center;
            }
        }
    }
}

.newsletter-message {
    align-items: center;
    display: flex;

    i {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(22);
    }

    span {
        font-size: rem-calc(12);
    }
}

#subscribe-email {
    max-width: 305px;

    @include breakpoint(large up) {
        width: 315px;
    }
}

#homepageAlertBar {
    background: map-get($foundation-palette, secondary);
    color: map-get($foundation-palette, white-default);
    padding: 0 50px 0 0;
    position: relative;

    i.icon-alert {
        color: map-get($foundation-palette, primary);
        font-size: 30px;
        min-width: 42px;
    }

    p {
        @include font-gotham-medium;
        align-items: center;
        display: flex;

        margin: 25px auto;
    }

    > div {
        display: table;
        min-height: 80px;
    }

    strong {
        @include font-gotham-bold;
    }

    #homepageAlertBarClose {
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        right: 20px;
        top: 38%;
    }
}

#homepageThreeAssetGroup {
    .main-left {
        width: 100%;

        @include breakpoint(large up) {
            width: 50%;
        }

        @include breakpoint(small only) {
            height: auto !important;
        }

        @include breakpoint(medium only) {
            height: auto !important;
        }
    }

    .main-right {
        width: 100%;

        @include breakpoint(large up) {
            width: 50%;
        }

        @include breakpoint(small only) {
            height: auto !important;
        }

        @include breakpoint(medium only) {
            height: auto !important;
        }
    }

    .right-column-wrapper {
        width: 100%;

        @include breakpoint(large up) {
            background: map-get($foundation-palette, primary);
            display: flex;
            flex-wrap: wrap;
            height: 100%;
        }
    }

    .left-column {
        background: map-get($foundation-palette, light-gray);
        padding: 36px 32px 0;
        width: 100%;

        @include breakpoint(medium up) {
            padding: 55px 32px 0;
        }

        @include breakpoint(large up) {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding: 90px 60px 0;
        }

        h2 {
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(32);

            @include breakpoint(medium up) {
                font-size: rem-calc(42);
                margin-bottom: 15px;
            }
        }

        p {
            color: map-get($foundation-palette, midgrey);
            margin-bottom: 30px;
            max-width: 540px;
        }

        ul {
            list-style: none;
            margin: 0 0 25px;
            padding: 0;

            @include breakpoint(medium only) {
                margin-bottom: 25px;
            }

            p {
                display: inline;
                margin: 0;
            }
        }

        li {
            &::before {
                color: map-get($foundation-palette, primary);
                content: "\2022";
                font-size: 22px;
                line-height: 16px;
                padding-right: 0.25em;
                position: relative;
                top: 0.1em;
            }
        }

        a.button {
            margin-bottom: 75px;
        }
    }


    .left-column-image-wrapper {
        background: map-get($foundation-palette, light-gray);
        padding: 0;
        position: relative;

        @include breakpoint(large up) {
            align-self: flex-end;
        }

        img {
            width: 100%;
        }

        a.button {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .right-column-first {
        background-position: bottom left;
        background-size: cover;
        padding: 85px 32px;
        width: 100%;

        @include breakpoint(medium up) {
            padding: 100px 32px 90px;
        }

        @include breakpoint(large up) {
            padding: 85px 80px;
        }

        img.right-column-first-icons {
            margin-bottom: 25px;
            max-width: 216px;

            @include breakpoint(large up) {
                max-width: 225px;
            }
        }

        h2 {
            color: map-get($foundation-palette, white-default);
            font-size: rem-calc(32);
            margin-bottom: 15px;

            @include breakpoint(medium up) {
                font-size: rem-calc(42);
            }
        }

        p {
            color: map-get($foundation-palette, white-default);
            margin-bottom: 25px;

            @include breakpoint(large up) {
                max-width: 460px;
            }
        }

        .button {
            margin: 0;
        }
    }

    .right-column-second {
        background: map-get($foundation-palette, primary);

        @include breakpoint(large up) {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .right-column-second-a {
        padding: 40px 32px 15px;
        text-align: center;
        width: 100%;

        @include breakpoint(medium up) {
            flex: 1 0 auto;
            flex: 1;
            padding: 60px 10px 40px 70px;
            text-align: left;
            width: 50%;
        }

        img {
            max-width: 190px;

            @include breakpoint(medium up) {
                max-width: 80%;
            }

            @include breakpoint(large up) {
                max-width: 90%;
            }
        }
    }

    .right-column-second-b {
        padding: 0 32px 45px;
        width: 100%;

        @include breakpoint(medium up) {
            flex: 1 0 auto;
            flex: 1;
            padding: 75px 45px 45px 0;
            width: 50%;
        }

        h2 {
            color: map-get($foundation-palette, white-default);
            font-size: 26px;
            margin-bottom: 20px;

            @include breakpoint(medium up) {
                font-size: 34px;
            }

            @include breakpoint(large up) {
                font-size: 36px;
            }
        }
    }
}

.row.collapse-row {
    margin-left: auto !important;
    margin-right: auto !important;
}

.vertical-align {
    align-items: center;
    display: flex !important;
}

.horizontal-align {
    display: flex !important;
    justify-content: center;
}

.info-tile-wrapper-spacearound {
    @include flex;
    @include flex-align(null, middle);

    @include breakpoint(medium down) {
        height: auto;
    }

    @include breakpoint(medium only) {
        min-height: auto !important;
        padding-bottom: 60px;
        padding-top: 60px;
    }

    @include breakpoint(small only) {
        min-height: auto !important;
        padding-bottom: 45px;
        padding-top: 60px;
    }
}

.info-tile-wrapper {
    width: 100%;

    &.hovered {
        align-items: center;
        display: flex;
    }

    >div {
        @include breakpoint(small only) {
            margin-bottom: 15px;
        }
    }
}

.hompage-info-tile {
    border: 10px solid map-get($foundation-palette, light-gray);
    height: 100%;
    padding: 15px 38px 15px 14px;
    position: relative;
    text-align: center;

    @include breakpoint(medium up) {
        padding: 8px 20px 15px;

        a {
            @include flex-align(center, middle);
            flex-direction: column;
        }
    }

    @include breakpoint(large up) {
        padding-left: 40px;
        padding-right: 40px;
    }

    p {
        color: map-get($foundation-palette, midgrey);
        display: none;

        @include breakpoint(medium up) {
            display: block;
            width: 100%;
        }
    }

    a {
        @include flex;
        @include flex-align(left, middle);

        color: map-get($foundation-palette, secondary);

        @include breakpoint(medium up) {
            flex-direction: column;
        }
    }

    i {
        color: map-get($foundation-palette, primary);
        display: block;
        font-size: 60px;

        @include breakpoint(small only) {
            font-size: 50px;
            line-height: 50px;
        }

        &::before {
            margin: 0;
            padding: 0;
        }
    }

    h4 {
        display: block;
        font-size: 16px;
        margin-left: 20px;
        text-align: left;
        width: 100%;

        @include breakpoint(small only) {
            margin-bottom: 0;
        }

        @include breakpoint(medium up) {
            font-size: 22px;
            margin-left: 0;
            text-align: center;
        }

    }

    &.showbutton {
        .button {
            display: block;
        }
    }

    .button {
        margin-bottom: 0;

        @include breakpoint(medium up) {
            margin-bottom: 10px;
        }

        @include breakpoint(large up) {
            display: none;
        }
    }

    .mobile-info-tile-CTA {
        font-size: 42px;
        position: absolute;
        right: 0;
    }
}

#headlinesWrapper {
    background-color: #262626;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 178px auto;
    padding: 4.25rem 0;

    @include breakpoint(medium only) {
        background-size: 241px auto;
    }

    @include breakpoint(large up) {
        background-size: 316px auto;
        padding: 100px 0;
    }

    .title-wrapper {
        margin-bottom: 20px;

        @include breakpoint(medium only) {
            margin-bottom: 10px;
        }
    }

    .cta-wrapper {
        margin-top: 30px;

        @include breakpoint(medium only) {
            margin-top: 15px;
        }

        .button {
            margin: 0;
        }

    }

    h2.section-title {
        color: map-get($foundation-palette, white-default);
        font-size: 26px;

        @include breakpoint(medium up) {
            font-size: 36px;
        }
    }

    p.section-subtitle {
        color: map-get($foundation-palette, midgrey);
        font-size: 14px;

        @include breakpoint(medium up) {
            font-size: 16px;
        }
    }


}

body.is-reveal-open {
    overflow: auto;
}

.reveal {
    &.revealnine {
        border: 0;
        max-width: 900px;
        padding: 0;
        width: 100%;
    }
}

.bordered-top {
    border-top: 1px solid map-get($foundation-palette, light-gray);
}

.reveal-overlay {
    overflow-y: hidden;

    &.scroll-overlay {
        overflow-y: scroll;

        @include breakpoint(medium up) {
            overflow-y: hidden;
        }
    }
}

.error-page-container {
    color: map-get($foundation-palette, white-default);

    .fullbleed {
        background-size: cover;
    }

    .row {
        padding-bottom: 3.75rem;
        padding-top: 4.25rem;

        @include breakpoint(large up) {
            padding-bottom: 12.5rem;
            padding-top: 12.875rem;
        }
    }

    h2 {
        @include font-gotham-bold;

        font-size: 44px;
        line-height: 1.0435em;
        margin-bottom: 1.4375rem;
        text-transform: uppercase;

        @include breakpoint(large up) {
            font-size: 4.0625rem;
            margin-bottom: 0.8125rem;
        }
    }

    p {
        @include font-gotham-medium;

        line-height: 1.3em;
        margin-bottom: 1.875rem;

        @include breakpoint(large up) {
            font-size: 18px;
        }
    }

    .button {
        color: inherit;
        margin: 0 0 0.9375rem;
        padding: 10px 28px;

        @include breakpoint(medium up) {
            margin: 0 0.9375rem 0 0;
        }

        &:hover {
            color: inherit;
        }
    }
}

.browser-error {
    color: map-get($foundation-palette, alert);
    margin-bottom: -1.25rem;
    padding-top: 2rem;
}

.privacypolicy-wrapper {
    padding: 50px 0 1.875rem;

    @include breakpoint(medium up) {
        padding-bottom: 2.5rem;
    }

    .privacypolicy-title {
        @include font-gotham-bold;

        font-size: 21px;
        margin-bottom: 35px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 26px;
        }
    }

    h4 {
        @include font-gotham-bold;
        font-size: rem-calc(16);
        line-height: 1em;

        margin-bottom: 15px;
        text-transform: uppercase;
    }

    p {
        @include font-gotham-book;
        font-size: rem-calc(14);

        margin-bottom: 25px;
    }

    ul {
        list-style-type: disc;
        margin-bottom: 25px;

        li {
            color: #ff6900;

            span {
                @include font-gotham-book;

                color: #000;
                font-size: rem-calc(14);
            }
        }
    }
}

.modal {
    p,
    .button {
        margin: 0;
    }

    p {
        font-family: "gotham", Arial;
        font-size: rem-calc(14);
        line-height: 24px;

        @include breakpoint(medium up) {
            font-size: rem-calc(18);
            line-height: 29px;
        }
    }

    .title {
        background-color: map-get($foundation-palette, primary);
        color: $white;
        flex-grow: 1;
        font-size: rem-calc(18);
        margin: 0;
        padding: 20px 0 20px 20px;

        @include breakpoint(medium up) {
            font-size: rem-calc(21);
            padding: 22px 0 22px 30px;
        }
    }

    .modal-header {
        display: flex;
    }

    &.reveal {
        border: 0;
        padding: 0;
    }

    .close-button {
        background-color: map-get($foundation-palette, primary);
        color: $white;
        padding-right: 18px;
        position: unset;

        @include breakpoint(medium up) {
            padding-right: 27px;
        }
    }
}

// GDPR info bar
.gdpr-info-bar {
    background: rgba(0, 0, 0, 0.9);
    bottom: 0;
    color: map-get($foundation-palette, white-default);
    left: 0;
    min-height: 50px;
    padding: 10px 0;
    position: fixed;
    right: 0;
    z-index: 10;

    p {
        font-size: rem-calc(14);
        line-height: 35px;
        margin: 0;

        @include breakpoint(small only) {
            line-height: 1.4;
        }
    }

    .button {
        margin: 0 0 0 10px;
        padding: 10px 15px;

        @include breakpoint(small only) {
            margin: 0;
        }
    }

    .gdpr-info-flex {
        align-items: center;
        display: flex;
    }

    @media print {
        display: none;
    }
}

//Order summary
.order-summary-products-title {
    display: none;
}


.icon-calendar-icon {
    margin-right: 0.5em;
}

// Province modal message
#province-modal-message {
    line-height: 20px;
    max-height: 65vh;
    padding: 30px;
    width: 750px;

    @include breakpoint(small only) {
        display: grid;
        margin: 5%;
        min-height: 75vh;
        overflow-y: scroll;
        position: absolute;
        width: 90%;
    }

    * {
        font-family: $gotham-a-b;
    }

    .province-modal-content {
        padding-right: 10px;
    }

    .message-close-button {
        font-size: 32px;
        line-height: 1;
        position: absolute;
        right: 1rem;
        top: 0.5rem;
    }

    .title-section {
        padding-bottom: 30px;

        @include breakpoint(small only) {
            padding: 0;
        }
    }

    .content-section {
        align-items: center;
        display: flex;

        @include breakpoint(small only) {
            flex-direction: column;
        }
    }

    h4 {
        line-height: 20px;

        @include breakpoint(small only) {
            font-size: 17px;
        }
    }

    img {
        margin-right: 30px;

        @include breakpoint(small only) {
            margin: 30px 0;
        }
    }

    p {
        font-size: 16px;
        line-height: 20px;
    }
}
