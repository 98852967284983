// Note: maybe we can remove the include for pagination from foundation if we will not use it

.pagination {
    margin: 0 0 50px;

    ul {
        margin: 0;
    }

    li {
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        margin: 0 12px 0 0;
        padding: 9px;

        a {
            padding: 0;
        }
    }

    .current-page {
        background: map-get($foundation-palette, primary-accessible);
        color: map-get($foundation-palette, white-default);
    }
}

.stores-pagination {
    .pagination {
        .current {
            padding: 9px;

            a {
                color: map-get($foundation-palette, white-default);
                cursor: inherit;

                &:hover {
                    background: transparent;
                }
            }
        }
    }
}
