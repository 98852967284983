.page-iam-store-locator {
    .pac-container {
        margin: 0;
    }
}

.pt_store-locator {
    .page-title {
        margin: 20px 0 0;

        @include breakpoint(medium up) {
            margin: 30px 0 10px;
        }

        @include breakpoint(large up) {
            margin: 50px 0 20px;
        }
    }

    .page-link {
        @include font-gotham-bold;

        display: inline-block;
        font-size: rem-calc(13);
        margin: 0 0 20px;

        @include breakpoint(medium up) {
            margin: 0 0 30px;
        }

        @include breakpoint(large up) {
            margin: 0 0 35px;
        }
    }

    #filters-content-wrap {
        display: none;

        &.expanded {
            display: block;
            padding: 15px 0 0;
        }
    }

    // vehicle types icons
    .vehicle-types-section {
        padding: rem-calc(20 16 0);

        @include breakpoint(large up) {
            padding: rem-calc(20 40 0);
        }
    }

    .vehicle-types-wrapper {
        display: flex;
        width: 100%;
    }

    .vehicle-radio-button {
        display: none;
    }

    .vehicle-types-title {
        font-weight: 600;
        margin-bottom: rem-calc(14);
    }

    .vehicle-type {
        border: 2px solid map-get($foundation-palette, bright-grey);
        margin-right: rem-calc(15);
        min-height: 100px;
        position: relative;
        text-align: center;
        width: 100%;

        @include breakpoint(medium only) {
            margin-right: rem-calc(5);
        }

        .personal-vehicle-label {
            background-image: url("../images/vehicle-type/personal.svg");
        }

        .commercial-vehicle-label {
            background-image: url("../images/vehicle-type/commercial.svg");
        }

        .fleet-vehicle-label {
            background-image: url("../images/vehicle-type/fleet.svg");
        }

        .personal-vehicle-label,
        .commercial-vehicle-label,
        .fleet-vehicle-label {
            background-position: center top 28px;
            background-repeat: no-repeat;

            span {
                height: 100%;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &:has(input:checked) {
            border: 2px solid map-get($foundation-palette, primary-accessible);

            label {
                color: map-get($foundation-palette, primary-accessible);
            }

            .personal-vehicle-label {
                background-image: url("../images/vehicle-type/personal-selected.svg");
            }

            .commercial-vehicle-label {
                background-image: url("../images/vehicle-type/commercial-selected.svg");
            }
    
            .fleet-vehicle-label {
                background-image: url("../images/vehicle-type/fleet-selected.svg");
            }
        }

        &:hover {
            background-color: map-get($foundation-palette, bright-grey);
            border: 2px solid map-get($foundation-palette, secondary);
        }

        .vehicle-type-icon {
            max-width: 60px;
            min-height: 35px;
        }

        .vehicle-type-tooltip {
            position: absolute;
            right: 0;
            top: 0;
        }

        .questionmarkcircle {
            cursor: pointer;
            height: 18px;
            margin: rem-calc(3);
            width: 18px;
        }

        label {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-weight: 700;
            height: 100%;
            justify-content: center;
            margin: 0;
            padding-top: rem-calc(60);
        }
    }

    .location-item-summary {
        margin-top: rem-calc(15);
    }
}

.store-locator-page-wrapper {
    min-height: 685px;
    padding: 0;

    @include breakpoint(medium up) {
        border-bottom: 1px solid map-get($foundation-palette, bright-grey);
        min-height: 820px;
    }
}

.store-locator-page {
    position: relative;

    ul {
        margin: 0;
    }

    p {
        font-size: 14px;
        line-height: 1.3;
    }

    .list-view {
        display: block !important;
    }

    .locations-list {
        padding: rem-calc(0 16);

        @include breakpoint(large up) {
            padding: rem-calc(0 40);
            text-align: right;
        }

        .page-link {
            color: map-get($foundation-palette, secondary);
            font-weight: normal;
            margin: 0;
            text-decoration: underline;
        }
    }

    .search-container {
        padding: rem-calc(20 16 0);

        @include breakpoint(large up) {
            padding: rem-calc(20 40 0);
        }

        .button {
            margin: 0 0 0 7px;

            @include breakpoint(medium up) {
                margin-left: 0;
                width: auto;
            }
        }

        .button.small {
            padding: 11.5px 15px;
        }

        .start_location {
            background-color: map-get($foundation-palette, light-gray);
            border-color: map-get($foundation-palette, light-gray);
            padding: rem-calc(10 35);

            &::placeholder {
                color: map-get($foundation-palette, midgrey);
                font-weight: 400;
            }

            &:focus {
                border-color: map-get($foundation-palette, secondary);

                &::placeholder {
                    color: transparent;
                }
            }
        }

        .search-field-wrapper {
            position: relative;
            width: 100%;

            .icon-search {
                left: 10px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .icon-cancel {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .store-filters {
        .breadcrumbs-filters .button {
            background-color: map-get($foundation-palette, light-gray);
            border: 0;
            font-size: rem-calc(13px);
            margin: 0;
            width: 100%;

            &:hover {
                background-color: $button-accessible-hover;
                color: map-get($foundation-palette, secondary);
            }

            &.is-active {
                &::after {
                    content: "";
                }

                .clear-filters {
                    display: block;
                }
            }

            &.breadcrumb-filter {
                font-weight: 500;
                margin-right: rem-calc(5);
                margin-top: rem-calc(5);
                padding: rem-calc(5 20 5 5);
                position: relative;
                text-transform: none;
                width: auto;
            }

            .clear-breadcrumb-filter {
                font-size: rem-calc(16);
                position: absolute;
                right: rem-calc(2);
                top: rem-calc(4);
            }

            .clear-filters {
                @include vertical-center;
                border: 0;
                display: none;
                font-size: 17px;
                position: absolute;
                right: 5px;
            }
        }

        .breadcrumbs-wrapper {
            border: 1px solid map-get($foundation-palette, bright-grey);
            display: flex;
            flex-direction: column;
            margin-top: rem-calc(20);
            padding: rem-calc(10 16);

            @include breakpoint(large up) {
                padding: rem-calc(10 40);
            }
        }

        .filters-wrapper {
            align-items: center;
            display: flex;
            justify-content: space-between;

            &.is-active {
                .filters-button::after {
                    content: "\e80e";
                }
            }
        }

        .filters-button,
        .filters-title {
            color: $black;
            display: inline-block;
            font-size: rem-calc(14);
            font-weight: 700;
        }

        .filters-button {
            margin-right: rem-calc(15);
            position: relative;
            text-align: right;

            &::after {
                content: "\e80f";
                font-family: "fontello", sans-serif;
                font-size: rem-calc(10);
                padding-left: rem-calc(5);
                padding-top: rem-calc(3);
                position: absolute;
            }
        }
    }

    #filters-content-wrap {
        p {
            @include font-gotham-book;

            line-height: 1;
            margin: rem-calc(0 0 14);
            padding: rem-calc(0 16);

            @include breakpoint(large up) {
                padding: rem-calc(0 16 0 40);
            }
        }

        .clear-filters {
            @include font-gotham-bold;

            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-right: rem-calc(16);

            @include breakpoint(large up) {
                padding-right: rem-calc(40);
            }

            .clear-filters-button {
                background: transparent;
                border: 3px solid map-get($foundation-palette, primary);
                color: map-get($foundation-palette, secondary);
                font-size: rem-calc(12);
                margin: 0;
                padding: rem-calc(10);
                text-transform: uppercase;

                &:hover,
                &:focus {
                    background: $button-accessible-hover;
                    border: 3px solid map-get($foundation-palette, primary);
                    color: map-get($foundation-palette, secondary);
                }

                &:disabled {
                    background-color: map-get($foundation-palette, very-light-grey);
                    border-color: map-get($foundation-palette, very-light-grey);
                    color: map-get($foundation-palette, white-default);
                }
            }
        }

        .filters-content-wrapper {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
        }
    }

    .filters-overlay {
        background: transparent;
        display: none;
        height: calc(100% - 278px);
        position: absolute;
        width: 40%;
        z-index: 100;
    }

    .store-filter {
        appearance: none;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        position: absolute;
        width: 1px;

        &:not(:checked),
        &:checked {
            + label {
                @include font-gotham-book;
                margin-left: 0;
                padding: rem-calc(0 0 0 24);
                position: relative;

                &::before {
                    color: map-get($foundation-palette, midgrey);
                    content: "\f1db";
                    font-family: "fontello", sans-serif;
                    font-size: rem-calc(18);
                    left: 0;
                    position: absolute;
                }
            }
        }

        &:checked + label::before {
            color: map-get($foundation-palette, primary);
            content: "\f111";
        }

        &:disabled + label {
            color: map-get($foundation-palette, very-light-grey);

            &::before {
                color: map-get($foundation-palette, very-light-grey);
            }
        }
    }

    .hide-filter {
        display: none;
    }

    .locations-box {
        width: 100%;

        .location-item {
            border-bottom: 1px solid map-get($foundation-palette, bright-grey);
            border-left: 7px solid transparent;
            cursor: pointer;
            padding-left: rem-calc(9);
            padding-right: rem-calc(16);


            @include breakpoint(large up) {
                background-position: right 1.125rem center;
                padding-left: rem-calc(40);
                padding-right: rem-calc(40);
            }

            &.active,
            &:hover {
                border-left: 7px solid map-get($foundation-palette, primary);
            }

            &.active {
                background-color: $medium-light-light-gray;

                .pr-snippet-stars.pr-snippet-stars-png {
                    background-color: $medium-light-light-gray;
                }
            }

            .pr-snippet-rating-decimal {
                color: map-get($foundation-palette, secondary);
            }

            .store-details-wrapper {
                display: flex;
                justify-content: space-between;
            }

            .cb-cta {
                margin: 0;
            }

            p {
                margin: 0 0 5px;
            }
        }

        .scroll-loader {
            bottom: -70px;
            display: none;
            top: auto;
        }

        .location-item-inner {
            padding: 20px 0 15px;
        }

        .phone-number {
            @include font-gotham-bold;
        }

        .location-item-distance {
            color: map-get($foundation-palette, secondary);
            font-weight: 300;
        }
    }

    .location-item-name {
        display: flex;
        justify-content: space-between;

        p {
            @include font-gotham-bold;

            font-size: rem-calc(16);
            line-height: 1.3;
            margin: rem-calc(0 0 10);
        }

        .nickname {
            @include font-gotham-book;
            display: block;
        }
    }

    .map-view {
        overflow: hidden;
        position: relative;

        .error-container {
            background-color: map-get($foundation-palette, primary);
            display: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;

            .close-button {
                color: map-get($foundation-palette, white-default);
                cursor: pointer;
                font-size: rem-calc(18);
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                transform: translateY(-50%);
                transform: translateY(-50%);
            }

            .error-message {
                color: map-get($foundation-palette, secondary);
                font-size: rem-calc(14);
                margin: 0;
                padding: 15px 40px 15px 15px;
            }
        }

        .loader {
            z-index: 10;
        }

        .gps-toggle {
            background: map-get($foundation-palette, white-default);
            border: 1px solid #d0d0d0;
            bottom: 15px;
            cursor: pointer;
            display: inline-block;
            padding: 6px 8px;
            position: absolute;
            right: 15px;
            z-index: 10;

            i {
                color: #ddd;
                font-size: 24px;
            }

            &.active i {
                color: map-get($foundation-palette, secondary);
            }

        }
    }
}

.pt_store-locator {
    #filters-content-wrap.expanded {
        background: map-get($foundation-palette, white-default);
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}

@include breakpoint(small only) {
    .map-view {
        max-width: 100%;
        width: 100%;
    }

    .view-map-btn {
        @include font-gotham-bold;
        float: right;

        font-size: rem-calc(13);
        margin: 0 0 20px;

        .active-btn-text {
            display: none;
        }

        &.is-active {
            .btn-text {
                display: none;
            }

            .active-btn-text {
                display: block;
            }
        }
    }

    .locations-box {
        float: left;
    }
}

#map-box {
    background-color: $map-background-grey;
    min-height: 320px;
}

@include breakpoint(medium up) {
    .store-locator-page {
        #map-box {
            float: right;
            width: 60%;
        }

        .map-view {
            max-width: 100%;
            width: 100%;
        }

        .list-view {
            padding-top: 278px;
            width: 40%;
        }

        .search-filter-box {
            float: left;
            width: 40%;
        }

        .search-container {
            .start_location {
                margin: 0;
            }

            .button {
                margin-left: rem-calc(10);
            }
        }
    }

    .pt_store-locator {
        #filters-content-wrap.expanded {
            height: calc(100% - 278px);
            overflow: auto;
            position: absolute;
            width: 40%;
        }
    }
}

.locations-box {
    overflow-y: scroll;
}

.todays-hours-toggle {
    @include font-gotham-book;

    .store-hours-expanded {
        opacity: 0;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        visibility: hidden;

        .store-schedule {
            @include font-gotham-bold;
        }
    }

    .store-hours-collapsed {
        display: inline-block;
        margin-bottom: rem-calc(8);

        * {
            cursor: pointer;
        }

        &::after {
            content: "\e80f";
            cursor: pointer;
            font-family: "fontello", sans-serif;
            font-size: rem-calc(10);
            padding-left: rem-calc(5);
            padding-top: rem-calc(3);
            position: absolute;
        }
    }

    &.is-active {
        .store-hours-collapsed::after {
            content: "\e80e";
        }

        .store-hours-expanded {
            opacity: 1;
            visibility: visible;
        }
    }
}

.todays-hours-label {
    font-weight: bold;
}

.store {
    &-closed {
        color: map-get($foundation-palette, red-accessible);
        font-weight: bold;
    }

    &-open {
        color: map-get($foundation-palette, green-accessible);
        font-weight: bold;
    }
}

.store-hours-wrapper {
    @include font-gotham-book;

    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.expanded {
        max-height: 500px;
    }
}


.store-schedule {
    @include font-gotham-book;

    display: grid;
    gap: 10px;
    grid-template-columns: auto minmax(0, 1fr);
    justify-items: start;

    .store-day {
        width: 110px;
    }
}
