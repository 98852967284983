// Replace products from cart - Pop-up
#addtocartpopup {
    padding: 20px;

    @include breakpoint(small only) {
        .product-image {
            display: none;
        }
    }

    .close-button {
        color: map-get($foundation-palette, primary);
    }

    .product-attributes,
    .categories-box,
    .availability-msg,
    .recommended-bagde,
    .promo-badge,
    .tested-bykal-badge {
        display: none; 
    }

    .product-size {
        .product-attributes {
            display: block;
        }
    }

    .popup-title {
        font-size: rem-calc(21);
        margin: 0 0 10px;
        padding-right: 1rem;

        @include breakpoint(medium up) {
            font-size: 18px;
        }
    }

    .popup-subtitle {
        @include font-gotham-book;

        font-size: rem-calc(14);
        margin: 0;
    }

    .new-cart-item {
        margin: 30px 0 25px;
    }

    .product-tile-box {

        @include breakpoint(medium up) {
            float: left;
            width: 49%;
        }

        &:first-of-type {
            margin: 0 0 0.625rem;

            @include breakpoint(medium up) {
                margin: 0 2% 0 0;
            }
        }
    }

    .product-tile {
        @include clearfix;
    }

    .product-name {
        @include font-gotham-bold;
        color: #000;

        font-size: rem-calc(18);
        line-height: 1;
        margin: 0 0 15px;
        word-wrap: break-word;

        .brand-name {
            display: block;
            font-size: rem-calc(12);
        }

        a {
            color: #000;
        }
    }

    .product-pricing {
        padding: 0 0 0 15px;

        .box-spacing {
            padding: 0 0 10px;
        }

        .product-price {
            @include clearfix;
        }

        .rebate {
            display: inline-block;
        }
    }

    .actions {
        border-bottom: 2px solid #d9d9da;
        padding: 0 0 25px;
        text-align: center;

        &.no-border {
            border: 0 !important;
        }

        .button {
            margin: 0 0 20px;
            width: 100%;
        }

        .cancel {
            @include font-gotham-bold;

            font-size: rem-calc(11);
        }
    }

    .actions-margin {
        margin: 1.5rem 0 0;
    }

    .current-cart {
        margin: 25px 0 0;

        @include breakpoint(medium up) {
            margin: 40px 0 0;
        }

        .product-badge {
            display: none;
        }

        .popup-subtitle {
            margin: 0 0 15px;
        }

        .product-tile {
            border: 6px solid map-get($foundation-palette, light-gray);
            padding: 15px 10px;

            @include breakpoint(medium up) {
                padding: 20px;
            }
        }

        .product-size,
        .product-image {
            display: none;
        }

        .product-name {
            float: left;
            width: 60%;
        }

        .product-pricing {
            font-size: rem-calc(20);
            width: 40%;

            .currency-indices {
                font-size: rem-calc(10);
            }
        }
    }
}

#cart-popup-container {
    .product-tile-content {
        position: relative;
    }

    .product-badge {
        max-width: 25%;
        position: absolute;

        span {
            margin-top: 0;
        }
    }
}

@include breakpoint(medium up) {
    #addtocartpopup {
        width: 700px;

        .new-cart-item {
            .product-image {
                flex: 0 0 25%;
                flex: 0 0 25%;
                margin-right: 8.33333%;
                max-width: 25%;
            }

            .product-box {
                flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
                max-width: 66.66667%;
            }

            .product-tile {
                border: 6px solid map-get($foundation-palette, light-gray);
                padding: 20px;
            }

            .product-tile-content {
                display: flexbox;
                display: flex;
                flex-flow: row wrap;
                flex-flow: row wrap;
                margin-left: auto;
                margin-right: auto;
                max-width: 82.5rem;
            }
        }

        .actions {
            align-items: center;
            border-width: 1px;
            display: flex;
            justify-content: flex-end;
            padding: 0 0 40px;

            .button {
                margin: 0;
                width: auto;
            }

            .cancel {
                margin: 0 15px 0 0;
            }
        }
    }
}
