.pt_carfax {
    @include breakpoint(small only) {
        .row.atroot {
            .content-column {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .page-description {
        font-size: rem-calc(16);
        margin-bottom: 25px;
    }

    .about-carfax-wrapper {
        .about-carfax-title {
            margin-top: rem-calc(50);
        }

        .toggle-item {
            .item-content {
                position: relative;

                a {
                    z-index: 2;
                }
            }
        }
    }

    .no-service-history {
        margin-bottom: rem-calc(50);
    }
}

.service-history-wrap {
    &[toggle-state="initial"] {
        .service-history {
            display: none;
        }

        .no-service-history {
            display: none;
        }
    }

    &[toggle-state="no-service"] {
        .service-history {
            display: none;
        }
    }

    &[toggle-state="has-service"] {
        .no-service-history {
            display: none;
        }
    }
}

.service-history {
    width: 100%;

    .history-header {
        background-color: map-get($foundation-palette, light-gray);
        display: flex;
        padding: rem-calc(20);
        position: relative;

        @include breakpoint(small only) {
            padding: rem-calc(15) rem-calc(10);
        }

        .header-content {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .logo-container {
            display: flex;
            flex: 0;

            @include breakpoint(small only) {
                align-items: flex-end;
            }
        }

        .carfax-logo {
            bottom: rem-calc(20);
            height: auto;
        }

        .delete-button {
            color: map-get($foundation-palette, primary-accessible);
            cursor: pointer;
            flex: 1;
            font-size: rem-calc(13);
            text-decoration: underline;
        }
    }

    .history-table {
        width: 100%;

        .table-header {
            background-color: $extra-light-gray;

            @include breakpoint(small only) {
                .table-column.services-performed {
                    display: none;
                }
            }

            .table-row {
                border-bottom: 0;
            }
        }

        p {
            font-size: rem-calc(14);
            line-height: 20px;
            margin-bottom: 0;
        }

        b {
            font-size: rem-calc(14);
        }
    }

    .table-row {
        border-bottom: 1px solid $light-gray-over;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(small only) {
            gap: rem-calc(10);
            padding: rem-calc(10);
        }
    }

    .table-column {
        flex: 1;
        padding: rem-calc(10) rem-calc(15);

        @include breakpoint(small only) {
            padding: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        &.services-performed {
            flex: 2;

            @include breakpoint(small only) {
                min-width: 100%;

                b.mobile-header {
                    display: inline;
                }
            }

            .mobile-header {
                display: none;
            }
        }
    }
}
