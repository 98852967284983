#results-info-from-gsp {
    margin: 25px 0 50px;

    img {
        display: block;
        width: 100%;

        @include breakpoint(small only) {
            margin-bottom: 20px;
        }
    }

    h4 {
        margin-bottom: 24px;
    }

    #change-responses-cta {
        @include font-gotham-bold;

        font-size: 13px;
    }

    &.catcolor-all-weather h4 span {
        color: $purple;
    }

    &.catcolor-winter h4 span {
        color: $blue-whiter;
    }

    &.catcolor-3-season h4 span,
    &.catcolor-all-season h4 span {
        color: $orange-yellow;
    }

    &.catcolor-performance h4 span {
        color: $purple-darker;
    }

    &.catcolor-touring h4 span {
        color: $green;
    }

    &.catcolor-mud-terrain h4 span {
        color: $brown-darker;
    }

    &.catcolor-sport-truck h4 span {
        color: $green-whiter;
    }

    &.catcolor-all-terrain h4 span {
        color: $brown;
    }

    &.catcolor-commercial h4 span {
        color: $grey-01;
    }

    &.catcolor-ultra-high-performance h4 span {
        color: $grey-02;
    }
}

#guidedSellingPathWrapper {
    @include breakpoint(large up) {
        .atroot {
            padding: 0;
        }
    }

    #gspMobileClose {
        @include font-gotham-bold;
        display: inline-block;
        font-size: 12px;

        padding: 20px 0;
    }

    h4 {
        font-size: rem-calc(18);
        margin: 0 0 30px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: rem-calc(21);
            margin-top: 50px;
            text-transform: none;
        }
    }

    h3 {
        @include font-gotham-book;
        font-size: rem-calc(14);

        padding: 1.5em 0 0.75em;

        @include breakpoint(large up) {
            font-size: rem-calc(20);
        }
    }

    .gsp-question-wrapper {
        background: rgba(map-get($foundation-palette, light-gray), 0.85);
    }

    .gsp-question-number {
        @include font-gotham-bold;
        border: 3px solid map-get($foundation-palette, primary);
        border-radius: 1em;

        display: inline-flex;
        height: 2em;
        justify-content: center;
        line-height: 2em;
        margin: 0 1em 0 -3.5em;
        min-width: 2em;
        width: 2em;
    }

    .gsp-question-title {
        @include font-gotham-book;
        background: map-get($foundation-palette, darkgrey1) url("../images/kaltire/question-header-image.png") -42px center no-repeat;
        background-size: 194px;
        display: flex;
        font-size: rem-calc(16);

        position: relative;

        @include breakpoint(medium up) {
            font-size: rem-calc(22);
            padding: 0 0.625rem;
        }

        @include breakpoint(large up) {
            background-color: rgba(map-get($foundation-palette, darkgrey1), 0.85);
            background-image: url("../images/kaltire/question-header-image@2x.png");
            background-position: -66px center;
            background-size: 48%;
        }

        > div {
            align-items: center;
            color: $white;
            display: flex;
            justify-content: flex-start;
            line-height: 1.25em;
            padding-bottom: 1.375em;
            padding-top: 1.375em;

            @include breakpoint(medium up) {
                padding-bottom: 0.9775em;
                padding-right: 3rem;
                padding-top: 0.9775em;
            }

            @include breakpoint(large up) {
                padding-bottom: 1.4095em;
                padding-top: 1.4095em;
            }
        }
    }

    .gspAnswer {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .fa-times {
        color: map-get($foundation-palette, white-default);
        cursor: pointer;
        margin-top: -11px;
        position: absolute;
        right: 20px;
        top: 50%;

        @include breakpoint(large up) {
            right: 32px;
        }

        &:hover {
            color: map-get($foundation-palette, light-gray);
        }
    }

    .gsp-question-style-multiselect {
        .gspAnswer {
            background-position: top center;
            background-size: cover;
            flex-basis: calc(50% - 22.5px);
            flex-basis: calc(50% - 22.5px);
            height: 8.5em;
            margin: 0 0 1.2em 0.9375rem;
            max-width: calc(50% - 22.5px);

            @include breakpoint(medium up) {
                flex-basis: calc(33% - 20px);
                flex-basis: calc(33% - 20px);
                height: 11em;
                margin: 0 0.625rem 1.2em;
                max-width: calc(33% - 20px);
            }

            @include breakpoint(large up) {
                flex-basis: calc(20% - 20px);
                flex-basis: calc(20% - 20px);
                height: 12.5em;
                max-width: calc(20% - 20px);
            }

            span {
                background: $white;
                color: map-get($foundation-palette, secondary);
                font-size: rem-calc(12);
                font-weight: bold;
                height: 37px;
                padding: 0 6px;
                position: relative;
                text-align: center;
                text-transform: uppercase;
                word-wrap: break-word;
                z-index: 2;

                @include breakpoint(medium up) {
                    font-size: rem-calc(13);
                    height: 43px;
                }
            }

            &:hover span {
                height: 49px;
            }
        }

        .selected {
            border: 0;
            position: relative;

            span {
                background: map-get($foundation-palette, primary);
            }

            &::after {
                background: rgba(map-get($foundation-palette, secondary), 0.4);
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            &::before {
                border: 3px solid map-get($foundation-palette, white-default);
                border-radius: 1.6875rem;
                box-sizing: border-box;
                color: map-get($foundation-palette, white-default);
                content: "\f00c";
                display: block;
                font-family: fontawesome;
                font-size: rem-calc(23);
                height: 3rem;
                left: 50%;
                margin-left: -1.5rem;
                position: absolute;
                text-align: center;
                top: 1.6875rem;
                width: 3rem;
                z-index: 2;

                @include breakpoint(medium up) {
                    border-width: 5px;
                    font-size: rem-calc(28);
                    height: 3.4375rem;
                    margin-left: -1.6875rem;
                    top: 3.125rem;
                    width: 3.4375rem;
                }
            }
        }
    }

    .gsp-question-style-radioselecta {
        .gspAnswer {
            align-self: flex-start;

            @include breakpoint(small only) {
                align-self: flex-start;
                justify-content: flex-start;
                width: 50px;
            }

            &:first-child {
                align-items: flex-start;

                h4,
                p {
                    text-align: left;
                }
            }

            &:last-child {
                align-items: flex-end;

                h4,
                p {
                    text-align: right;
                }
            }

            h4 {
                font-size: rem-calc(13);
                margin: 2em 0 0.75em;
                position: relative;
                text-align: center;
                text-transform: uppercase;
            }

            p {
                color: rgba(map-get($foundation-palette, midgrey), 0.7);
                font-size: rem-calc(12);
                line-height: 1em;
                max-width: 200px;
                text-align: center;
                text-align: center;
            }

            &.selected {
                border: 0;
            }
        }

        .option-bg {
            background: map-get($foundation-palette, primary);
            border-radius: 24px;
            height: 47px;
            margin-top: 3.75em;
            width: 100%;

            @include breakpoint(medium up) {
                margin-top: 9.375em;
            }
        }
    }

    .gsp-question-style-radioselectb,
    .gsp-question-style-radioselectc {
        .gspAnswer {
            @include font-gotham-book;
            background: $white;
            color: map-get($foundation-palette, secondary);

            justify-content: center;
            text-align: center;

            p {
                font-size: inherit;
                line-height: 1.3125em;
                width: 14rem;
            }

            strong {
                @include font-gotham-bold;
            }
        }
    }

    .gsp-question-style-radioselectb {
        .gspAnswer {
            flex-basis: 100%;
            height: 14.1875rem;
            margin: 30px 1.875rem;

            @include breakpoint(medium up) {
                flex-basis: 40%;
                height: 14.1875rem;
                margin: 30px 1.875rem;

                &:last-child {
                    margin-left: 0.9375rem;
                    margin-right: 1.25rem;
                }
            }

            @include breakpoint(large up) {
                height: 14.1875rem;
                margin: 30px 1.875rem;

                p {
                    text-align: center;
                    width: 12.13rem;
                }

                &:last-child {
                    margin-left: 0.9524em;
                    margin-right: 0;
                }
            }

            &:hover {
                border: 4px solid map-get($foundation-palette, primary);
            }

            span {
                background: map-get($foundation-palette, primary);
                color: $white;
                font-size: rem-calc(12);
                font-weight: bold;
                height: 37px;
                position: relative;
                text-transform: uppercase;
                z-index: 2;

                @include breakpoint(medium up) {
                    font-size: rem-calc(13);
                    height: 43px;
                }
            }

            &:hover span {
                height: 49px;
            }
        }

        img {
            height: 4.375rem;
            margin-bottom: 0.8rem;

            @include breakpoint(large up) {
                height: 5.625rem;
            }
        }

        .selected {
            border: 4px solid map-get($foundation-palette, primary);
            position: relative;

            &::after {
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            &::before {
                background: $white;
                border: 3px solid $white;
                border-radius: 1.6875rem;
                border-width: 5px;
                box-sizing: border-box;
                color: map-get($foundation-palette, primary);
                content: "\f00c";
                display: block;
                font-family: fontawesome;
                font-size: 1.5rem;
                height: 3.0375rem;
                position: absolute;
                right: 0.725rem;
                text-align: center;
                top: 0.525rem;
                width: 3.0375rem;
                z-index: 2;
            }
        }
    }

    .gsp-question-style-radioselectc {
        .gsp-question-number {
            margin-left: -3.75em;
        }

        .gspAnswer {
            height: 13rem;
            margin: 0 0.9375rem 1.875rem;

            @include breakpoint(medium up) {
                height: 13.8125rem;
                margin-top: -0.4375rem;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            @include breakpoint(large up) {
                flex-direction: row;
                height: 17rem;
            }

            img {
                height: 8.625rem;

                @include breakpoint(large up) {
                    height: 10.8125rem;
                }
            }

            p {
                margin-bottom: 0.7rem;
                width: 14rem;

                @include breakpoint(large up) {
                    font-size: rem-calc(22);
                    margin-bottom: 0.7rem;
                    padding-left: 0.9375rem;
                    text-align: left;
                    width: 19rem;
                }
            }
        }
    }

    .button {
        background: transparent;
        border: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, secondary);
        margin-bottom: 3.25em;
        padding: 13px 2.5em;
        text-transform: uppercase;

        &:hover {
            background: $button-hover;
        }

        &.disabled {
            background: map-get($foundation-palette, midgrey);
            border-color: map-get($foundation-palette, midgrey);
            color: $white;
        }
    }
}

.gsp-question-style-radioselecta .gspAnswer {
    @include breakpoint(small only) {
        h4,
        p {
            display: none;
        }

        &:first-child,
        &:last-child {
            h4,
            p {
                display: block;
            }
        }
    }

    .option {
        align-items: center;
        background: $white;
        border: 1px solid map-get($foundation-palette, primary);
        border-radius: 18px;
        display: flex;
        height: 36px;
        justify-content: center;
        margin: -42px 5px 0;
        width: 36px;

        &::after {
            background: rgba(map-get($foundation-palette, midgrey), 0.2);
            border-radius: 10px;
            content: "";
            display: block;
            height: 15px;
            width: 15px;
        }
    }

    &.selected .option,
    .option:hover {
        border: 1px solid $white;

        &::after {
            background: map-get($foundation-palette, primary);
        }
    }
}

.gsp-question-style-radioselectc {
    .gsp-question-answers ul {
        @include breakpoint(large up) {
            pointer-events: none;
        }

        &:hover .gspAnswer {
            &:hover {
                border-color: map-get($foundation-palette, primary);
                border-width: 4px;
            }
        }
    }

    .gspAnswer {
        flex-basis: 100%;
        font-size: rem-calc(18);

        @include breakpoint(medium up) {
            flex-basis: 40%;
        }

        @include breakpoint(large up) {
            font-size: rem-calc(21);
            pointer-events: auto;

            &.selected {
                border-color: map-get($foundation-palette, primary);
            }
        }

        strong::after {
            content: ",";
        }
    }
}
