.all-stores {

    @include breakpoint(medium up) {
        .plus-minus-accordion .is-accordion-submenu-parent > a {
            padding-left: 22px;

            &::after {
                left: 0;
            }
        }

        .submenu {
            column-count: 2;
            column-count: 2;
            column-count: 2;

            &[aria-hidden=false] {
                display: block !important;
            }
        }
    }

    .city-name {
        @include font-gotham-bold;

        font-size: 15px;
        margin: 5px 0 -2px;
        text-transform: capitalize;
    }

    .store-name {
        @include font-gotham-medium;

        font-size: 13px;
        margin: 0 0 15px;
        padding: 0;
        text-transform: capitalize;
    }

    .menu .city-box {
        display: inline-flex;
        flex-direction: column;
        margin: 0 0 10px;
        width: 100%;
    }
}
