.pt_product-search-result,
.pt_product-search-noresult,
.pt_product-compare,
.pt_product-details,
.pt_cart,
.pt_checkout,
.pt_order-confirmation {
    #main {
        margin-bottom: 100px;
    }
}

.pt_product-search-result {
    #main {
        @include breakpoint(small only) {
            margin-bottom: 0;
        }
    }

    .search-results-resource-center {
        @include breakpoint(small only) {
            margin-bottom: 100px;
        }
    }
}

.pt_account {
    .reset-password-page {
        margin-bottom: 100px;
    }
}
