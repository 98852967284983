.pt_mygarage {
    @include breakpoint(small down) {
        .columns.mobile-padding-right-6px {
            padding-right: 6px;
        }

        .columns.mobile-padding-left-6px {
            padding-left: 6px;
        }
    }

    .width-50-percent {
        width: 50%;
    }

    .dotted-border {
        border-bottom: 3.5px dotted map-get($foundation-palette, primary);
    }

    .arrow {
        color: map-get($foundation-palette, primary);
    }

    .top-section,
    .top-section-archive {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        margin: 15px 0;

        @include breakpoint(small down) {
            flex-wrap: wrap;

            p,
            .car-add-new,
            button {
                width: 100%;
            }

            .car-add-new {
                margin-top: 25px;
            }
        }

        p {
            @include font-gotham-medium;

            font-size: 18px;
            font-weight: 700;
            line-height: 21.6px;
            margin: 0;

            @include breakpoint(small down) {
                font-size: 16px;
            }
        }
    }

    .top-section {
        @include breakpoint(small down) {
            margin: 0;
        }
    }

    .top-section-archive {
        @include breakpoint(small down) {
            p {
                width: 75%;
            }

            button {
                width: 30%;
            }
        }
    }

    .subtitle-text {
        font-size: 16px;
        margin-bottom: 25px;

        @include breakpoint(small down) {
            font-size: 14px;
        }
    }

    .car-add-new {
        button.cb-cta.button-hollow-black {
            font-size: 13px;
            margin: 0;
            padding: 11px 15px;
        }
    }

    .cars-container,
    .cars-container-archived {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(small down) {
            margin-left: -15px;
            width: calc(100% + 30px);
        }
    }

    .car-container {
        background: map-get($foundation-palette, light-gray);
        margin-bottom: 50px;
        padding: 10px 10px 30px;
        width: 100%;

        @include breakpoint(medium up) {
            margin-bottom: 30px;
            padding: 10px;
        }

        p {
            margin: 0;
        }

        .cb-cta {
            margin: 5px 0;
        }
    }

    .car-visuals {
        align-content: center;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        justify-content: center;
        position: relative;
        text-align: center;

        .questionmarkcircle-container {
            border: 0;
            height: 16px;
            left: 0;
            position: absolute;
            top: 0;
            width: 16px;
        }

        .questionmarkcircle {
            color: map-get($foundation-palette, secondary);
            font-size: 9px;
            font-weight: bold;
            height: 16px;
            width: 16px;
        }
    }

    .car-information {
        @include font-gotham-book;

        background: map-get($foundation-palette, white-default);
        display: flex;
        flex-direction: column;
        font-size: 13px;
        height: 100%;
        line-height: 1;
        padding: 15px;
        position: relative;
        width: 100%;

        &::before {
            @include breakpoint(medium up) {
                border-color: map-get($foundation-palette, white-default) transparent;
                border-style: solid;
                border-width: 14px 14px 0;
                content: "";
                display: block;
                left: -21px;
                position: absolute;
                top: calc(50% - 7px);
                transform: rotate(90deg);
                width: 0;
                z-index: 0;
            }
        }
    }

    .car-image {
        max-height: 100%;
        max-width: 100%;
        min-height: 70px;
        padding: 10px 10px 0;

        @include breakpoint(small down) {
            max-height: 100px;
            max-width: 200px;
            min-height: 90px;
        }

        .loader {
            display: block;
        }
    }

    .car-name,
    .car-vin,
    .car-plate-add,
    .car-plate-edit {
        @include font-gotham-medium;

        color: map-get($foundation-palette, primary);
        cursor: pointer;
        font-size: 12px;
        line-height: 14.4px;
        padding-top: 2px;
        text-decoration: underline;
        width: 100%;

        @include breakpoint(small down) {
            margin-bottom: 15px;
        }
    }

    .car-plate-edit,
    .car-name,
    .car-vin {
        color: map-get($foundation-palette, secondary);
        padding-bottom: 4px;
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }

    .car-title {
        @include font-gotham-bold;

        font-size: 17px;
        line-height: 1;
    }

    .car-name {
        @include font-gotham-medium;

        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;

        i {
            color: map-get($foundation-palette, primary);
            font-size: 15px;
        }
    }

    .car-tire-wheel > div {
        line-height: 17px;
        margin-top: 14px;

        @include breakpoint(small down) {
            margin-top: 12px;
        }
    }

    .car-tire-wheel,
    .car-info-bottom {
        display: flex;
        width: 100%;
    }

    .car-info-bottom {
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        margin-top: auto;
        padding-top: 16px;
        row-gap: 17px;

        a {
            color: map-get($foundation-palette, secondary);

            @include breakpoint(small down) {
                width: 100%;
            }
        }
    }

    .car-no-order-history {
        color: map-get($foundation-palette, midgrey);
    }

    .car-appointment {
        background: map-get($foundation-palette, white-default);
        border-left: 5px solid map-get($foundation-palette, primary);
        margin-top: 16px;
        padding: 15px 20px;
    }

    .car-appointment-title {
        @include font-gotham-medium;

        font-size: 16px;
        line-height: 22px;
        padding-bottom: 5px;
    }

    .car-appointment-info {
        @include font-gotham-book;

        font-size: 14px;
        line-height: 20px;
    }

    .appointment-map,
    .modify-appointment {
        color: map-get($foundation-palette, primary);
    }

    .appointment-map {
        text-transform: capitalize;
    }

    .modify-appointment {
        font-size: rem-calc(14);
    }

    .car-buy-actions {
        flex-flow: row wrap;
        justify-content: space-evenly;
        margin-top: 6px;

        a {
            @include font-gotham-bold;

            flex-grow: 1;
            font-size: 13px;
            line-height: 15px;
            text-align: center;

            @include breakpoint(small down) {
                font-size: 11px;
                line-height: 12px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .car-buy-action,
    .services-cta {
        width: 100%;

        &.cb-cta.button-hollow-black,
        &.cb-cta.button-full-white {
            padding: 11px 0;
        }
    }

    .services-cta {
        background-color: $white;

        &:hover,
        &:focus {
            background: $button-accessible-hover;
        }
    }

    .custom-class {
        .questionmarkcircle {
            float: left;
        }
    }

    .highlight-existing-car {
        border: 2px solid map-get($foundation-palette, primary);
        transition: border-width 0.5s linear;

        &.transition-to-end {
            border-width: 0;
        }
    }

    .toggle-archived-cars {
        color: map-get($foundation-palette, secondary);
        font-size: 14px;

        i {
            font-size: 10px;
            vertical-align: top;
        }
    }

    .archived-cars {
        margin-top: 20px;

        @include breakpoint(small down) {
            margin-left: -15px;
            width: calc(100% + 30px);
        }
    }
}

.service-actions-modal {
    top: 50%;

    @include breakpoint(small down) {
        height: auto;
        margin: auto;
        min-height: auto;
        width: 95%;
    }

    .modal-title {
        margin: 8px 0;
        padding: 0 10px;
    }

    .car-buy-actions {
        padding: 10px 0;
    }

    .car-buy-action {
        text-align: center;
        width: 100%;
    }

    .modal-close {
        background-color: transparent;
        color: map-get($foundation-palette, primary);
        position: absolute;
        right: 15px;
        top: 15px;

        span {
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
        }
    }
}

.text-align-right {
    text-align: right;
}

.flex-order-1 {
    order: -1;
}

.garage-modal-title {
    margin-bottom: 30px;
}

[form-ctrl] {
    .vin-container {
        display: none;
    }

    &[form-ctrl="carfax"],
    &[form-ctrl="editVehicleData"] {
        .vin-container {
            display: block;
        }
    }
}

.garage-modal {
    width: 100%;

    &#verify-vehicle-modal {
        .description {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .modal-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .modal-close {
        background-color: transparent;
        position: absolute;
        right: 15px;
        top: 15px;

        span {
            font-size: 30px;
            font-weight: 400;
            line-height: 1;
        }
    }

    .description {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        padding: 0;
    }

    .vehicles-section {
        gap: 20px;

        .car-container {
            max-width: calc(50% - 10px);
            padding: 0;
        }
    }

    .name-your-vehicle-fields {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        @include breakpoint(small down) {
            .field-container {
                max-width: 100%;
                min-width: 100%;

                &:nth-child(even),
                &:nth-child(odd) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .field-container {
            flex: 1;
            margin-bottom: 15px;
            max-width: 50%;
            min-width: 50%;

            &:nth-child(even) {
                padding-left: 15px;
            }

            &:nth-child(odd) {
                padding-right: 15px;
            }

            .field-label {
                font-size: 14px;
                font-weight: bold;
                line-height: 16px;
            }
        }
    }

    .buttons-wrap {
        display: flex;
        justify-content: space-between;

        &.align-to-right {
            justify-content: right;
        }

        button {
            font-size: 12px;
            height: 42px;
            line-height: 20px;
            margin-bottom: 0;
            max-width: 200px;
            min-width: 200px;
            padding: 0 20px;

            @include breakpoint(small down) {
                flex: 1;
                max-width: none;
                min-width: auto;
            }

            &:disabled {
                background-color: $extra-light-gray;
                border-color: $extra-light-gray;
                opacity: 1;
            }
        }

        &.fit-width {
            button {
                max-width: none;
                min-width: 200px;
            }
        }
    }

    .close-button {
        color: $black;
    }

    p {
        padding: 10px 0;
    }

    a {
        margin: 0;
    }

    input {
        margin: 6px 0 0;
        padding: 10px 15px;
    }

    .plate-no-container {
        margin-bottom: 30px;
    }

    .cb-cta.button-hollow-black,
    .cb-cta.button-full-white {
        padding: 8px 60px;
    }
}

.vehicle-exists-modal {
    max-width: 400px;
}

.plate-number-modal {
    min-height: 100px;
}

.plate-number-modal,
.car-edit-modal,
.vehicle-info-modal,
#verify-vehicle-modal {
    max-width: 700px;
    padding: 30px;

    .garage-vehicle-name {
        @include font-gotham-bold;

        font-size: 20px;
        line-height: 24px;
        padding: 0 36px 20px 0;
    }
}

#verify-vehicle-modal {
    max-height: 80vh;
    overflow: auto;
}

.car-edit-modal {
    p {
        padding: 0 0 10px;
    }
}

.add-plate-no {
    @include font-gotham-medium;

    color: map-get($foundation-palette, primary);
    cursor: pointer;
    font-size: 12px;
    line-height: 14.4px;
    text-decoration: underline;
}
