.financing-blocks {
    &.active-plan {
        .cb-column-child {
            background-color: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, white-default);

            h2,
            a {
                color: map-get($foundation-palette, white-default);
            }

            a {
                text-decoration: none;
            }
        }
    }

    .cb-column-child {
        background-color: map-get($foundation-palette, white-default);
        color: map-get($foundation-palette, secondary);
        min-height: 230px;
        position: relative;
        text-align: center;

        h2 {
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(90px);
            margin-bottom: 0;
            padding-top: 20px;
        }

        p.financing-month-text {
            @include font-gotham-bold;

            font-size: rem-calc(30px);
            margin-bottom: 56px;

            +p.financing-small-info {
                letter-spacing: -2px;
                position: relative;
            }
        }

        p.financing-info {
            font-size: 1.45rem;
            line-height: 1.6rem;
            margin-bottom: 0;
            padding: 0 10px;
        }

        p.financing-small-info {
            bottom: 12px;
            font-size: rem-calc(24px);
            left: 0;
            position: absolute;
            right: 0;
        }

        a {
            color: map-get($foundation-palette, secondary);
            text-decoration: underline;
        }

        img {
            margin: 40px auto 0;
            max-height: 90px;
            width: auto;
        }
    }

    &.steps-to-take {
        .cb-column-child {
            border: 7px solid #1e1e1e;
            min-height: 438px;

            @include breakpoint(medium only) {
                min-height: 505px;
            }

            &:hover {
                background-color: map-get($foundation-palette, white-default);
                color: map-get($foundation-palette, secondary);

                h2,
                a {
                    color: map-get($foundation-palette, secondary);
                }

                a {
                    text-decoration: underline;
                }
            }

            p.financing-small-info {
                font-size: rem-calc(13px);
                line-height: 1.115rem;
                padding: 0 22px;
            }
        }

        h2 {
            color: map-get($foundation-palette, secondary);
            font-size: 1.4rem;
            font-weight: bold;
            padding: 30px 0 18px;
        }
        
    }
}

.atroot {
    .financing-blocks:not(.steps-to-take):nth-child(1),
    .financing-blocks:not(.steps-to-take):nth-child(2) {
        border-right: 2px solid #ff3900;

        @include breakpoint(small only) {
            border-right: 0;
            position: relative;

            &::before {
                border-bottom: 2px solid #ff3900;
                bottom: 0;
                content: "";
                height: 1px;
                left: 37%;
                position: absolute;
                width: 25%;
            }
        }
    }
}

.last-monthly-column {
    padding-bottom: 50px !important;

    @include breakpoint(small only) {
        border-top: 2px solid #f2f2f2;
    }

    .columns {
        @include breakpoint(medium up) {
            border-top: 2px solid map-get($foundation-palette, primary);
            padding-top: 50px;
        }
    }

    .cb-column-child {
        h2 {
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(90px);
            margin-bottom: 0;
            text-align: center;
        }

        p.financing-month-text {
            font-size: rem-calc(30px);
            font-weight: bold;
            margin-bottom: 41px;
            text-align: center;
        }

        p.financing-info-1,
        p.financing-info-2 {
            @include font-gotham-bold;

            color: map-get($foundation-palette, primary);
            line-height: 1em;
        }

        p.financing-info-1 {
            font-size: 2rem;
        }

        p.financing-info-2 {
            font-size: 3.2rem;
        }

        p.financing-small-info {
            font-size: 22px;
            letter-spacing: -2px;
        }

        .cb-column-child-copy:last-child {
            @include breakpoint(medium up) {
                padding: 0 50px;
            }

            @include breakpoint(small only) {
                text-align: center;

                p.financing-info-1,
                p.financing-info-2 {
                    text-align: left;
                }

                a {
                    margin: 0 auto;
                    width: 90%;
                }
            }

            a {
                text-align: center;
                width: 100%;
            }

            p.terms-conditions {
                text-align: center;

                a {
                    color: #000;
                    font-size: 13px;
                    text-align: center;
                    text-decoration: underline;
                }
            }
        }
    }
}

.cb-column-child-copy.single-column {
    text-align: center;

    p.terms-conditions { 
        a {
            color: #000;
            font-size: 15px;
            text-align: center;
            text-decoration: none;
        }
    }
}

.financing-apply-now-cta {
    margin-bottom: 50px;

    a {
        min-width: 220px;
    }

    .cb-cta {
        margin: 0;
    }
}
