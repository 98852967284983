@charset 'UTF-8';

/* Icons */
@font-face {
    font-family: "slick";
    font-style: normal;
    font-weight: normal;

    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
    background: transparent;
    border: 0;

    color: transparent;

    cursor: pointer;

    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    outline: none;
    padding: 0;

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transform: translate(0, -50%);
    transform: translate(0, -50%);

    width: 20px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background: transparent;
    color: transparent;
    outline: none;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
    opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
    opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
    color: $white;
    font-family: "slick";
    font-size: 20px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;

    opacity: 0.75;
}

.slick-prev {
    left: -25px;
}

[dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
}

.slick-prev::before {
    content: "←";
}

[dir="rtl"] .slick-prev::before {
    content: "→";
}

.slick-next {
    right: -25px;
}

[dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
}

.slick-next::before {
    content: "→";
}

[dir="rtl"] .slick-next::before {
    content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    bottom: -25px;

    display: block;

    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;

    text-align: center;

    width: 100%;
}

.slick-dots li {

    cursor: pointer;

    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    position: relative;

    width: 20px;
}

.slick-dots li button {
    background: transparent;
    border: 0;

    color: transparent;

    cursor: pointer;

    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    outline: none;
    padding: 5px;

    width: 20px;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover::before,
.slick-dots li button:focus::before {
    opacity: 1;
}

.slick-dots li button::before {
    color: $black;

    content: "•";
    font-family: "slick";
    font-size: 6px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 20px;
    left: 0;
    line-height: 20px;

    opacity: 0.25;

    position: absolute;
    text-align: center;
    top: 0;

    width: 20px;
}

.slick-dots li.slick-active button::before {
    color: $black;
    opacity: 0.75;
}

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $white !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        font-style: normal;
        font-weight: normal;
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    background: transparent;
    border: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 20px;

    &:hover,
    &:focus {
        background: transparent;
        color: transparent;
        outline: none;

        &::before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        color: $slick-arrow-color;
        font-family: $slick-font-family;
        font-size: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1;
        opacity: $slick-opacity-default;
    }
}

.slick-prev {
    left: -25px;

    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }

    &::before {
        content: $slick-prev-character;

        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -25px;

    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }

    &::before {
        content: $slick-next-character;

        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    bottom: -25px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            background: transparent;
            border: 0;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0px;
            outline: none;
            padding: 5px;
            width: 20px;

            &:hover,
            &:focus {
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &::before {
                color: $slick-dot-color;
                content: $slick-dot-character;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                height: 20px;
                left: 0;
                line-height: 20px;
                opacity: $slick-opacity-not-active;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
            }
        }

        &.slick-active button::before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
