.register-page-title {
    @include font-gotham-bold;

    font-size: 21px;
    margin: 20px 0;
    text-transform: uppercase;

    @include breakpoint(medium up) {
        font-size: rem-calc(26);
        margin: 30px auto 2.5rem;
    }

    @include breakpoint(large up) {
        margin-top: 20px;
    }

    &.has-subtitle {
        margin-bottom: 0.5rem;
    }
}

.register-breadcrumb {
    @include font-gotham-book;
    font-size: 0.875rem;
    font-weight: bold;

    margin-top: 40px;
}

.register-form {
    margin-bottom: 4.875rem;

    .dialog-required {
        margin: 0 0 1rem;
        text-align: left;
    }

    .info-box {
        @include font-gotham-book;

        color: map-get($foundation-palette, midgrey);

        a {
            color: map-get($foundation-palette, midgrey);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        p {
            font-size: rem-calc(14);
            margin-bottom: 1.75rem;

            @include breakpoint(medium up) {
                margin-bottom: 3rem;
            }
        }
    }

    .hidden-caption {
        .form-caption {
            display: none;
        }
    }
}
