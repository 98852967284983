.pt_order {
    .d-flex {
        @include flex;
    }

    .position-relative {
        position: relative;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .flex-column {
        flex-direction: column;
    }

    .switcher-container {
        @include flex-align(null, middle);

        @include breakpoint(medium up) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .switcher {
        display: block;
        margin-bottom: 16px;
        min-height: 1.5rem;
        position: relative;
        z-index: 1;

        @include breakpoint(medium up) {
            text-align: end;
        }
    }

    .switcher-input {
        box-sizing: border-box;
        height: 1.25rem;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 1rem;
        z-index: -1;

        &:checked ~ .switcher-label::before {
            border-color: map-get($foundation-palette, primary);
        }

        &:checked ~ .switcher-label::after {
            background-color: map-get($foundation-palette, primary);
            right: 3px;
        }
    }

    .switcher-label {
        display: inline-block;
        line-height: 24px;
        margin: 0;
        padding-right: 45px;
        position: relative;
        vertical-align: top;

        &::before {
            background-color: map-get($foundation-palette, white-default);
            border: 1px solid map-get($foundation-palette, light-gray-my-garage);
            border-radius: 12.5px;
            content: "";
            display: block;
            height: 24px;
            pointer-events: all;
            position: absolute;
            right: 0;
            top: 0;
            transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
            width: 39px;
        }

        &::after {
            background: 50% / 50% 50% no-repeat;
            background-color: map-get($foundation-palette, light-gray-my-garage);
            border-radius: 0.5rem;
            content: "";
            display: block;
            height: 18px;
            position: absolute;
            right: 18px;
            top: 3px;
            transition: transform 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-transform 0.15s ease-in-out;
            width: 18px;
        }
    }

    .order-history-filter-box {
        justify-content: center;
        margin-bottom: 20px;
    }

    .order-history-filter-item {
        align-items: center;
        border: 2px solid map-get($foundation-palette, bright-grey);
        margin: 0;
        min-height: 122px;
        padding: 14px 10px 10px;
        position: relative;
        width: 100%;

        @include breakpoint(medium up) {
            min-height: 125px;
        }

        &::before {
            border: 1px solid map-get($foundation-palette, bright-grey);
            border-radius: 100%;
            content: "";
            height: 19px;
            position: absolute;
            right: 8px;
            top: 8px;
            width: 19px;
            z-index: 1;
        }
    }

    .order-checkbox {
        opacity: 0;
        position: absolute;
        z-index: 1;

        &:checked ~ .order-history-filter-item {
            border: 2px solid map-get($foundation-palette, primary);

            &::before {
                background-color: map-get($foundation-palette, primary);
                border-color: map-get($foundation-palette, primary);
            }

            &::after { 
                border-bottom: 2px solid $white;
                border-right: 2px solid $white;
                content: "";
                display: inline-block;
                height: 10px;
                margin-left: 60%;
                position: absolute;
                right: 14px;
                top: 11px;
                transform: rotate(45deg);
                width: 6px;
                z-index: 1;
            }
        }
    }

    .car-image {
        max-width: 149px;
    }

    .car-name {
        padding: 6px 0 4px;
        text-align: center;
    }

    .car-plate,
    .car-name {
        font-size: rem-calc(12);
        text-align: center;
    }
}
