.brands-landing-wrapper {
    @include breakpoint(small only) {
        h4 {
            font-size: 18px;
        }
    }

    .brands-page-title {
        @extend %login-page-title;
        margin-bottom: 1.75rem;

        @include breakpoint(medium up) {
            margin-bottom: 2.5rem;
        }
    }

    .brands,
    .featured-brands {
        border-bottom: 1px solid map-get($foundation-palette, light-gray);
        margin-bottom: 1.75rem;
        padding-bottom: 1.25rem;

        @include breakpoint(medium up) {
            margin-bottom: 4.1875rem;
            padding-bottom: 3.125rem;
        }
    }

    .brands-slot div {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 1rem 0;

        p {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 1.8rem;

            @include breakpoint(medium up) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }

    .brands {
        padding: 1rem 0;
    }

    .brands-item {
        @include breakpoint(small only) {
            &:nth-child(odd) {
                padding-right: 0.3125rem;
            }

            &:nth-child(even) {
                padding-left: 0.3125rem;
            }
        }

        a {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 3.75rem;
            justify-content: center;
            margin-bottom: 0.625rem;
            padding: 0.625rem;

            @include breakpoint(medium up) {
                height: 8rem;
                margin-bottom: 1.3rem;
                padding: 1.3rem;
            }
        }

        &.featured-brands-item {
            a {
                border: 6px solid map-get($foundation-palette, light-gray);
                height: 7.1875rem;

                @include breakpoint(medium up) {
                    border-width: 10px;
                    height: 15rem;
                }
            }
        }

        .button {
            display: none;
            margin: 0.625rem 0 0;
            min-height: 1.5rem;
            padding: 0.3125rem;

            @include breakpoint(medium up) {
                font-size: 0.8125rem;
                margin-top: 1.3rem;
                padding: 0.9375rem 0.625rem;
                padding-right: 0.625rem;
            }
        }

        &:hover {
            .button {
                display: block;
            }
        }

        img {
            max-height: 4.0625rem;
        }
    }
}

.brand-banner {
    background-size: cover;
    color: map-get($foundation-palette, white-default);
    margin-bottom: 1.75rem;
    padding: 4rem 0 3rem;

    @include breakpoint(medium up) {
        margin-bottom: 3rem;
        padding: 8.75rem 0 8.25rem;

        p {
            font-size: 18px;
            line-height: 1.5rem;
        }
    }

    h1,
    h2 {
        font-size: 42px;
        margin-bottom: 1.5rem;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 67px;
            margin-bottom: 1rem;
        }
    }
}

.brand-banner-wrapper {
    margin-bottom: 2.75rem;
    min-height: 3.3125rem;
    position: relative;

    @include breakpoint(medium up) {
        margin-bottom: 5rem;
        min-height: 3.75rem;
    }

    .brand-banner {
        background-position: 79% 20%;
        margin-bottom: 0;
        padding-bottom: 5.625rem;

        @include breakpoint(medium up) {
            padding: 8.125rem 0 8.25rem;
        }
    }
}

.brand-menu-wrapper {
    background: rgba(map-get($foundation-palette, secondary), 0.6);
    bottom: 0;
    height: 3.3125rem;
    left: 0;
    position: absolute;
    width: 100%;

    @include breakpoint(medium up) {
        height: 3.75rem;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        li {
            flex-grow: 1;
            line-height: 3.3125rem;
            max-width: 33%;

            @include breakpoint(medium up) {
                line-height: 3.75rem;
            }

            a {
                @include font-gotham-bold;
                align-items: center;
                color: map-get($foundation-palette, white-default);

                display: flex;
                font-size: 12px;
                height: 3.3125rem;
                justify-content: center;
                line-height: 1rem;
                opacity: 0.6;
                padding: 5px;
                text-align: center;

                @include breakpoint(medium up) {
                    font-size: 16px;
                    height: 3.75rem;
                }
            }
        }

        .selected,
        a:hover {
            opacity: 1;
            position: relative;

            &::after {
                background: map-get($foundation-palette, primary);
                bottom: 0;
                content: "";
                display: block;
                height: 3px;
                left: 0;
                position: absolute;
                right: 0;
                width: 100%;

                @include breakpoint(medium up) {
                    height: 6px;
                }
            }
        }
    }
}

.brand-page-title {
    border-left: 3px solid map-get($foundation-palette, primary);
    font-size: 24px;
    line-height: 1.125rem;
    margin-bottom: 2.75rem;
    padding-left: 1.125rem;
    text-transform: uppercase;
}

.about-brand-section {
    margin-bottom: 2rem;

    iframe {
        max-width: 100%;
        width: 100%;

        @include breakpoint(small only) {
            height: auto;
        }
    }

    .button,
    p {
        margin-bottom: 1.5rem;
    }

    .about-brand-top {
        @include font-gotham-bold;
    }
}

.brand-page-products {
    .grid-tile {
        @extend .large-3; /* stylelint-disable-line */
    }

    .recommended-bagde,
    .product-compare {
        display: none;
    }

}
