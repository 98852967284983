.cb-bordered-content {
    border: 7px solid map-get($foundation-palette, light-gray);
    padding: 15px 0;

    @include breakpoint(medium up) {
        padding: 30px 0;
    }
}

// Page Header Content Block
.cb-page-header {
    background-position: 50% 50%;
    background-size: cover;
    color: map-get($foundation-palette, white-default);
    margin-bottom: 40px;
    padding: 100px 0 120px;

    @include breakpoint(medium up) {
        margin-bottom: 100px;
        padding: 115px 0;
    }

    h1 {
        @include font-gotham-bold;

        font-size: 42px;
        line-height: 1em;
        margin-bottom: 16px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 66px;
        }
    }

    p {
        @include font-gotham-medium;

        font-size: 14px;
        line-height: rem-calc(21);

        @include breakpoint(medium up) {
            font-size: 18px;
        }
    }

    .cb-cta {
        margin-top: 10px;
    }
}


// CTA Style

.cb-cta {
    @include font-gotham-bold;
    color: map-get($foundation-palette, primary-accessible);
    display: inline-block;
    font-size: 13px;
    margin-right: 10px;

    margin-top: 10px;

    @include breakpoint(medium up) {
        font-size: 14px;
    }

    &.button-hollow-white {
        border: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        padding: 7px 30px;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out;

        @include breakpoint(medium up) {
            padding: 9px 30px;
        }

        &:hover,
        &:focus {
            background: map-get($foundation-palette, primary);
            border: 3px solid map-get($foundation-palette, primary);
            color: map-get($foundation-palette, secondary);
        }
    }

    &.button-hollow-black {
        border: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, secondary);
        margin-bottom: 5px;
        padding: 7px 30px;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        transition-property: background, color;

        @include breakpoint(medium up) {
            padding: 9px 30px;
        }

        &:hover,
        &:focus {
            background: $button-accessible-hover;
            border: 3px solid map-get($foundation-palette, primary);
            color: map-get($foundation-palette, secondary);
        }
    }

    &.button-full-white {
        background: map-get($foundation-palette, primary);
        border: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, secondary);
        padding: 7px 30px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            padding: 9px 30px;
        }

        &.financingLink {
            margin: 0 0 10px;
        }

        &:hover,
        &:focus {
            background: $button-hover;
            border: 3px solid $button-hover;
        }
    }

    &.link {
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(13);

        @include breakpoint(medium up) {
            font-size: rem-calc(14);
        }

        &:hover {
            text-decoration: underline;
        }

        .arrow {
            color: map-get($foundation-palette, primary);
        }
    }
}

.cb-cta-secondary {
    margin-right: 0;
}

// Section Header Content Block
.cb-section-header {
    margin: 0 auto 25px;

    h2 {
        @include font-gotham-bold;
        border-left: 3px solid map-get($foundation-palette, primary);

        font-size: 18px;
        line-height: 1;
        margin-bottom: 16px;
        padding-left: 20px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 26px;
            max-width: 900px;
        }
    }

    p,
    li {
        @include font-gotham-book;

        font-size: rem-calc(16);
        line-height: rem-calc(21);

        @include breakpoint(medium up) {
            font-size: 18px;
        }
    }

    li {
        margin-bottom: 1rem;
    }
}

.cb-column {
    margin: 5px 0 0;

    .cb-column-child-copy {
        padding: 20px 0 0;
    }

    .financing-blocks-title {
        color: #ff6900;
        font-size: 3rem;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        max-width: 82.5rem;
        padding-left: 40px;

        @include breakpoint(small only) {
            padding-left: 15px;
        }
    }
}

.cb-column-child {
    margin: 0 0 40px;

    h2 {
        @include font-gotham-bold;

        font-size: 18px;
        line-height: 1em;
        margin-bottom: 16px;

        @include breakpoint(medium up) {
            font-size: 22px;
        }
    }

    p {
        @include font-gotham-book;

        font-size: 13px;
        line-height: rem-calc(21);
        margin: 0 0 13px;

        @include breakpoint(medium up) {
            font-size: 14px;
        }
    }

    img {
        display: block;
        width: 100%;
    }

    .cb-cta {
        margin-top: 0;
    }

    .cb-column-child-copy {

        @include breakpoint(large up) {
            padding: 20px 10px 0;
        }
    }
}

// Section Image Alternate Column
.cb-image-alternate-column {
    margin: 0 0 50px;

    .cb-cta {
        margin-top: 0;
        padding: 7px 30px;

        @include breakpoint(medium up) {
            margin-top: 5px;
        }
    }

    img {
        display: block;
        margin-bottom: 16px;
        width: 100%;

        @include breakpoint(medium up) {
            margin: 0;
        }
    }

    h2 {
        @include font-gotham-bold;

        font-size: 18px;
        line-height: 1em;
        margin-bottom: 16px;

        @include breakpoint(medium up) {
            font-size: 22px;
        }
    }

    p {
        @include font-gotham-book;

        font-size: 14px;
        line-height: rem-calc(21);

        @include breakpoint(medium up) {
            font-size: 14px;
        }
    }
}

// Section One Column CTA
.cb-1-column-cta {
    background-position: 50% 100%;
    background-size: cover;
    color: map-get($foundation-palette, white-default);
    margin: 0 auto 40px;
    min-height: 302px;
    padding: 60px 0;

    @include breakpoint(medium up) {
        padding: 75px 0 50px;
    }

    > .row {
        width: 100%;
    }

    h2 {
        @include font-gotham-bold;

        font-size: 18px;
        line-height: 1em;
        margin-bottom: 34px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 26px;
        }
    }

    p {
        @include font-gotham-book;

        font-size: 16px;
        line-height: rem-calc(21);

        @include breakpoint(medium up) {
            font-size: 18px;
        }
    }

    .cb-cta {
        @include breakpoint(small only) {
            margin: 10px;
        }
    }
}

// Section Testimonial
.cb-testimonial {
    background: map-get($foundation-palette, light-gray);
    color: map-get($foundation-palette, secondary);
    margin: 0 auto;
    padding: 65px 0;

    @include breakpoint(medium up) {
        margin: 0 auto;
        padding: 80px 0;
    }

    h2 {
        @include font-gotham-bold;

        font-size: 22px;
        line-height: 1em;
        margin-bottom: 14px;
    }

    p {
        @include font-gotham-book;

        font-size: 16px;
        line-height: rem-calc(21);

        @include breakpoint(medium up) {
            font-size: 18px;
        }
    }

    .cb-testimonial-avatar {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        img {
            display: inline-block;
            margin-right: 15px;
            max-width: 48px;
        }

        p {
            color: #999;
            font-size: 12px;
            margin: 0;
        }
    }
}

// 3 Column Feature
.cb-3-column-feature {
    margin-bottom: 40px;

    .cb-section-header {
        margin: 0 auto 20px;
    }

    .cb-column-child {
        margin-bottom: 20px;
    }

    .cb-column-child-copy {
        padding: 0;

        h2 {
            @include font-gotham-bold;
            font-size: 16px;

            text-transform: none;

            @include breakpoint(medium up) {
                @include font-gotham-medium;

                font-size: 18px;
            }
        }

        p {
            @include breakpoint(medium up) {
                margin-bottom: 8px;
                padding-right: 20px;
            }
        }

        .cb-cta {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}

// Section Featured Products Cards
.cb-featured-products-cards {
    margin: 0 0 20px;

    @include breakpoint(large up) {
        margin: 0 0 60px;
    }

    .cb-cta {
        margin-top: 30px;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 20px;

        @include breakpoint(medium up) {
            font-size: 22px;
        }
    }

    p {
        font-size: 14px;
        margin-bottom: 20px;

        @include breakpoint(medium up) {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }

    .product-tile {
        margin: 20px 0 0;

        @include breakpoint(small only) {
            height: initial !important;
        }

        @include breakpoint(large up) {
            margin: 0;
        }
    }

}


// Section Product Details Cards
.cb-product-details-card {
    margin: 0 0 30px;

    @include breakpoint(large up) {
        margin: 0 0 50px;
    }

    img.prodImg {
        display: block;
        width: 100%;

        @include breakpoint(small only) {
            margin-bottom: 15px;
        }
    }

    p {
        font-size: 13px;

        @include breakpoint(large up) {
            font-size: 14px;
        }
    }

    .cb-product-details-card-headings {
        margin-bottom: 14px;

        @include breakpoint(large up) {
            margin-bottom: 24px;
        }

        p {
            font-size: 14px;
        }

        h4 {
            font-size: 13px;
            text-transform: none;

            @include breakpoint(large up) {
                font-size: 16px;
            }
        }

        h2 {
            font-size: 16px;
            text-transform: none;

            @include breakpoint(large up) {
                font-size: 22px;
            }
        }

        h5 {
            font-size: 12px;
            text-transform: none;
        }
    }

    table {
        @include font-gotham-book;
        border: 0;

        font-size: 14px;
        margin-bottom: 0;

        thead {
            @include font-gotham-bold;
            background: none;

            border: 0;
            padding: 5px 0 20px;

            th {
                padding: 5px 0 20px;
            }
        }

        th,
        td {
            padding-bottom: 0;
            padding-left: 0;
            padding-top: 0;
        }

        tbody {
            border: 0;
        }

        tr {
            background: none !important;
        }
    }

    .cb-product-details-card-cta-wrapper {
        min-height: 45px;
        position: relative;

        @include breakpoint(medium down) {
            margin-top: 20px;
        }

        .cb-product-details-card-ctas {

            @include breakpoint(large up) {
                bottom: 0;
                position: absolute;
                right: 0;
            }

            .cb-cta {
                @include breakpoint(large up) {
                    margin-top: 0;
                }
            }
        }
    }
}

// Section Secondary Navigation
.cb-secondary-page-navigation {

    margin-bottom: 50px;

    .cb-secondary-page-nav-child {
        border: 10px solid map-get($foundation-palette, light-gray);
        padding: 8px 40px;
        text-align: center;

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }

        h4 {
            @include font-gotham-bold;

            font-size: 16px;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: 20px;
            }
        }

        .cb-secondary-page-nav-icon {
            height: 100px;
            width: auto;
        }

        p {
            @include font-gotham-book;
            color: #999;

            font-size: 16px;
        }
    }
}


// Section Column Tabs
.cb-column-tabs {
    .column-image-wrapper {
        @include breakpoint(small only) {
            margin-bottom: 20px;
        }
    }

    .tabs {
        border: 0;
        width: 100%;

        li a:hover,
        li.is-active {
            position: relative;

            &::after {
                background: map-get($foundation-palette, primary);
                bottom: -5px;
                content: "";
                height: 5px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .tabs-title > a:focus,
        .tabs-title > a[aria-selected="true"] {
            background: none;
            color: map-get($foundation-palette, secondary);
        }

        li {
            @include breakpoint(small only) {
                width: 33.2%;
            }

            a {
                @include font-gotham-bold;
                color: #999;

                font-size: 12px;
                padding: 15px;
                text-align: center;

                @include breakpoint(medium up) {
                    font-size: 16px;
                    padding: 15px 60px;
                }

                &:hover {
                    color: map-get($foundation-palette, secondary);
                }
            }
        }
    }

    .tabs-content {
        border: 0;
        border-top: 5px solid map-get($foundation-palette, light-gray);
        margin-bottom: 60px;
        padding: 40px 0 0;

        .tabs-panel {
            padding: 0;

            h2 {
                @include font-gotham-bold;

                font-size: 18px;

                @include breakpoint(medium up) {
                    font-size: 22px;
                }
            }

            p {
                @include font-gotham-book;

                font-size: 14px;
            }
        }
    }
}

// Section Column Details
.cb-1-column-details {
    margin-bottom: 50px;

    h4 {
        font-size: 18px;

        @include breakpoint(medium up) {
            font-size: 22px;
        }
    }

    img {
        display: block;
    }

    p {
        @include font-gotham-book;

        font-size: 14px;
    }
}

// Accordion
.cb-accordion {
    margin: rem-calc(21 0);

    .toggle-item {
        summary {
            font-size: rem-calc(18);
        }
    
        .item-content {
            @include font-gotham-book;
            position: relative;

            * {
                font-size: rem-calc(16);
            }
        }
    }
}
