.slick-slider {
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    user-select: none;
}

.slick-slider-container {
    position: relative;
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    .dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-list,
.slick-arrow {
    &:focus {
        outline: none;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    display: block;
    left: 0;
    position: relative;
    top: 0;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

    img.slick-loading {
        visibility: hidden;
    }
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}

.slick-arrow.slick-hidden {
    display: none;
}
