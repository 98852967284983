.page-designer-kaltire {

    @include breakpoint(large up) {
        .no-padding-left-large {
            padding-left: 0;
        }

        .no-padding-right-large {
            padding-right: 0;
        }
    }

    .black {
        color: map-get($foundation-palette, secondary) !important;
    }

    .white {
        color: map-get($foundation-palette, white-default) !important;
    }

    .orange {
        color: map-get($foundation-palette, primary) !important;
    }

    .darker-gray {
        color: $darker-gray !important;
    }

    .has-shaddow {
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
    }

    .background-black {
        background-color: map-get($foundation-palette, secondary) !important;
    }

    .background-white {
        background-color: map-get($foundation-palette, white-default) !important;
    }

    .background-orange {
        background-color: map-get($foundation-palette, primary) !important;
    }

    .background-darker-gray {
        background-color: $darker-gray !important;
    }

    h5 a {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(18);
    }

    .component-figure {
        color: map-get($foundation-palette, white-default);
        height: 100%;
        margin: 0;
        position: relative;
        width: 100%;

        p {
            margin: 0;
        }

        .banner-title {
            @include font-gotham-bold;

            font-size: rem-calc(60);
            line-height: rem-calc(60);
            text-transform: uppercase;

            @include breakpoint(medium down) {
                font-size: rem-calc(40);
                line-height: rem-calc(40);
                padding-bottom: 15px;
            }
        }

        .banner-subtitle {
            @include font-gotham-medium;

            font-size: rem-calc(13);
            line-height: rem-calc(16);
            padding-left: rem-calc(4);

            @include breakpoint(medium down) {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                max-width: 100%;
                padding-left: 0;
            }
        }

        figcaption {
            align-items: center;
            display: flex;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;

            .row {
                width: 100%;
            }
        }
    }

    .component-image {
        height: 100%;
        object-fit: cover;
        object-position: var(--focal-point-x) var(--focal-point-y);
        // width: 100%;
    }

    .video-article {
        height: 100%;
        position: absolute;
        width: 100%;

        i {
            align-items: center;
            display: flex;
            font-size: 40px;
            height: 100%;
            justify-content: center;
        }
    }

    .article-main-image,
    .featured-article,
    .featured-left-article {
        .video-article i {
            @include breakpoint(medium up) {
                font-size: 60px;
            }
        }
    }

    .feature-article-image {
        max-height: rem-calc(300);
        width: 100%;
    }

    .experience-layouts-exploretopic,
    .experience-assets-exploretopics {
        background-color: map-get($foundation-palette, light-gray);
        padding: rem-calc(80) 0;

        @include breakpoint(small down) {
            padding: rem-calc(40) 0;
        }
    }

    .topic-link-title {
        @include font-gotham-bold;

        font-size: rem-calc(35px);
        line-height: rem-calc(35px);
        padding-bottom: rem-calc(15);
        text-transform: uppercase;

        @include breakpoint(small down) {
            font-size: rem-calc(20);
            line-height: rem-calc(20);
            padding-bottom: rem-calc(5);
        }
    }

    a.topic-link {
        color: map-get($foundation-palette, secondary);
        display: block;

        &:hover {
            color: map-get($foundation-palette, primary);
        }

        .topic-link-image {
            height: 40px;
            width: 40px;
        }

        i {
            font-size: 40px;
        }

        p {
            @include font-gotham-bold;

            font-size: rem-calc(12);
            padding-top: rem-calc(5);
        }
    }

    .popular-reads {
        margin: 50px 0 20px;
    }

    .articles-infinite {
        margin: 20px 0;

        @include breakpoint(medium up) {
            margin: 50px 0 20px;
        }
    }

    .popular-reads-top,
    .related-reads-top {
        margin-bottom: 20px;
    }

    .popular-reads-title {
        border-left: 3px solid map-get($foundation-palette, primary);
        font-size: rem-calc(16);
        font-weight: bold;
        line-height: rem-calc(14);
        padding-left: 8px;
        
        text-transform: uppercase;
    }

    .popular-reads-link {
        font-size: 12px;
        font-weight: bold;
        text-align: right;
    }

    .article-container {
        border: 1px solid $lighter-gray;
        margin-bottom: 20px;
    }

    .article-info {
        padding: 15px 15px 6px;
    }

    .featured-tag {
        display: none;
    }

    .article-title {
        font-size: rem-calc(16);
        font-weight: bold;
        height: 38px;
        line-height: rem-calc(20);
        overflow: hidden;
        text-transform: uppercase;
    }

    .article-link.text-link {
        font-size: rem-calc(11);
        font-weight: bold;

        &.button {
            margin-top: 20px;
        }

        &::after {
            content: " >";
        }

        &.text-link-custom::after {
            content: "";
        }
    }

    .article-container,
    .featured-article {
        position: relative;
    }

    .article-link-full {
        cursor: pointer;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .featured-article,
    .featured-left-article {
        color: $white;
        margin-bottom: 20px;
        overflow: hidden;

        .article-info {
            background-color: $darker-gray;
            height: 100%;
            padding: 50px 50px 0;

            @include breakpoint(medium down) {
                padding: 20px 40px;
            }
        }

        .featured-tag {
            background-color: $dark-gray-feature;
            display: inline;
            font-size: rem-calc(9);
            padding: 4px 7px;
            text-transform: uppercase;
        }

        .article-title {
            font-size: rem-calc(31);
            font-weight: normal;
            height: auto;
            line-height: rem-calc(32);
            padding: 16px 0 10px;

            @include breakpoint(medium down) {
                font-size: rem-calc(23);
                line-height: rem-calc(28);
            }
        }

        .article-description {
            display: -webkit-box; /* stylelint-disable-line */
            font-size: rem-calc(13);
            -webkit-line-clamp: 2;
            max-height: 36px;
            overflow: hidden;

            @include breakpoint(medium down) {
                -webkit-line-clamp: 3;
                max-height: 55px;
            }
        }

        .article-link.button {
            font-size: rem-calc(12);
            margin: 20px 0 0;
            padding: 12px 24px;
        }
    }

    .articles-layout {
        .feature-article-image {
            max-height: rem-calc(256);
        }
    }

    .featured-left-article {
        position: relative;

        .article-info {
            padding: 22px 30px;
        }

        .article-title {
            padding: 26px 0 10px;
        }

        .article-description {
            @include font-gotham-book;

            margin: 5px 0 20px;
        }

        a {
            display: block;
            margin-bottom: 10px;
        }
    }

    .topic-breadcrumb {
        background-color: $darker-gray;
        color: $white;

        .article-breadcrumbs {
            margin: 15px 0 5px;

            a,
            p {
                color: $white;
            }
        }

        .topic-title {
            border-left: 3px solid map-get($foundation-palette, primary);
            text-transform: uppercase;

            &-text {
                font-size: rem-calc(16);
                line-height: rem-calc(16);
                margin: 0 0 25px 10px;
            }
        }
    }

    .article-breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 20px;

        a,
        p {
            @include font-gotham-book;

            color: $black;
            display: block;
            font-size: rem-calc(11);
            line-height: rem-calc(11);
        }
    }

    .breadcrumb-level-1,
    .breadcrumb-level-2,
    .breadcrumb-level-3 {
        padding-bottom: 10px;
    }

    .breadcrumb-level-1,
    .breadcrumb-level-2 {
        a,
        p {
            &::after {
                content: "/";
                margin: 0 6px;
            }
        }
    }

    .breadcrumb-level-3 {
        p {
            @include font-gotham-bold;

            margin: 0;
        }
    }

    .article-contents,
    .content-markup {
        margin-bottom: 50px;
        margin-top: 40px;

        @include breakpoint(medium down) {
            margin-top: 0;
        }

        p {
            font-size: 0.875rem;
        }

        p,
        ul,
        ol,
        h4 {
            margin: 0;
            padding: 0;
        }

        p,
        ul,
        ol {
            font-size: rem-calc(14);
        }

        p,
        ul,
        ol {
            margin-bottom: 25px;
        }

        h1 {
            font-size: 1.5625rem;
            line-height: 1.5625rem;
        }

        h2 {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }

        h3 {
            color: map-get($foundation-palette, secondary);
            font-size: 1rem;
            line-height: 1rem;
        }

        h4 {
            color: map-get($foundation-palette, primary);
            font-size: 1rem;
            line-height: 1rem;
            margin-bottom: 10px;
        }

        .article-page-title {
            @include font-gotham-bold;

            font-size: rem-calc(25);
            line-height: rem-calc(25);
            margin-bottom: 30px;
            text-align: center;

            @include breakpoint(medium down) {
                margin-bottom: 20px;
                text-align: left;
                text-transform: uppercase;
            }
        }

        .article-main-image {
            margin-bottom: 30px;
        }

        .article-page-content {
            margin-bottom: 75px;
        }

        ol {
            @include font-gotham-book;

            list-style: decimal;
            padding-left: 30px;

            @include breakpoint(medium down) {
                padding-left: 20px;
            }
        }

        ul {
            @include font-gotham-book;

            list-style: disc;
            padding-left: 30px;

            @include breakpoint(medium down) {
                padding-left: 20px;
            }
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .product-image-badge {
            img {
                display: inline-block;
            }
        }
    }

    .content-markup {
        margin: 0;
    }

    .bottom-border-2p-gray {
        border-bottom: 2px solid $light-gray-over;
    }

    .video-article-link {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    .article-video {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .thumbs-up-container {
        margin: 20px 0 50px;
        text-align: center;
    }

    .thumbs-up-title p {
        @include font-gotham-bold;

        font-size: rem-calc(20);
        margin: 0 0 4px;
        text-transform: uppercase;
    }

    .thumbs-up-subtitle p {
        font-size: rem-calc(14);
        margin: 0;
    }

    .thumbs-up-triggers {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        i {
            color: $thumbs-gray;
            display: block;
            font-size: rem-calc(42);
        }

        i.active {
            color: map-get($foundation-palette, primary);
        }
    }

    .thumbs-up-trigger,
    .thumbs-down-trigger {
        cursor: pointer;
    }

    .thumbs-up-trigger {
        margin-right: 10px;
    }

    .thumbs-down-trigger {
        margin-left: 10px;
        padding-top: 12px;
    }

    .article-external-section {
        margin-bottom: 75px;
        min-height: 320px;
        text-align: center;

        .article-external-content {
            justify-content: center;

            @include breakpoint(small down) {
                padding: 30px;
            }
        }

        .article-external-title {
            @include font-gotham-bold;

            font-size: rem-calc(20);
            margin: 10px 0;
            text-transform: uppercase;
        }

        .article-external-subtitle {
            font-size: rem-calc(14);
            margin-bottom: 10px;
        }

        .button {
            margin-top: 20px;
        }
    }

    .article-filters {
        padding: 50px 10px 0;

        @include breakpoint(medium up) {
            padding: 50px 30px 0 10px;
        }

        p {
            border-bottom: 1px solid $medium-gray;
            font-size: rem-calc(12px);
            margin: 0 0 30px;
            padding-bottom: 30px;
        }

        a {
            color: $black;
            font-size: rem-calc(15);
        }

        a:hover {
            color: map-get($foundation-palette, primary);
        }

        .active-link {
            color: map-get($foundation-palette, primary);
        }

        .subcategory-region {
            margin: 0 0 10px 10px;

            a {
                @include font-gotham-book;

                font-size: rem-calc(14);
            }
        }
    }

    .article-count {
        display: none;

        @include breakpoint(medium up) {
            display: block;
        }
    }

    p.article-count-mobile {
        border-bottom: 0;
        display: block;
        margin: 0;
        padding: 10px 0 0;

        @include breakpoint(medium up) {
            display: none;
        }
    }

    a.refinement-filter-toggle {
        display: block;
        font-size: rem-calc(12);
        margin: 0;
        position: relative;
        width: 100%;

        @include breakpoint(medium up) {
            display: none;
        }

        &.active {
            background-color: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, white-default);

            ::after {
                content: "\e807";
                font-family: "fontello";
                font-size: 17px;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .topic-filters {
        border-bottom: 1px solid $medium-gray;
        border-left: 1px solid $medium-gray;
        border-right: 1px solid $medium-gray;
        display: none;
        padding: 15px;

        @include breakpoint(medium up) {
            border: 0;
            display: block;
            padding: 0;
        }
    }

    .table-grid-component {
        text-align: center;

        .column-content {
            overflow: hidden;
            padding: 10px;

            @include breakpoint(medium down) {
                padding: 4px;
            }
        }

        p {
            margin: 0;
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        p,
        ul,
        ol {
            font-size: rem-calc(14);

            @include breakpoint(medium down) {
                font-size: rem-calc(12);
            }
        }
    }

    .flex-center {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
    }

    .content-optional-title {
        @include font-gotham-bold;

        font-size: rem-calc(25);
        line-height: rem-calc(25);
        margin-bottom: 24px;

        @include breakpoint(medium down) {
            margin-bottom: 16px;
            text-transform: uppercase;
        }
    }

    .experience-layouts-productcardright,
    .experience-layouts-productcardleft,
    .experience-layouts-productcardscentered {
        margin: rem-calc(30 0);
    }

    .powerreviews-container {
        min-height: 33px;
    }
}

.video-article-popup {
    .ui-dialog-titlebar-close {
        right: 16px;
        top: -28px;
    
        @include breakpoint(medium up) {
            right: 16px;
            top: -28px;
        }
    }

    #dialog-container {
        padding: 0;
    }
}

#resource-centre {
    @include breakpoint(medium up) {
        .experience-assets-headlinebannerfullwidth {
            height: 371px;
            text-align: center;

            .title-row {
                margin: 0 0 0 345px;
                text-align: justify;

                .banner-title {
                    font-size: 65px;
                    margin-bottom: 18px;
                }

                .banner-subtitle {
                    font-size: 18px;
                    padding: 0;
                }

                .subtitle-row {
                    margin: 0;

                    .subtitle-column {
                        padding: 0;
                    }
                }
            }
        }

        .experience-assets-exploretopics {
            height: 408px;
            padding: 120px 0;

            .topic-link-title {
                font-size: 42px;
                margin-bottom: 0;
                padding-bottom: 12px;
            }

            .topic-column {
                display: block;
                margin: 0 24px;
                padding: 0;

                .topic-link {
                    display: flex;
                    flex-direction: column;

                    i {
                        font-size: 56px;
                        margin: 0;
                    }

                    p {
                        font-size: 18px;
                        padding-top: 22px;
                    }
                }
            }
        }

        .experience-layouts-popularreads {
            .popular-reads {
                margin: 100px 0 70px;
            }

            .popular-reads-title {
                font-size: 26px;
                height: 23px;
                line-height: 21px;
            }

            .featured-article {
                margin-bottom: 30px;
            }

            .article-container {
                margin-bottom: 30px;
            }
        }
    }
}
