// Product tiles from results/grid page
.search-result-content,
.page-designer-kaltire {
    .grid-tile {
        margin-bottom: 20px;

        @include breakpoint(medium up) {
            margin-bottom: 45px;
        }

        &.new-row:first-of-type {
            flex: 1;
        }
    }

    .product-tile-content {
        @include clearfix;
        @include flex;
        flex: 1 0 auto;
        flex-direction: column;
        padding: 0 15px 15px;
        position: relative;
    }

    .product-tile {
        @include flex;
        @include flex-align(justify);
        border: 3px solid map-get($foundation-palette, light-gray);
        flex-direction: column;
        height: 100%;
        position: relative;

        .product-link-above {
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        .link-above-product {
            background-color: map-get($foundation-palette, light-gray);
            border: 3px solid $white;
            position: relative;
            z-index: 3;

            &:hover {
                background-color: $button-accessible-hover;
                border: 3px solid map-get($foundation-palette, primary);
                color: map-get($foundation-palette, secondary);
            }
        }

        &:hover:not(.product-tile-active):not(.product-tile--cart-button-hover) {
            border-color: $darker-gray-over;

            .recommended-bagde {
                background: $light-gray-over;
            }

            .product-compare label {
                text-decoration: underline;
            }
        }

        &:not(.isMasterProduct) {
            .product-pricing {
                // custom breakpoint because also for 1025 we have issues with price size
                @media only screen and (max-width: 1279px) {
                    font-size: rem-calc(25);
                }
            }
        }

        .recommended-bagde {
            @include font-gotham-bold;
            background: map-get($foundation-palette, light-gray);
            color: map-get($foundation-palette, primary);
            padding: 3px 0;
            text-align: center;

            width: 100%;
        }

        .promo-badge {
            @include font-gotham-bold;

            background: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(12);
            left: 0;
            margin: 0 7px;
            padding: 5px;
            position: absolute;
            top: 7px;
        }

        .product-badge {
            color: map-get($foundation-palette, secondary);
            left: 0;
            margin: 0 6px;
            position: absolute;
            z-index: 1;
        }

        .product-name {
            @include font-gotham-bold;
            color: #000;

            font-size: rem-calc(22);
            line-height: 1;
            margin: 0 0 15px;
            word-wrap: break-word;

            .brand-name {
                font-size: rem-calc(13);
                margin: 0 0 5px;
            }

            a {
                color: #000;
            }
        }

        .v-view-on-vehicle {
            min-height: 65px;

            .button {
                margin-bottom: 5px;
            }
        }

        .product-price {
            @include clearfix;

            color: map-get($foundation-palette, secondary);

            .rebate ~ .price-sales {
                color: map-get($foundation-palette, primary);
            }
        }

        .price-sales {
            float: left;
            margin: 0 5px 0 0;
        }

        .rebate {
            float: left;
            margin: 10px 0 0;
        }

        .add-all-to-cart {
            height: 60px;
            margin-bottom: 0;
        }
    }

    .marketing-info-wrapper {
        align-items: center;
        background: map-get($foundation-palette, light-gray);
        border: 3px solid map-get($foundation-palette, border-grey);
        display: flex;
        height: 100%;
        padding: 30px 10px;

        .marketing-title {
            font-size: rem-calc(18);
            margin-bottom: 20px;
            text-align: center;
        }

        .marketing-info-section {
            padding: 15px 0;
            position: relative;
            text-align: center;

            h4 {
                font-size: rem-calc(16);
                text-align: center;
            }

            p {
                color: $black;
                font-size: rem-calc(13);
            }

            i {
                color: map-get($foundation-palette, primary);
                display: block;
                font-size: rem-calc(40);

                &::before {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .product-image {
        margin: 15px 0;

        .thumb-link {
            display: block;
            margin: 0 auto;
            max-height: 215px;
            max-width: 215px;
            text-align: center;

            @include breakpoint(medium only) {
                max-height: 195px;
                max-width: 195px;
            }
        }
    }

    .tested-bykal-badge {
        min-height: 20px;
    }

    .product-name {
        .brand-name {
            display: block;
        }
    }

    .product-attributes {
        .attribute {
            font-size: rem-calc(14);
            line-height: 1;
            margin: 0 0 8px;

            @include breakpoint(medium only) {
                font-size: rem-calc(13);
            }
        }

        .name {
            @include font-gotham-bold;
        }

        .value {
            text-transform: capitalize;
        }

        .category {
            color: map-get($foundation-palette, midgrey);
            font-size: rem-calc(13);
        }
    }

    .categories-box {
        line-height: 1;
        margin: 10px 0 15px;
    }

    .not-available-msg {
        color: map-get($foundation-palette, error);
        font-weight: bold;
    }

    .availability-msg {
        @include font-gotham-book;

        font-size: rem-calc(14);
        line-height: 1;
        padding: 0 0 10px;

        @include breakpoint(medium only) {
            font-size: rem-calc(13);
        }

        .availability-label {
            @include font-gotham-bold;
        }
    }

    .product-size {
        padding-right: 0.3125rem;

        @include breakpoint(large up) {
            padding-right: 0.625rem;
        }

        .product-attributes {
            .attribute {
                font-size: rem-calc(12);
            }
        }
    }

    .product-pricing {
        padding-left: 0.3125rem;

        @include breakpoint(large up) {
            padding-left: 0.625rem;
        }
    }

    .isMasterProduct {
        .product-pricing {
            padding-left: 0;
            width: 100%;
        }

        .master-product-price,
        .rebate {
            display: inline-block;
        }

        .rebate {
            float: none;
            line-height: 1.2;
        }
    }

    .view-on-vehicle {
        margin: 10px 0 0;
        width: 100%;
    }

    // Product set
    .size-price-qty-box {
        margin: auto 0 0;

        .product-size,
        .product-pricing {
            .box-spacing:first-of-type {
                margin: 0 0 35px;
            }
        }

        .product-pricing {
            color: map-get($foundation-palette, primary);
        }
    }

    .product-tile__actions {
        @include flex;
        position: relative;
        z-index: 2;
    }

    .product-tile__add-to-cart {
        flex: 1 1 100%;
        height: 60px;

        .add-to-cart {
            height: 60px;
            margin-bottom: 0;
            width: 100%;
        }
    }
}

.compare-items-container {
    background: map-get($foundation-palette, white-default);
    border-top: 1px solid $light-gray-over;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.compare-items {
    margin: 0 auto;
    max-width: 1240px;

    .compare-item {
        height: 160px;
        margin: 30px 50px 30px 0;
        position: relative;
        width: 150px;

        img {
            display: block;
            height: 145px;
            margin: 0 auto;
            padding: 10px 0 15px;
            width: 120px;
        }

        .compare-item-name {
            text-align: center;
        }

        .compare-item-number {
            align-items: center;
            background-color: map-get($foundation-palette, light-gray);
            color: map-get($foundation-palette, midgrey);
            display: flex;
            font-size: rem-calc(24);
            height: 110px;
            justify-content: center;
            margin: 0 auto;
            width: 110px;
        }
    }

    .compare-item-remove {
        position: absolute;
        right: 0;
        top: 0;
    }

    .compare-title {
        font-size: rem-calc(16);
        margin: 0;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .button {
        margin: 0 0 0 25px;
        padding: 15px 28px;
    }

    .compare-action {
        align-items: center;
        display: flex;
        padding-top: 84px;
    }
}

.italic-text {
    font-style: italic;
}

.finance-info {
    border: 2px solid #ff6900;
    display: inline-block;
    font-size: rem-calc(15px);
    margin: 25px 0;
    padding: 0.5em;

    a {
        text-decoration: underline;
    }
}

@include breakpoint(medium up) {
    .search-result-content {
        .product-tile-content {
            padding: 0 10px 15px;
        }

        .product-compare {
            border-top: 3px solid map-get($foundation-palette, light-gray);
            flex: 1 1 100%;
            line-height: 55px;
            max-height: 60px;
            text-align: center;

            .compare-label,
            .field-wrapper {
                cursor: pointer;
                display: inline-block;
                position: relative;
            }

            .compare-label {
                @include font-gotham-bold;
                color: map-get($foundation-palette, secondary);

                font-size: rem-calc(14);
                margin: 0 5px 0 0;
                text-transform: uppercase;
            }

            input {
                display: none;
                margin: 0;
                opacity: 0;
                position: relative;
                top: 4px;
                user-select: none;
                z-index: 2;

                &:checked {
                    display: inherit;
                }

                &:checked + .input-custom::after {
                    color: map-get($foundation-palette, secondary);
                    content: "\e811";
                    font-family: "fontello";
                }

                &:focus {
                    outline: none;
                }
            }

            .input-custom {
                left: 0;
                position: absolute;
                top: 2px;
                z-index: 1;

                &::after {
                    color: transparent;
                    content: "\f1db";
                    font-family: "fontello";
                    left: 0;
                    position: absolute;
                }
            }

            .input-custom,
            input {
                height: 17px;
                width: 17px;
            }
        }

        .product-tile-active {
            border-color: map-get($foundation-palette, primary);

            .product-compare {
                background: map-get($foundation-palette, primary);
                border: 3px solid map-get($foundation-palette, primary);

                .compare-label {
                    color: map-get($foundation-palette, secondary);
                    text-decoration: underline;
                }
            }
        }

        .product-tile-active,
        .product-tile--cart-button-hover {
            .recommended-bagde {
                background: map-get($foundation-palette, primary);
                color: map-get($foundation-palette, white-default);
            }

            .compare-label {
                color: map-get($foundation-palette, primary);
            }
        }

        .product-tile--cart-button-hover {
            border-color: $darker-gray-over;

            .compare-label {
                color: map-get($foundation-palette, secondary);
            }
        }
    }
}
