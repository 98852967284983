.compare-page {
    // horizontal scroll for medium
    @include breakpoint(medium only) {
        .scroll-section {
            display: flex;
            flex-flow: column;
            flex-wrap: nowrap;
            margin-right: 20px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            &.no-margin {
                margin-right: 0;
            }
        }

        .row {
            flex-flow: row nowrap;
        }

        .section-title {
            border: 0;
            margin: 0;
        }

        .title-border {
            border-top: 2px solid map-get($foundation-palette, light-gray);
            padding-top: 30px;
        }

        .two-columns {
            &:nth-child(1) {
                .title-border {
                    margin-left: 0.625rem;
                    padding-left: 0;
                }
            }

            &:nth-child(2) {
                .title-border {
                    margin-right: 0.625rem;
                    padding-right: 0;
                }
            }
        }

        .three-columns {
            &:nth-child(1) {
                .title-border {
                    margin-left: 0.625rem;
                    padding-left: 0;
                }
            }

            &:nth-child(3) {
                .title-border {
                    margin-right: 0.625rem;
                    padding-right: 0;
                }
            }
        }
    }

    .small-device-message {
        p {
            @include font-gotham-medium;

            font-size: rem-calc(20);
            margin: 100px 0 0;
            text-align: center;
        }
    }

    .page-title {
        margin: 25px 0 20px;
    }

    a.back {
        @include font-gotham-bold;

        font-size: rem-calc(13);
    }

    .section-box {
        margin: 0 0 50px;
    }

    .section-title {
        @include font-gotham-bold;

        border-bottom: 2px solid map-get($foundation-palette, light-gray);
        font-size: rem-calc(18);
        line-height: 1;
        margin: 0 0 30px;
        padding: 0 0 10px;
        text-transform: uppercase;
    }

    .product-column {
        &:not(:first-of-type) {
            .section-title,
            .test-score .season-title {
                outline: 0;
                text-indent: -9999em;
            }
        }
    }

    #compare-table {
        margin-top: 100px;

        .product-tile-content {
            position: relative;
        }

        .product-winter-use {
            position: absolute;
            right: 0;
        }
    }

    // custom grid for compare page
    .two-columns {
        flex: 0 0 50%;
        max-width: 50%;

        @include breakpoint(large up) {
            &:nth-child(1) {
                padding-left: 0.625rem;

                .row-spaces {
                    padding-right: 8.33333%;
                }
            }

            &:nth-child(2) {
                padding-right: 0.625rem;

                .row-spaces {
                    padding-left: 8.33333%;
                }
            }
        }
    }

    .three-columns {
        flex: 0 0 50%;
        max-width: 50%;

        @include breakpoint(large up) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;

            &:nth-child(1) {
                padding-left: 0.625rem;

                .row-spaces {
                    padding-right: 2.1865rem;
                }
            }

            &:nth-child(2) {
                .row-spaces {
                    padding-left: 1.40575rem;
                    padding-right: 1.40575rem;
                }
            }

            &:nth-child(3) {
                padding-right: 0.625rem;

                .row-spaces {
                    padding-left: 2.1865rem;
                }
            }
        }
    }

    // Product Tiles container
    .product-tile {
        height: auto !important;
        margin: 0 0 25px;
        text-align: center;

        @include breakpoint(medium only) {
            .compare-remove-product {
                display: inline-block !important;
            }
        }

        @include breakpoint(large up) {
            %removeProductIcon { /* stylelint-disable-line */
                .compare-remove-product {
                    color: map-get($foundation-palette, primary);
                    display: inline-block !important;
                    margin: 0 0 0 5px;
                    position: absolute;
                    z-index: 2;
                }
            }

            .product-image {
                &:hover {
                    + .product-name {
                        @extend %removeProductIcon;
                    }
                }
            }

            .product-name {
                &:hover {
                    @extend %removeProductIcon;
                }
            }
        }

        .recommended-bagde,
        .promo-badge,
        .product-attributes,
        .availability-msg,
        .product-size,
        .tested-bykal-badge,
        .aditional-details {
            display: none;
        }

        .product-badge {
            position: absolute;
        }

        .product-image {
            margin: 0 auto;
            max-width: 215px;
            padding: 0 0 25px;

            a {
                display: block;
            }
        }

        .product-name {
            @include font-gotham-bold;

            font-size: 22px;
            line-height: 1;
            position: relative;

            .brand-name {
                display: block;
                font-size: 16px;
                margin: 0 0 5px;
            }

            .name-link {
                color: #000;
            }
        }

        .price-qty-box {
            align-items: flex-end;
            display: flex;
        }

        .product-pricing {
            text-align: left;
            width: 75%;

            .price-sales,
            .price-standard {
                margin-bottom: -5px;
            }

            .price-standard + .price-sales {
                margin: 0 0 0 15px;
            }
        }

        .product-price {
            align-items: flex-end;
            display: flex;
            position: relative;
        }

        .rebate {
            margin: 0 0 0 15px;
        }

        .price-standard + .price-sales + .rebate {
            bottom: -20px;
            left: 0;
            position: absolute;
        }

        .inventory {
            float: right;
            width: 25%;
        }

        .quantity {
            label,
            input.product-quantity {
                display: none;
            }
        }

        .product-quantity {
            select {
                margin: 0;
                max-width: 85px;
            }
        }

        .product-add-to-cart {
            display: block;
            float: right;
            padding: 30px 0 0;
            text-align: right;
            width: 100%;
        }
    }

    .button-group {
        margin: 0;
        text-align: right;
    }

    // Category section
    .categories {
        .category {
            @include font-gotham-medium;

            color: $compare-gray;
            display: block;
            font-size: rem-calc(13);

            &:not(:first-of-type) {
                margin: 10px 0 0;
            }
        }
    }

    // Summary section and Tire Specs section
    .summary,
    .tire-specs {
        .attribute {
            @include clearfix;
            font-size: rem-calc(15);
            margin: 0;
            padding: 7px 12px;

            &:nth-of-type(odd) {
                background: map-get($foundation-palette, light-gray);
            }

            &:nth-of-type(even) {
                padding: 9px 12px;
            }
        }

        .name {
            @include font-gotham-medium;
        }

        .value {
            float: right;
            text-transform: capitalize;
        }
    }

    // Kal's Test Scores
    .test-score {
        .section {
            @include font-gotham-book;

            font-size: rem-calc(15);
            padding: 12px;

            &:nth-of-type(odd) {
                background: map-get($foundation-palette, light-gray);
            }

            ul {
                margin: 0;
            }

            li {
                @include clearfix;
            }

            .attribute-title {
                @include font-gotham-medium;

                font-size: rem-calc(15);
            }
        }

        .label-title {
            float: left;
            padding-right: 10px;
            width: calc(100% - 75px);
        }

        .raiting {
            display: flex;
            float: right;
            justify-content: flex-end;
            width: 75px;;
        }

        [class^="icon-"]::before,
        [class*=" icon-"]::before {
            margin: 0;
        }

        i {
            color: map-get($foundation-palette, primary);
            float: left;
        }

        .season-title {
            @include font-gotham-bold;

            font-size: rem-calc(18);
            line-height: 1;
            margin: 0 0 15px;

            &:not(:first-of-type) {
                margin: 30px 0 15px;
            }
        }
    }

    .small-device-message {
        p {
            @include font-gotham-medium;
            font-size: rem-calc(20);
            margin: 100px 0 0;

            text-align: center;
        }
    }

    .page-title {
        margin: 25px 0 20px;
    }

    a.back {
        @include font-gotham-bold;

        font-size: rem-calc(13);
    }

    .section-box {
        margin: 0 0 50px;
    }

    .section-title {
        @include font-gotham-bold;
        border-bottom: 2px solid map-get($foundation-palette, light-gray);

        font-size: rem-calc(18);
        line-height: 1;
        margin: 0 0 30px;
        padding: 0 0 10px;
        text-transform: uppercase;
    }

    .product-column {
        &:not(:first-of-type) {
            .section-title,
            .test-score .season-title {
                outline: 0;
                text-indent: -9999em;
            }
        }
    }

    #compare-table {
        margin-top: 100px;

        .product-tile-content {
            position: relative;
        }

        .product-winter-use {
            position: absolute;
            right: 0;
        }
    }

    // custom grid for compare page
    .two-columns {
        flex: 0 0 50%;
        max-width: 50%;

        @include breakpoint(large up) {
            &:nth-child(1) {
                padding-left: 0.625rem;

                .row-spaces {
                    padding-right: 8.33333%;
                }
            }

            &:nth-child(2) {
                padding-right: 0.625rem;

                .row-spaces {
                    padding-left: 8.33333%;
                }
            }
        }
    }

    .three-columns {
        flex: 0 0 50%;
        max-width: 50%;

        @include breakpoint(large up) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;

            &:nth-child(1) {
                padding-left: 0.625rem;

                .row-spaces {
                    padding-right: 2.1865rem;
                }
            }

            &:nth-child(2) {
                .row-spaces {
                    padding-left: 1.40575rem;
                    padding-right: 1.40575rem;
                }
            }

            &:nth-child(3) {
                padding-right: 0.625rem;

                .row-spaces {
                    padding-left: 2.1865rem;
                }
            }
        }
    }

    // Product Tiles container
    .product-tile {
        height: auto !important;
        margin: 0 0 25px;
        text-align: center;

        @include breakpoint(medium only) {
            .compare-remove-product {
                display: inline-block !important;
            }
        }

        @include breakpoint(large up) {
            %removeProductIcon { /* stylelint-disable-line */
                .compare-remove-product {
                    color: map-get($foundation-palette, primary);
                    display: inline-block !important;
                    margin: 0 0 0 5px;
                    position: absolute;
                    z-index: 2;
                }
            }

            .product-image {
                &:hover {
                    + .product-name {
                        @extend %removeProductIcon;
                    }
                }
            }

            .product-name {
                &:hover {
                    @extend %removeProductIcon;
                }
            }
        }

        .recommended-bagde,
        .promo-badge,
        .product-attributes,
        .availability-msg,
        .product-size,
        .tested-bykal-badge,
        .aditional-details {
            display: none;
        }

        .product-badge {
            position: absolute;
        }

        .product-image {
            margin: 0 auto;
            max-width: 215px;
            padding: 0 0 25px;

            a {
                display: block;
            }
        }

        .product-name {
            @include font-gotham-bold;

            font-size: 22px;
            line-height: 1;
            position: relative;

            .brand-name {
                display: block;
                font-size: 16px;
                margin: 0 0 5px;
            }

            .name-link {
                color: #000;
            }
        }
        
        // Reviews
        .product-review {
            height: 25px;
            margin: 5px 0 25px;
        }

        .sa_s28_display_avg_rating {
            display: flex;
            justify-content: center;

            ul {
                margin: 0;
            }

            .sa_s28_rating,
            .sa_s28_points_rating {
                display: inline-block;
            }

            .sa_s28_leftBrackets,
            .sa_s28_productReviewCount,
            .sa_s28_masterCategoryPageText,
            .sa_s28_childCategoryPageText,
            .sa_s28_rightBrackets {
                display: none;
            }

            .sa_s28_points_rating {
                span {
                    color: map-get($foundation-palette, secondary);
                    font-size: rem-calc(12);
                    margin: 0 0 0 5px;
                }
            }
        }

        .price-qty-box {
            align-items: flex-end;
            display: flex;
        }

        .product-pricing {
            text-align: left;
            width: 75%;

            .price-sales,
            .price-standard {
                margin-bottom: -5px;
            }

            .price-standard + .price-sales {
                margin: 0 0 0 15px;
            }
        }

        .product-price {
            align-items: flex-end;
            display: flex;
            position: relative;
        }

        .rebate {
            margin: 0 0 0 15px;
        }

        .price-standard + .price-sales + .rebate {
            bottom: -20px;
            left: 0;
            position: absolute;
        }

        .inventory {
            float: right;
            width: 25%;
        }

        .quantity {
            label,
            input.product-quantity {
                display: none;
            }
        }

        .product-quantity {
            select {
                margin: 0;
                max-width: 85px;
            }
        }

        .product-add-to-cart {
            display: block;
            float: right;
            padding: 30px 0 0;
            text-align: right;
            width: 100%;
        }
    }

    .button-group {
        margin: 0;
        text-align: right;
    }

    // Category section
    .categories {
        .category {
            @include font-gotham-medium;
            color: #999;
            display: block;

            font-size: rem-calc(13);

            &:not(:first-of-type) {
                margin: 10px 0 0;
            }
        }
    }

    // Summary section and Tire Specs section
    .summary,
    .tire-specs {
        .attribute {
            @include clearfix;
            font-size: rem-calc(15);
            margin: 0;
            padding: 7px 12px;

            &:nth-of-type(odd) {
                background: map-get($foundation-palette, light-gray);
            }

            &:nth-of-type(even) {
                padding: 9px 12px;
            }
        }

        .name {
            @include font-gotham-medium;
        }

        .value {
            float: right;
            text-transform: capitalize;
        }
    }

    // Kal's Test Scores
    .test-score {
        .section {
            @include font-gotham-book;

            font-size: rem-calc(15);
            padding: 12px;

            &:nth-of-type(odd) {
                background: map-get($foundation-palette, light-gray);
            }

            ul {
                margin: 0;
            }

            li {
                @include clearfix;
            }

            .attribute-title {
                @include font-gotham-medium;

                font-size: rem-calc(15);
            }
        }

        .label-title {
            float: left;
            padding-right: 10px;
            width: calc(100% - 75px);
        }

        .raiting {
            display: flex;
            float: right;
            justify-content: flex-end;
            width: 75px;
        }

        [class^="icon-"]::before,
        [class*=" icon-"]::before {
            margin: 0;
        }

        i {
            color: map-get($foundation-palette, primary);
            float: left;
        }

        .season-title {
            @include font-gotham-bold;

            font-size: rem-calc(18);
            line-height: 1;
            margin: 0 0 15px;

            &:not(:first-of-type) {
                margin: 30px 0 15px;
            }
        }
    }

    // Product set
    .product-set {
        // Product tiles section
        .product-tile {
            margin: 0;

            .product-size {
                .product-attributes {
                    display: block;
                }
            }
        }

        .size-price-qty-box {
            .box-spacing {
                margin: 0 0 25px;

                @include breakpoint(medium only) {
                    margin: 0 0 35px;
                }
            }
        }

        .product-size {
            display: inline-block;
            text-align: left;
            width: 18%;

            @include breakpoint(medium only) {
                width: 30%;
            }
        }

        .priceQtyCompareForm {
            display: inline-block;
            width: 82%;

            @include breakpoint(medium only) {
                width: 70%;
            }

            .promotion {
                display: none;
            }
        }

        .product-set-item {
            @include clearfix;
        }

        .product-set-details {
            display: inline-block;
            float: left;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            width: 65%;

            @include breakpoint(medium only) {
                width: 73%;

                .product-price {
                    display: block;
                }

                .price-sales,
                .price-standard {
                    margin-bottom: 0;
                }

                .price-standard + .price-sales {
                    margin: 2px 0 0;
                }
            }
        }

        .product-pricing {
            width: 100%;
        }

        .add-sub-product {
            display: inline-block;
            float: right;
            text-align: right;
            width: 35%;

            @include breakpoint(medium only) {
                width: 25%;
            }

            .product-quantity {
                float: none;
                width: auto;
            }
        }

        .product-add-to-cart {
            float: none;
            padding: 0;
        }

        // Summary and Tire Specs sections
        .set-attr-titles {
            line-height: 1;
            padding: 0 12px 12px;
            text-align: right;
        }

        .set-attribute-name {
            display: inline-block;
            font-size: 15px;
            padding-left: 0.625rem;
            width: 18%;

            @include breakpoint(medium only) {
                width: 33%;
            }
        }

        .summary,
        .tire-specs {
            .attribute {
                > .value {
                    display: inline-block;
                    padding-left: 0.625rem;
                    text-align: right;
                    width: 18%;

                    @include breakpoint(medium only) {
                        width: 33%;
                    }
                }

                > .name {
                    float: left;

                    @include breakpoint(medium only) {
                        width: 34%;
                    }
                }
            }
        }

        .tire-specs {
            .value-rear {
                .name {
                    display: none;
                }
            }

            .finish_attribute {
                .value {
                    width: 66%;
                }

                .value:not(.value-rear) {
                    display: none;
                }
            }
        }
    }
}
