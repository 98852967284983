// Login page styles

.login-wrapper {
    margin-bottom: 6.25rem;
    padding: 0.3125rem 0;

    @include breakpoint(medium up) {
        border: 5px solid rgba(map-get($foundation-palette, light-gray), 0.8);
        display: flex;
        padding-bottom: 2.813rem;
        padding-top: 4.063rem;
    }

    .pt_cart & {
        margin-bottom: 0;
    }

    h5 {
        margin-bottom: 0.9375rem;

        @include breakpoint(medium up) {
            margin-bottom: 1.375rem;
        }
    }

    .dialog-required {
        margin: 0 0 1rem;
        text-align: left;
    }

    .bordered-end {
        border-bottom: 1px solid map-get($foundation-palette, light-gray);
        margin-bottom: 2.375rem;
        padding-bottom: 0.3125rem;

        @include breakpoint(medium up) {
            border-bottom: 0;
            border-right: 1px solid map-get($foundation-palette, light-gray);
            margin-bottom: 0;
            margin-right: 0.625rem;
            padding-bottom: 0;
            padding-right: 0.625rem;

            .button-group {
                margin-bottom: 0;
            }
        }
    }

    .password-reset {
        font-size: rem-calc(11);
        line-height: 1.625rem;

        @include breakpoint(medium up) {
            @include font-gotham-bold;

            font-size: rem-calc(12);
        }
    }

    .login-rememberme {
        margin: 0.9375rem 0 1.875rem;

        @include breakpoint(medium up) {
            margin-bottom: 3.125rem;
        }
    }

    .error-form {
        margin-bottom: 1.125rem;
    }

    .login-create-account p {
        margin: 0.1875rem 0 1.75rem;

        @include breakpoint(medium up) {
            font-size: rem-calc(14);
            line-height: 1.1875rem;
            margin-bottom: 2.875rem;
        }
    }
}

.login-page-title,
%login-page-title {
    @include font-gotham-bold;

    font-size: 21px;
    margin: 20px 0;
    text-transform: uppercase;

    @include breakpoint(medium up) {
        font-size: rem-calc(26);
        margin: 30px 0 2.9375rem;
    }

    @include breakpoint(medium up) {
        margin: 50px 0 2.9375rem;
    }
}

// Modal dialog styling

.ui-widget-overlay {
    background: rgba(map-get($foundation-palette, secondary), 0.9);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.ui-widget {
    font-size: rem-calc(14);
    max-width: 100%;
    outline: none;
    padding: 0 0.9375rem;
    z-index: 101;

    p {
        line-height: 1.375rem;
        margin-bottom: 1.375rem;
        margin-bottom: 1.5625rem;

        @include breakpoint(medium up) {
            margin-bottom: 1rem;
        }
    }

    .dialog-content {
        background: map-get($foundation-palette, white-default);
        padding: 1.0625rem 1.25rem;

        @include breakpoint(medium up) {
            padding: 1.6875rem 1.875rem 2.6875rem;
        }
    }

    h1 {
        font-size: rem-calc(18);
        margin-bottom: 1.5625rem;

        @include breakpoint(medium up) {
            margin-bottom: 1.1875rem;
        }
    }

    .ui-dialog-title {
        display: none;
    }

    .ui-dialog-titlebar-close {
        border: 0;
        outline: none;
        padding: 0;
        position: absolute;
        right: 35px;
        top: 12px;

        @include breakpoint(medium up) {
            right: 47px;
            top: 24px;
        }
    }

    .ui-button-icon-only {
        .ui-button-text {
            display: none;
        }

        .ui-button-icon-primary {
            &::after {
                color: map-get($foundation-palette, primary);
                content: "\f00d";
                font-family: fontawesome;
                font-size: rem-calc(24);
            }

            &:hover::after {
                color: #db5a00;
            }
        }
    }

    .ui-dialog-buttonpane {
        background: map-get($foundation-palette, white-default);
        padding: 1.5rem 1.25rem;

        @include breakpoint(medium up) {
            padding: 0 1.875rem 1.5em;
        }
    }

    &.no-buttonpane .ui-dialog-buttonpane {
        display: none;
    }

    .button-modal {
        margin-top: -2rem;
        padding: 0.75rem 18px;
    }
}

// Reset password dialog styles

.forgot-password {
    @include font-gotham-medium;

    font-size: rem-calc(13);
}

.password-reset-dialog {
    max-width: 42rem;

    @include breakpoint(medium up) {
        p {
            font-size: rem-calc(14);
        }

        .form-row {
            float: left;
            margin-right: 0.625rem;
            min-width: 400px;
        }

        .button-group {
            display: inline-block;
            margin-top: 1.375rem;
        }

        strong {
            @include font-gotham-bold;
        }
    }

    .button-group {
        margin-bottom: 0;
    }
}

.pt_account {
    .medium-centered {
        margin-left: auto;
        margin-right: auto;
    }
}
