#rebatesLandingWrapper {
    .rebatesTopSlotWrapper {
        background: map-get($foundation-palette, secondary);
        background-size: cover;
        color: map-get($foundation-palette, white-default);
        padding: 70px 0 100px;
        position: relative;

        @include breakpoint(medium up) {
            padding: 125px 0 135px;
        }

        h1,
        h2 {
            @include font-gotham-bold;
            font-size: 46px;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: 67px;
            }
        }

        p {
            @include font-gotham-medium;

            font-size: 14px;
            line-height: 1.2rem;
            margin: 1.4rem 0 0;

            @include breakpoint(medium up) {
                font-size: 18px;
                line-height: 1.6rem;
            }
        }

        .rebates-tabs-nav-wrapper {
            background: rgba(0, 0, 0, 0.6);
            bottom: 0;
            left: 0;
            line-height: 45px;
            min-height: 45px;
            position: absolute;
            right: 0;
            width: 100%;

            @include breakpoint(medium up) {
                line-height: 60px;
                min-height: 60px;
            }
        }
    }

    .rebates-tabs-nav {
        display: flex;
        flex-wrap: wrap;
        line-height: 45px;
        margin: 0;

        @include breakpoint(medium up) {
            line-height: 60px;
        }

        li {
            display: inline;
            flex-grow: 1;
            position: relative;
            text-align: center;

            &.active a {
                opacity: 1;
            }

            a {
                @include font-gotham-bold;

                color: map-get($foundation-palette, white-default);
                display: block;
                font-size: 12px;
                opacity: 0.6;
                width: 100%;

                @include breakpoint(medium up) {
                    font-size: 16px;
                }
            }

            &:hover::after,
            &.active::after {
                background: map-get($foundation-palette, primary);
                bottom: 0;
                content: "";
                height: 3px;
                left: 0;
                position: absolute;
                right: 0;
                width: 100%;

                @include breakpoint(medium up) {
                    height: 5px;
                }
            }
        }
    }

    .rebatesMainContentWrapper {
        margin: 45px 0 115px;

        @include breakpoint(medium up) {
            margin: 75px 0 115px;
        }
    }

    .rebates-landing-head {
        h2 {
            @include font-gotham-bold;

            font-size: 18px;

            @include breakpoint(medium up) {
                font-size: 22px;
            }
        }

        p {
            @include font-gotham-book;

            font-size: 14px;

            @include breakpoint(medium up) {
                font-size: 16px;
            }
        }

        img {
            display: block;
            height: auto;
            width: 100%;
        }

        .button.small {
            margin: 20px 0 0;
            padding: 8px 15px;

            @include breakpoint(medium up) {
                font-size: 13px;
                margin: 30px 0 0;
                padding: 10px 15px;
            }
        }
    }

    .rebates-item {
        @include clearfix;
        border: 6px solid map-get($foundation-palette, light-gray);
        margin: 15px 0 0;
        padding: 7px;
        width: 100%;

        @include breakpoint(medium up) {
            margin: 20px 0 0;
            padding: 12px;
        }

        .rebates-item-img {
            display: none;
            float: left;
            width: 35.332%;

            @include breakpoint(medium up) {
                display: block;
            }

            img {
                width: 100%;
            }
        }

        .rebates-item-content {
            box-sizing: border-box;
            float: left;
            width: 100%;

            @include breakpoint(medium up) {
                padding-left: 20px;
                width: 64.67%;
            }
        }

        h3 {
            font-size: 16px;
            margin: 0;
            padding: 0 10px 5px;
        }

        h2 {
            font-size: 22px;
            margin: 0;
            padding: 0 10px 10px;
        }

        p {
            font-size: 14px;
            margin: 0;
            padding: 0 10px 18px;

            strong {
                @include font-gotham-bold;
            }
        }

        a.button {
            margin: 0 0 8px 10px;
            padding: 11px 15px;

            @include breakpoint(medium up) {
                margin: 0 0 0 10px;
            }
        }
    }

    .rebates-item-type-row {
        overflow: hidden;

        .rebates-item-type {
            float: left;

            @include breakpoint(medium up) {
                float: right;
            }
        }
    }

    .rebates-item-type {
        @include font-gotham-bold;
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        display: inline-block;

        font-size: 12px;
        line-height: 27px;
        margin-bottom: 15px;
        padding: 0 13px;

        &.black {
            background: map-get($foundation-palette, secondary);
        }

    }
}
