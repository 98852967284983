.packages-selection {
    margin: 40px 0 15px;

    @include breakpoint(small only) {
        padding: 0 30px;
    }

    .circle-indicator {
        @include font-gotham-medium;
        color: map-get($foundation-palette, secondary);

        display: block;
        font-size: 18px;

        @include breakpoint(medium up) {
            display: inline-block;
            margin-left: 90px;

            &:first-child {
                margin: 0;
            }
        }

        &:first-child {
            margin: 0 0 12px;
        }

        .pk-edit-wheel {
            @include font-gotham-bold;

            font-size: 13px;
            margin-left: 5px;
        }

        .step-number {
            @include font-gotham-bold;

            background: map-get($foundation-palette, secondary);
            border-radius: 50%;
            color: map-get($foundation-palette, white-default);
            display: inline-block;
            font-size: 18px;
            height: 32px;
            line-height: 32px;
            margin: 0 0.3125rem 0 0;
            text-align: center;
            width: 32px;
        }

        &.selected {
            color: map-get($foundation-palette, primary);

            .step-number {
                background: map-get($foundation-palette, primary);
            }
        }

        &.disabled {
            color: #d9d9da;

            .step-number {
                background: #d9d9da;
            }
        }
    }
}

.selector-packages {
    h2 {
        font-size: rem-calc(20);
        margin-bottom: 0;
        text-transform: none;
    }

    h5 {
        margin-bottom: 0;

        @include breakpoint(small only) {
            margin-top: 2rem;
        }
    }

    .mid-line-copy {
        margin: 2.5rem 0 2.375rem;
    }
    

    .oe-size {
        border: 0;
        display: inline-block;
        margin: 0 0 1rem;

        @include breakpoint(medium up) {
            margin: 0 0 3rem;
        }

        .sel-subtitle span {
            @include font-gotham-medium;
        }

        a {
            color: map-get($foundation-palette, midgrey);
            display: block;
            font-size: rem-calc(15);
            padding: 15px 18px;

            &:not(.sel-subtitle) {
                @include font-gotham-bold;
                color: map-get($foundation-palette, secondary);

                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        &.oe-size-orange {

            background: map-get($foundation-palette, primary);
            transition: all 0.3s ease-in-out;

            &:hover {
                background: $button-hover;
                border-color: $button-hover;
            }

            a {
                color: map-get($foundation-palette, white-default);
            }
        }
    }

    .optional-sizes-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.1875rem;
        

        i {
            color: map-get($foundation-palette, primary);
            margin-left: 15px;

            &::before {
                font-size: 40px;
                line-height: 27px;
            }
        }
    }

    .show-self-service {
        @include font-gotham-book;
    }

    .oe-size-container {
        .section-tite {
            margin-bottom: 0.5rem;
        }

        .sel-subtitle {
            margin: 0;
        }

        .subtitle-title {
            font-weight: bold;
        }
    }

    .your-vehicle-info {
        margin-bottom: 1rem;
    }

    .section-title-tooltip {
        align-items: center;
        display: flex;
        margin-bottom: 0.5rem;

        .sel-subtitle {
            margin-bottom: 0;
            margin-left: 0.5rem;
        }
    }
}

.oe-size-btn {
    text-align: center;
}

.section-question-copy p {
    @include font-gotham-medium;
    color: map-get($foundation-palette, midgrey);

    font-size: rem-calc(13);

    span {
        color: map-get($foundation-palette, primary);
        cursor: pointer;
        float: left;
    }
}

.packages-section-heading {
    @extend .section-question-copy; /* stylelint-disable-line */

    p {
        @include font-gotham-book;
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(14);

        margin-bottom: 1.3125rem;
    }

    a {
        border-bottom: 2px dotted map-get($foundation-palette, primary);
        color: inherit;
        font-style: italic;
    }
}

.packages-section-heading-main {
    margin-top: 1rem;

    p {
        margin-top: 1rem;
    }
}

.packages-staggered {
    margin-top: rem-calc(30);
    
    .package-staggered-button {
        margin-left: rem-calc(15);
        
        @include breakpoint(small only) {
            margin-left: 0;
            margin-top: rem-calc(15);
        }
    }
}
