// Empty Cart page
.cart-empty {
    margin-top: 4rem;

    h1 {
        margin-bottom: 2rem;
        text-align: center;
    }

    .cart-action-continue-shopping {
        float: none;
        margin-bottom: 4rem;
        text-align: center;
    }
}

// Cart page
.cart-page {
    margin-top: rem-calc(40);

    @include breakpoint(small down) {
        display: flex;
        flex-direction: column;
    }

    .page-title {
        margin: 0;
        text-transform: none;

        h1 {
            font-size: rem-calc(21);
            line-height: 1;
            margin: 0;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: rem-calc(26);
            }
        }
    }

    .vehicle-info {
        @include font-gotham-medium;

        font-size: rem-calc(14);
        line-height: 1.3;
        margin: 0.625rem 0 0;

        @include breakpoint(medium up) {
            font-size: rem-calc(18);
        }

        .editVehicleCart {
            color: map-get($foundation-palette, secondary);
            text-decoration: underline;
        }

        a {
            @include font-gotham-bold;

            font-size: rem-calc(13);
        }
    }

    .cart-technical-difficulties {
        background-color: $black;
        color: $white;
        text-align: center;

        .small-12 {
            padding: 30px 10px 20px;

            p {
                span {
                    @include font-gotham-bold;
                }
            }
        }
    }

    .cart-technical-difficulties.header {
        .row {
            margin: -40px 0 10px;
        }
    }

    .header-product-details {
        @include font-gotham-bold;

        font-size: rem-calc(18);
        margin: rem-calc(16) 0;
    }

    .item-list {
        border: 4px solid map-get($foundation-palette, white-ghost);
        margin: 0 0 rem-calc(25);
        padding: rem-calc(20 0);

        .section-header {
            @include font-gotham-medium;

            font-size: rem-calc(15);
            margin: 0 0 0.3125rem;
        }
    }

    .main-section-header {
        @include breakpoint(medium up) {
            .qty-header {
                text-align: center;
            }

            .total-price-header {
                text-align: right;
            }
        }
    }

    .cart-row,
    .service-row {
        @include breakpoint(medium up) {
            .item-quantity {
                text-align: center;
            }

            .item-total,
            .service-item-total {
                text-align: right;
            }
        }
    }

    .cart-line-separator {
        &::before {
            background: #e8e8e9;
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            margin: auto 0;
            right: 0;
            top: 0;
            width: 100%;
        }
    }

    .order-summary-row {
        margin-bottom: 1.875rem;

        @include breakpoint(medium up) {
            margin-bottom: 3.125rem;
        }

        .cart-line-separator {
            @include breakpoint(medium up) {
                order: 3;
            }
        }
    }

    .disclaimer-cart {
        @include font-gotham-book;
        color: map-get($foundation-palette, midgrey);
        font-size: rem-calc(12);
        margin-top: rem-calc(10);

        p {
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(12);
            margin: 0;

            &.levy-message-cart {
                color: map-get($foundation-palette, midgrey);
                font-weight: bold;
                margin-top: rem-calc(20);
            }
        }
    }

    .order-summary {
        @include breakpoint(small down) {
            margin-top: rem-calc(50);
        }
    }

    .order-totals-table {
        margin: rem-calc(15) 0;

        tbody {
            border: 0;

            td {
                padding: 0 0 rem-calc(15);
            }
        }

        tr {
            @include font-gotham-book;

            background: map-get($foundation-palette, light-gray);
            font-size: rem-calc(14);
            line-height: 1;

            td:first-child {
                padding-right: rem-calc(15);
                text-align: left;
            }

            td:last-child {
                text-align: right;
            }

            &.vehicle-info {
                display: none;
            }

            &.order-total {
                @include font-gotham-bold;

                font-size: rem-calc(15);

                td {
                    padding-bottom: 0;
                }
            }
        }
    }

    .out-of-stock-message-box {
        float: right;
        margin: 0 0 1.875rem;
        width: 300px;

        @include breakpoint(medium up) {
            margin: 0 0 3.125rem;
        }

        p {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);
            font-size: rem-calc(12);

            font-style: italic;
            margin: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    .cart-financing {
        align-items: center;
        background-color: map-get($foundation-palette, white-default);
        display: flex;
        height: 100%;
        justify-content: space-between;
        margin-top: rem-calc(15);
        min-height: 45px;

        p {
            color: map-get($foundation-palette, secondary);
            display: inline-block;
            float: left;
            font-size: rem-calc(14);
            font-weight: 500;
            margin: 0;
        }

        .cart-financing-link {
            display: inline-block;
            margin-left: 6px;
        }
    }

    .cart-action-checkout-box {
        @include breakpoint(medium up) {
            order: 2;
        }
    }

    .cart-action-checkout {
        margin: 0 0 rem-calc(15);

        @include breakpoint(medium up) {
            margin: 0;
        }

        .button {
            font-size: rem-calc(12);
            margin: 0;
            padding: rem-calc(12) rem-calc(18);
            width: 100%;

            &::before {
                content: "\f023";
                font-family: fontawesome;
                padding-right: 5px;
            }
        }
    }

    .need-help-cart {
        h3 {
            font-size: rem-calc(18);
            margin: 0 0 0.625rem;
        }

        p {
            font-size: rem-calc(12);
            margin: 0 0 0.3125rem;
        }

        a {
            font-size: rem-calc(14);
        }
    }

    .verify-vehicle {
        border: 4px solid map-get($foundation-palette, white-ghost);
        margin: rem-calc(16) 0 0;
        padding: rem-calc(15);

        @include breakpoint(medium up) {
            align-items: center;
            display: flex;
            padding: rem-calc(18);
        }

        @include breakpoint(medium up) {
            .left-box,
            .right-box {
                width: 41.66667%;
            }

            .left-box {
                float: left;
            }

            .right-box {
                float: right;
                margin-left: 16.66667%;
                text-align: right;
            }
        }

        p {
            @include font-gotham-bold;

            font-size: rem-calc(14);
            line-height: 1.3;
            margin: 0 0 1.25rem;

            @include breakpoint(medium up) {
                margin: 0;
            }
        }

        .button {
            margin: 0;

            @include breakpoint(small only) {
                width: 100%;
            }
        }
    }

    .verified-response {
        @include breakpoint(small only) {
            align-items: center;
            display: flex;
        }

        .icon-box {
            float: left;
            padding-right: 1.313rem;
            text-align: center;
            width: 27%;
        }

        .text-box {
            width: 73%;

            @include breakpoint(medium up) {
                width: 100%;
            }
        }

        .icon-alert {
            color: map-get($foundation-palette, primary-accessible);
            font-size: rem-calc(30);

            @include breakpoint(medium up) {
                margin: 0 0.9375rem 0 0;
            }
        }

        .fa-check {
            color: map-get($foundation-palette, primary-accessible);
            font-size: 16px;

            @include breakpoint(medium up) {
                margin: 0 0.9375rem 0 0;
            }

            &::before {
                border: 3px solid map-get($foundation-palette, primary-accessible);
                border-radius: 50%;
                padding: 0.3125rem;
            }
        }

        p {
            margin: 0;
        }

        .left-box {
            @include breakpoint(medium up) {
                align-items: center;
                display: flex;
            }
        }

        .right-box {
            p {
                @include font-gotham-medium;

                @include breakpoint(small only) {
                    margin: 0.3125rem 0 0;
                }

                a {
                    @include font-gotham-bold;

                    font-size: rem-calc(13);
                }
            }
        }
    }

    .sticky-section-wrapper {
        @include breakpoint(medium up) {
            position: sticky;
            top: 100px;
        }

        .order-summary-wrapper {
            background-color: map-get($foundation-palette, light-gray);
            padding: rem-calc(15);
        }

        .marketing-card {
            background-image: url("../images/interface/tire-mark.png");
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: rem-calc(20);

            .learn-more-wrapper {
                align-items: center;
                display: flex;
                height: 100%;
                min-height: 20px;
                padding-top: rem-calc(15);

                .learn-more-link {
                    display: flex;

                    &:hover > .more-info-text {
                        text-decoration: underline;
                    }
                }

                .more-info-text {
                    color: map-get($foundation-palette, secondary);
                    display: inline-block;
                    font-size: rem-calc(14);
                    margin: 0;
                }
            }
        }

        .marketing-info-wrapper {
            border: 4px solid map-get($foundation-palette, white-ghost);
            height: 100%;
            padding: rem-calc(15);

            .marketing-title {
                font-size: rem-calc(20);

                span {
                    color: map-get($foundation-palette, primary);
                }
            }

            .marketing-info-section {
                display: flex;
                padding-top: rem-calc(10);
                position: relative;
                text-align: left;

                .content-wrapper {
                    padding-left: rem-calc(20);
                }

                h4 {
                    font-size: rem-calc(16);
                }

                p {
                    color: $black;
                    font-size: rem-calc(13);
                    margin-bottom: 0;
                }

                i {
                    color: map-get($foundation-palette, primary);
                    display: block;
                    font-size: rem-calc(30);

                    &::before {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

// Cart Product row
.cart-row {
    margin-bottom: rem-calc(20);

    .service-row {
        margin: 0;
    }

    .product-list-item {
        @include breakpoint(small only) {
            margin: 0 0 0.9375rem;
        }
    }

    .item-image {
        margin: 0 auto;
        max-width: 100px;

        @include breakpoint(medium up) {
            max-width: 120px;
        }

        @include breakpoint(large up) {
            max-width: 190px;
        }
    }

    .product-name-box {
        margin: 0 0 0.9375rem;

        @include breakpoint(medium up) {
            margin: 0 0 rem-calc(22);
        }
    }

    .product-name {
        @include font-gotham-bold;
        color: map-get($foundation-palette, secondary);

        font-size: rem-calc(18);
        line-height: 1;

        @include breakpoint(medium up) {
            font-size: rem-calc(22);
        }
    }

    .product-brand {
        @include font-gotham-bold;
        display: block;

        font-size: rem-calc(14);
        line-height: 1;

        @include breakpoint(medium up) {
            font-size: rem-calc(16);
        }
    }

    .product-attributes {
        .attribute {
            font-size: rem-calc(14);
            line-height: 1;
            margin: 0;
            padding: 0.3125rem 0;
        }

        &.category-attributes {
            .name {
                float: left;
                margin-right: 0.3125rem;
                min-height: 18px;
                overflow: hidden;
            }
        }

        .name {
            @include font-gotham-medium;
        }

        .value {
            @include font-gotham-book;

            text-transform: capitalize;
        }
    }

    .availability-attribute {
        font-size: rem-calc(14);
        line-height: 1;
        padding: 0.3125rem 0;

        .availability-label {
            @include font-gotham-medium;
        }

        .product-availability-list {
            @include font-gotham-book;
            display: inline-block;

            line-height: 1;
            margin: 0;
        }
    }

    .item-qty-price-section {
        @include breakpoint(small down) {
            flex-direction: row-reverse;
        }
    }

    .section-header.qty-header,
    .section-header.item-price-header {
        @include breakpoint(medium up) {
            margin-bottom: rem-calc(20);
        }
    }

    .item-price {
        @include font-gotham-book;

        font-size: rem-calc(15);

        @include breakpoint(medium up) {
            text-align: right;
        }

        .old-price {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: rem-calc(12);
            text-decoration: line-through;
        }
    }

    .item-total {
        font-size: rem-calc(16);
        margin-top: rem-calc(36);

        @include breakpoint(small only) {
            margin: 0.9375rem 0 0;
        }
    }

    .price-total {
        @include font-gotham-book;

        font-size: rem-calc(16);
    }

    .product-card-quantity.item-quantity {
        text-align: left;

        .product-quantity {
            display: inline-block;
            max-width: 55px;
            width: 100%;

            @include breakpoint(medium up) {
                display: block;
            }
        }

        .productQuantity {
            margin: 0;
        }
    }

    .remove-btn {
        @include font-gotham-bold;
        color: map-get($foundation-palette, secondary);

        font-size: rem-calc(11);
        text-decoration: underline;

        @include breakpoint(medium up) {
            font-size: rem-calc(14);
        }
    }

    .rebate-box {
        margin: 1.25rem 0 0;

        @include breakpoint(medium up) {
            padding-left: rem-calc(30);
        }
    }

    .rebate-badge {
        @include font-gotham-bold;
        align-items: center;
        color: map-get($foundation-palette, primary-accessible);
        display: flex;

        font-size: rem-calc(11);
        margin: 0 0 0.3125rem;

        @include breakpoint(medium up) {
            justify-content: flex-end;
        }

        @include breakpoint(large up) {
            font-size: rem-calc(13);
        }

        &::before {
            background: url("../images/icons/rebate_icon.png") no-repeat;
            content: "";
            height: 25px;
            width: 30px;
        }
    }

    .rebate-text {
        @include font-gotham-book;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(14);
        font-style: italic;
    }

    // Product set
    &.first-pset {
        @include breakpoint(medium up) {
            margin: 0 0 1.875rem;
        }
    }

    &.second-pset {
        .item-image {
            display: none;
        }

        .remove-btn {
            margin: 0.9375rem 0 0;
        }
    }

    .front-back-set-label {
        @include font-gotham-bold;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(14);
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: rem-calc(11);
        }
    }
}

// Services
.cart-services {
    // Required Services
    &.required-services {
        margin-top: rem-calc(40);

        .service-row {
            padding: rem-calc(15 0 0);
        }

        .service-item-price-wrapper {
            @include breakpoint(small down) {
                margin-top: rem-calc(15);
            }
        }

        .item-price {
            @include breakpoint(small down) {
                padding-left: rem-calc(10);
            }
        }

        .service-info-wrapper {
            margin-left: 0;
        }
    }

    // Included Services
    &.included-services {
        .item-price {
            font-size: rem-calc(16);
            font-weight: 500;
        }

        .service-row {
            border: 4px solid map-get($foundation-palette, white-ghost);

            @include breakpoint(medium up) {
                position: relative;
            }
        }

        .service-item-price-wrapper {
            @include breakpoint(medium up) {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    // Grouped servises
    &.grouped-services {
        .recommended-service {
            font-size: rem-calc(10);
            margin-bottom: rem-calc(12);
            text-transform: uppercase;

            @include breakpoint(medium up) {
                text-align: right;
            }

            span {
                background-color: map-get($foundation-palette, light-gray);
                padding: rem-calc(5);
            }
        }

        .service-row {
            border: 4px solid map-get($foundation-palette, white-ghost);
        }

        .service-group-name {
            font-size: rem-calc(18);
            margin: rem-calc(40 0 15);
        }

        .service-item-total {
            font-weight: 500;
        }

        .item-price {
            @include breakpoint(small down) {
                padding-left: rem-calc(10);
            }
        }

        .service-item-price-wrapper {
            @include breakpoint(small down) {
                margin: rem-calc(10 0);
            }

            @include breakpoint(medium up) {
                max-height: rem-calc(20);
            }
        }

        .service-item-price {
            @include breakpoint(small down) {
                display: flex;
            }
        }
    }

    .service-row {
        margin: 0;
        padding: rem-calc(15 0);
    }

    .bottom-margin {
        margin: rem-calc(0 0 20);
    }

    .service-item-details {
        @include breakpoint(small only) {
            padding-left: 60px;
        }
    }

    .service-item-price-wrapper {
        @include breakpoint(small down) {
            display: flex;
        }
    }

    .service-info-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include breakpoint(medium up) {
            margin-left: rem-calc(25);
        }

        .service-image {
            flex: 1;
        }

        .service-description {
            flex: 1;
            padding: 0 0 0 10px;
        }
    }

    .service-name-box {
        display: flex;
        justify-content: space-between;
        margin: rem-calc(0 0 10);
    }

    .service-name {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1;
    }

    .service-label {
        @include font-gotham-medium;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(11);

        @include breakpoint(medium up) {
            font-size: rem-calc(13);
        }
    }

    .short-description {
        font-size: rem-calc(14);
        line-height: 1.3;
        margin: 0;

        @include breakpoint(medium up) {
            margin: 0 0 0.625rem;
        }
    }

    .service-image {
        margin: 0 auto;
        max-width: 50px;

        @include breakpoint(medium up) {
            max-width: 60px;
        }
    }

    .service-total-wrapper {
        @include breakpoint(small down) {
            padding: rem-calc(0 0 0 12);
        }
    }

    .more-info-link {
        align-items: center;
        display: flex;

        @include breakpoint(small down) {
            margin-top: rem-calc(10);
        }
    }

    .more-info-text {
        @include font-gotham-bold;
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: 1;

        &:hover {
            text-decoration: underline;
        }
    }

    .section-header {
        @include font-gotham-medium;

        font-size: rem-calc(15);
        line-height: 1;
        margin: 0 0 0.3125rem;
    }

    .main-header {
        @include font-gotham-bold;
        margin: 0 0 2.188rem;

        text-transform: uppercase;

        @include breakpoint(medium up) {
            margin: 0;
        }
    }

    .main-section-header {
        margin-bottom: 2.5rem;
    }

    .service-item-total {
        @include font-gotham-book;

        font-size: rem-calc(16);
        line-height: 1;
        margin-bottom: rem-calc(8);
    }

    .item-price {
        font-size: rem-calc(13);
        font-weight: normal;

        @include breakpoint(medium up) {
            text-align: right;
        }
    }
}

// Service more-info modal
// Service check modal (when uncheck an recommended service)
// common styles
.reveal[id*="servicepopup"],
#serviceinfomodal {
    padding: 1.25rem;

    @include breakpoint(medium up) {
        padding: 1.875rem;
    }

    .close-button {
        color: map-get($foundation-palette, primary-accessible);
        right: 1.125rem;
        top: 0.9375rem;

        @include breakpoint(medium up) {
            right: 1.75rem;
            top: 1.438rem;
        }
    }
}

// Service more-info modal
.service-modal-content {
    .service-name {
        @include font-gotham-bold;

        font-size: rem-calc(21);
        line-height: 1.2;
        margin: 0 0 1.25rem;
        width: 90%;

        @include breakpoint(medium up) {
            font-size: rem-calc(16);
            margin: 0 0 1.875rem;
        }
    }

    .service-image {
        margin: 0 0 1.25rem;
        text-align: center;

        @include breakpoint(medium up) {
            float: left;
            margin: 0;
            width: 35%;
        }
    }

    .long-description {
        font-size: 14px;
        line-height: 1.4;
        margin: 0;

        @include breakpoint(medium up) {
            float: right;
            padding: 0 0 0 1.875rem;
            width: 65%;
        }
    }
}

// Verify Vehicle modal
.cart-verify-vehicle-modal {
    padding: 1.25rem;

    @include breakpoint(medium up) {
        padding: 1.875rem;
    }
}

// Verify Vehicle modal
// Service check modal (when uncheck an recommended service)
// common styles
.cart-verify-vehicle-modal,
#serviceinfomodal {
    .title {
        @include font-gotham-bold;

        font-size: rem-calc(21);
        line-height: 1.2;
        margin: 0 0 1.25rem;
        width: 90%;

        @include breakpoint(medium up) {
            font-size: rem-calc(18);
            margin: 0 0 1.875rem;;
        }
    }

    .description {
        p {
            font-size: rem-calc(14);
            line-height: 1.4;
        }
    }

    .buttons-box {
        @include breakpoint(small only) {
            text-align: center;
        }

        @include breakpoint(medium up) {
            align-items: center;
            display: flex;
        }

        .button {
            margin: 0 0 1.563rem;
            width: 100%;

            @include breakpoint(medium up) {
                margin: 0 1.25rem 0 0;
                width: auto;
            }
        }

        a {
            @include font-gotham-bold;

            font-size: rem-calc(11);
        }
    }
}

// Hide Live Chat button
#inside_holder #inside_liveChatTab {
    display: none !important;
}

// YMMo check modal
.cart-psmodal-title {
    @include font-gotham-bold;

    font-size: rem-calc(21);
    line-height: 1.2;
    margin: 20px 0 0 30px;
    width: 85%;

    @include breakpoint(medium up) {
        font-size: rem-calc(18);
    }
}

.totals-sticky-button {
    align-items: center;
    background: map-get($foundation-palette, primary);
    bottom: 0;
    box-shadow: 0 -4px 10px map-get($foundation-palette, disabled-grey);
    display: flex;
    font-size: rem-calc(13);
    justify-content: center;
    padding: rem-calc(10 8);
    position: sticky;
    z-index: 10;

    .price-total {
        background: map-get($foundation-palette, white-default);
        border-radius: 3px;
        padding: rem-calc(5);
    }

    .price-total-text {
        padding-left: rem-calc(4);
    }
}

#psmodal.cart-psmodal {
    .close-button {
        right: 25px;
    }
}

#cartQuantityModal {
    border: 0;
    max-width: 380px;
    padding: 0;

    h2 {
        @include font-gotham-medium;

        background: map-get($foundation-palette, primary-accessible);
        color: map-get($foundation-palette, white-default);
        font-size: rem-calc(13);
        margin-bottom: 10px;
        padding: 9px 15px;
        text-transform: uppercase;
    }

    p {
        font-size: rem-calc(12);
        padding: 0 15px;
    }

    .cartQuantityModalCta {
        color: map-get($foundation-palette, primary-accessible);
        font-size: rem-calc(12);
        padding: 0 15px 15px;
        text-align: right;
    }
}

// Why Choose Kal Tire modal 
#why-choose-kaltire-modal {
    padding: rem-calc(20);

    @include breakpoint(small down) {
        height: fit-content;
        margin: 0 auto;
        max-height: 100%;
        max-width: 90%;
        min-height: unset;
    }

    &.reveal {
        left: 50%;
        position: fixed;
        top: 50% !important;
        transform: translate(-50%, -50%);

        @include breakpoint(medium up) {
            max-width: 690px;
            width: 100%;
        }
    }

    .modal-title {
        padding-bottom: rem-calc(20);
    }

    p {
        font-size: rem-calc(14);
    }

    .close-button {
        color: map-get($foundation-palette, primary-accessible);
        top: rem-calc(12);
    }
}

// Contact Us/ Live Chat section
.contat-us-footer {
    margin-top: rem-calc(80);

    @include breakpoint(small down) {
        margin-top: rem-calc(30);
    }

    .here-to-help {
        background: map-get($foundation-palette, light-gray);
        border: 3px solid $dark-gray-over;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: rem-calc(0 25);
    }

    .cart-footer-image {
        text-align: center;

        img {
            height: 100%;
            max-height: rem-calc(300);
        }
    }

    .contact-us-content {
        padding-top: rem-calc(30);

        @include breakpoint(small down) {
            padding: rem-calc(20 0);
        }
    }

    .section-subtitle {
        font-size: rem-calc(17);
    }

    .contact-info-section {
        margin: 0;
        
        @include breakpoint(medium up) {
            padding-top: rem-calc(15);
        }
    }

    .action-box-content {
        p {
            line-height: 1.8;
            margin: 0;
        }
    }

    .section-title-box {

        @include breakpoint(small down) {
            padding: rem-calc(5);
        }

        h3 {
            font-size: rem-calc(24);
        }
    }

    .phone-number {
        @include font-gotham-bold;

        color: map-get($foundation-palette, primary-accessible);
        padding-top: rem-calc(10);
    }

    .orderSummaryLiveChat {
        @include font-gotham-bold;

        color: map-get($foundation-palette, secondary);
        margin-top: rem-calc(20);

        @include breakpoint(small down) {
            width: 100%;
        }
    }

    .action-icon {
        .card-icon {
            max-height: rem-calc(16);
        }
    }

    .main-section-title {
        font-size: rem-calc(18);
        min-height: rem-calc(22);
    }

    .card-icon {
        display: inline-block;
        line-height: 1.2;
        margin-right: rem-calc(8);
        width: rem-calc(16);
    }
}
