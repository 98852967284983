// This file contains the styles for the box with cart details from Checkout, Summary and Order Details(My Account) pages
.cart-summary-box {
    .section-header {
        @include font-gotham-medium;

        font-size: rem-calc(15);
        line-height: 1;
        margin: 0 0 10px;

        &.qty-header {
            text-align: center;
        }

        &.total-price-header {
            text-align: right;
        }
    }

    .line-items {
        @include breakpoint(medium up) {
            padding: 1.5625rem 1.875rem 0;
        }
    }

    .line-item {
        margin-bottom: 1.25rem;

        @include breakpoint(medium up) {
            margin-bottom: 1.5625rem;
        }
    }

    .line-item-details {
        line-height: 1;
        margin: 0 0 10px;

        @include breakpoint(medium up) {
            margin: 0;
        }
    }

    .product-brand {
        @include font-gotham-bold;
        display: block;

        font-size: rem-calc(13);
        line-height: 1;
    }

    .product-name {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1;

        @include breakpoint(large up) {
            font-size: rem-calc(18);
        }
    }

    .line-item-price {
        @include font-gotham-book;

        .old-price {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: rem-calc(12);
            text-decoration: line-through;
        }
    }

    .line-item-quantity {
        text-align: center;
    }

    .item-quantity,
    .price-total {
        @include font-gotham-book;

        font-size: rem-calc(15);
    }

    .line-item-total {
        text-align: right;
    }

    .main-section-header {
        border-bottom: 1px solid #f2f2f3;
        padding: 1.5625rem 1.875rem;

        .section-header {
            margin: 0;
        }
    }

    // Product Set
    .front-back-set-label {
        @include font-gotham-bold;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(11);
        text-transform: uppercase;
    }

    .first-pset {
        margin-bottom: 0.9375rem;
    }

    // Rebate section
    .rebate-badge {
        @include font-gotham-bold;
        align-items: center;
        color: map-get($foundation-palette, primary);
        display: flex;

        font-size: rem-calc(14);

        @include breakpoint(medium up) {
            justify-content: flex-start;
        }

        @include breakpoint(medium only) {
            margin: 0.3125rem 0 0;
        }

        &::before {
            background: url("../images/icons/rebate_icon.png") no-repeat;
            content: "";
            height: 25px;
            margin: 0 0.25rem 0 0;
            width: 25px;
        }
    }

    .rebate-text {
        @include font-gotham-book;
        color: map-get($foundation-palette, midgrey);
        display: none;

        font-size: rem-calc(14);
        font-style: italic;
    }
}

// Aditional Services
.service-row {
    .product-name {
        @include font-gotham-book;

        font-size: rem-calc(14);
    }
}

// Aditional custom styles for Order Details(My Account) page
.orderdetails {
    .cart-summary-box {
        border: 2px solid #f2f2f3;
        margin: 0 0 20px;
        padding: 1rem 1.25rem;

        @include breakpoint(medium up) {
            border-bottom-width: 1px;
            margin: 0;
            padding: 0;
        }
    }

    .rebate-box {
        display: none;
    }
}

// Aditional custom styles for Checkout pages
.checkout-mini-cart {
    border: 1px solid $medium-gray;
    padding: 30px 20px;

    .section-title {
        margin-bottom: 20px;
    }
}

@include breakpoint(large up) {
    .checkout-mini-cart {
        padding: 0;

        .section-title {
            margin: 1.25rem 0 0 1.25rem;
        }

        .main-section-header {
            display: none;
        }

        .cart-summary-box {
            border: 0;
            margin: 0;

            .line-items {
                padding: 0;
            }

            .line-item {
                border-bottom: 1px solid #f2f2f3;
                margin: 0;
                padding: 1.25rem;

                > div {
                    flex: 0 0 100%;
                    margin: 0;
                    max-width: 100%;
                    padding: 0;
                }

                &.first-pset {
                    border-bottom: 0;
                    padding-bottom: 0.3125rem;
                }

                &.second-pset {
                    padding-top: 0.3125rem;
                }
            }

            .line-item-details {
                margin: 0 0 3px;
            }

            .product-brand {
                display: inline-block;
            }

            .product-brand,
            .product-name {
                font-size: rem-calc(14);
            }

            .price-total {
                @include font-gotham-bold;
            }
        }

        .line-item-quantity {
            .section-header {
                @include font-gotham-book;

                display: inline-block !important;

                &::after {
                    content: ":";
                }
            }
        }

        .service-row {
            border: 0;

            .product-name {
                @include font-gotham-bold;
            }
        }
    }
}


// Aditional custom styles for Step 4 - Order Review
.order-review-page {
    .cart-summary-box {
        @include breakpoint(small only) {
            margin: 0 0 35px;
            padding: 0 0.625rem;
        }

        @include breakpoint(medium up) {
            border: 2px solid #f2f2f3;
        }
    }

    .appointment-data-time {
        p {
            line-height: 22px;
            margin-bottom: 10px;
        }
    }

    .app-info-wrapper {
        p {
            display: inline;
            line-height: 22px;
        }
    }

    .location-box {
        .section-title,
        p, {
            line-height: 22px;
            margin: 0;
        }
    }

    .section-title-box {
        @include breakpoint(medium up) {
            padding: 50px 0 0;
        }
    }

    .section-box-wrap {
        @include breakpoint(medium up) {
            margin-bottom: 50px;
        }

        .edit-section-link {
            display: block;
            font-size: rem-calc(13);
            line-height: 13px;
            padding-top: 10px;
        }
    }

    .mini-address-location {
        display: flex;
        flex-wrap: wrap;

        p {
            flex: 0 0 100%;
        }

        .full-name {
            order: 0;
        }

        .email {
            order: 1;
        }

        .phone-number {
            order: 2;
        }

        .postal-code {
            display: none;
        }
    }

    .disclaimer-checkout {
        margin-bottom: 15px;

        @include breakpoint(medium up) {
            margin-bottom: 0;
        }
    }
}

// Aditional custom styles for rebate message - Order Review & Confirmation page
.order-review-page,
.confirmation-page {
    .rebate-text {
        display: block;
    }

    .rebate-box {
        float: right;
        text-align: right;
        width: 80%;

        @include breakpoint(medium up) {
            width: 25%;
        }

        @include breakpoint(large up) {
            width: 27%;
        }
    }

    .rebate-badge {
        justify-content: flex-end;
    }
}
