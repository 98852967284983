.store-details-page {
    padding: rem-calc(0 20 20);

    @include breakpoint(small only) {
        .map-container {
            margin: 40px 0 100px;
        }
    }

    @include breakpoint(medium up) {
        .row-separator {
            margin-bottom: 25px;
        }

        .store-hours {
            margin-bottom: 0;
        }

        .plus-minus-accordion .menu.nested {
            margin: 0;
        }

        .section-title {
            @include font-gotham-bold;
            border-left: 3px solid map-get($foundation-palette, primary);
            color: #000;
            cursor: default;

            font-size: rem-calc(24);
            margin: 0 0 35px;
            padding: 0 0 0 20px;
            text-transform: uppercase;
        }
    }

    @include breakpoint(small only) {
        .map-container {
            margin: 40px 0 100px;
        }

        #map,
        #streetview {
            min-height: 250px;
        }
    }

    @include breakpoint(medium up) {
        .row-separator {
            margin-bottom: 25px;
        }

        .store-hours {
            margin-bottom: 0;
        }

        .plus-minus-accordion .menu.nested {
            margin: 0;
        }

        .section-title {
            @include font-gotham-bold;
            border-left: 3px solid map-get($foundation-palette, primary);
            color: #000;
            cursor: default;

            font-size: rem-calc(24);
            margin: 0 0 35px;
            padding: 0 0 0 20px;
            text-transform: uppercase;
        }
    }

    ul {
        margin: 0;
    }

    li {
        @include font-gotham-book;

        font-size: rem-calc(14);
        line-height: 1.4;
        margin-bottom: 0;

        &.store-address {
            line-height: 1.7;
        }
    }

    .close-button {
        border: 2px solid map-get($foundation-palette, primary);
        border-radius: 50%;
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(24);
        height: 30px;
        top: 16px;
        width: 30px;
    }

    .store-name {
        @include font-gotham-bold;

        font-size: rem-calc(20);
        padding-top: rem-calc(20);

        .nickname {
            @include font-gotham-book;
            display: block;
        }
    }

    .sa_s28_display_avg_rating {
        margin: 0 0 15px;
    }

    .link {
        @include font-gotham-bold;


        @include breakpoint(medium up) {
            font-size: rem-calc(13);
        }
    }

    .store-contact-info {
        margin: rem-calc(20 0);

        p {
            margin: 0;
        }
    }

    .store-hours {
        @include font-gotham-book;
        font-size: rem-calc(14);
        line-height: 1.4;
    }

    .bold-text {
        @include font-gotham-bold;
    }

    .about-location {
        margin-bottom: 20px;
        margin-top: 10px;

        @include breakpoint(medium up) {
            margin-bottom: 0;
        }
    }

    .store-amenities {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include breakpoint(small down) {
            flex-direction: column;
        }

        i {
            font-size: rem-calc(18);
            margin-right: rem-calc(5);

            &::before {
                margin: 0;
            }
        }
    }

    .offered-service-name {
        font-size: rem-calc(16);
        line-height: 1.5;
        list-style: disc;
    }

    .is-accordion-submenu-parent {
        a::after {
            color: map-get($foundation-palette, secondary);
            content: "\e80f";
            font-family: "fontello", sans-serif;
            font-size: rem-calc(14);
            position: absolute;
            right: 20px;
            transition: transform 0.3s ease;
        }

        &[aria-expanded="true"] a::after {
            content: "\e80f";
            transform: rotate(180deg);
        }

        .store-services-content {
            margin: rem-calc(0 0 40 40);
        }

        .service-title {
            span {
                display: inline-block;
                padding: rem-calc(0 35 0 20);
                text-transform: capitalize;
            }
        }
    }

    .store-services-list {
        column-count: 1;

        @include breakpoint(medium up) {
            column-count: 3;
        }

        @include breakpoint(large up) {
            column-count: 4;
        }
    }

    .map-container {
        ul li {
            @include font-gotham-bold;

            background-color: $dark-gray;
            display: inline-block;
            margin-right: 2px;
            min-width: 110px;
            text-align: center;

            &.ui-state-active {
                background-color: map-get($foundation-palette, primary);
            }

            a.ui-tabs-anchor {
                color: map-get($foundation-palette, white-default);
                display: block;
                font-size: rem-calc(18);
                padding: 0 10px;
            }
        }
    }

    .title-main-section {
        @include font-gotham-bold;
        border-left: 3px solid map-get($foundation-palette, primary);

        font-size: rem-calc(16);
        margin: 0 0 25px;
        padding: 0 0 0 18px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: rem-calc(24);
        }

        &.review-title {
            margin: rem-calc(30 0 20);
        }
    }
}

.store-location-info {
    display: flex;
    justify-content: space-between;

    @include breakpoint(small down) {
        flex-direction: column-reverse;
    }

    .store-distance {
        font-size: rem-calc(12);
    }
}

.store-locator-page-wrapper {
    position: relative;
}

.store-details-sidebar {
    background-color: map-get($foundation-palette, white-default);
    height: calc(100% - 320px);
    left: -100%;
    margin-top: 320px;
    overflow: hidden;
    position: absolute;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 2;

    @include breakpoint(medium up) {
        height: 100%;
        left: -40%;
        margin-top: 0;
        opacity: 0;
        top: 0;
        transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;
        width: 40%;
    }

    &.open {
        left: 0;
        opacity: 1;
        overflow: visible;

        @include breakpoint(medium up) {
            overflow: auto;
        }
    }
}

.map-container {
    position: relative;

    @include breakpoint(small only) {
        .gmnoprint,
        .gm-control-active,
        .gm-iv-address,
        .gps-toggle {
            display: none;
        }
    }
}

.store-details-mode {
    .switch-to-street-view,
    .switch-to-map-view {
        margin: rem-calc(5 0 0);
        width: 100%;

        @include breakpoint(small down) {
            font-size: rem-calc(9);
            padding: rem-calc(5);
        }
    }

    .street-view-wrapper {
        height: 100%;
        width: 100%;
    
        #streetview {
            height: 100%;
        }
    }

    .is-small-view {
        background: map-get($foundation-palette, white-default);
        bottom: 25px;
        height: 250px;
        left: 20px;
        padding: rem-calc(15 15 60);
        position: absolute;
        width: 200px;
        z-index: 2;

        @include breakpoint(small down) {
            height: 140px;
            padding: rem-calc(10 10 35);
            width: 110px;
        }

        .map-view,
        .street-view {
            pointer-events: none;

            .gmnoprint,
            .gm-control-active,
            .gm-iv-address,
            .gps-toggle {
                display: none;
            }
        }

        #streetview {
            height: 100%;
        }
    }
}

.map-accordion {
    bottom: 20px;
    position: absolute;
}

.cta-buttons {
    .link {
        display: inline;
        margin: 0;
        padding-right: rem-calc(15);
    }
}

.icon-angle-left::before {
    color: map-get($foundation-palette, primary);
    font-size: rem-calc(20);
    margin: 0;
    width: auto;
}

.accessibility-table {
    border-spacing: 1px;

    td {
        border-right: 1px solid $light-gray;

        &:last-child {
            border-right: 0;
        }
    }
}
