.noresults-page {
    p,
    a {
        font-size: rem-calc(14);
    }

    .search-phrase {
        font-size: rem-calc(18);
        margin: 0 0 15px;
    }

    .noresults-message {
        @include font-gotham-book;

        font-size: rem-calc(14);
        margin: 0 0 30px;

        p {
            font-size: rem-calc(18);
        }
    }

    .section-title {
        font-size: rem-calc(18);
        margin: 0 0 15px;
    }

    .new-search,
    .related-search,
    .resource-center {
        margin: 0 0 30px;
    }

    .new-search {
        input {
            height: 45px;
            margin: 0;
        }

        .button {
            margin: 0 0 0 7px;

            @include breakpoint(small only) {
                height: 45px;
                padding: 0 10px;
            }
        }

        .icon-search {
            font-size: rem-calc(17);
        }
    }

    .related-search {
        .refinement-header {
            font-size: rem-calc(18);
            margin: 0 0 15px;
        }

        ul {
            margin: 0;
        }

        li {
            @include font-gotham-bold;

            margin: 0 0 15px;
        }
    }

    .customer-service {
        @include breakpoint(small only) {
            border-top: 2px solid $medium-gray;
            padding: 30px 0 0;
        }

        .box {
            margin: 0 0 20px;
        }

        h4 {
            @include font-gotham-medium;

            font-size: rem-calc(16);
            margin: 0;

            span {
                padding: 0 0 0 8px;
            }
        }

        a {
            @include font-gotham-book;

            font-size: rem-calc(14);
        }

        p,
        a {
            margin: 0 0 0 30px;
        }
    }

    .noresults-selector {
        margin-top: 3.125rem;

        p {
            line-height: 1.6875rem;
        }

        .button.hollow {
            color: inherit;
        }
    }
}

@include breakpoint(medium up) {
    .noresults-page {
        .noresults-message {
            background: map-get($foundation-palette, darkgrey1);
            color: map-get($foundation-palette, white-default);
            margin: 0 0 45px;
            padding: 45px 0;
            text-align: center;
        }

        .medium-centered {
            left: 50%;
            position: relative;
            transform: translateX(-50%);
        }

        .noresults-selector {
            background-image: url("../images/kaltire/question-header-image@2x.png");
            background-position: 550px center;
            background-repeat: no-repeat;
            background-size: 600px;
            margin-top: 3.125rem;
        }
    }
}

@include breakpoint(large up) {
    .noresults-page {
        .noresults-selector {
            background-position: 150% center;
        }
    }
}
