#carfax-history-modal {
    font-size: rem-calc(12);
    max-width: 816px;
    padding: rem-calc(20);
    top: 16px;
    width: 100%;

    @include breakpoint(small only) {
        position: sticky;
    }

    .modal-content {
        max-height: 78vh;

        @include breakpoint(small only) {
            max-height: 78vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
            max-height: calc(var(dynamicScreenSize, 1vh) * 100);
            overflow-y: scroll;
            padding-bottom: 20px;
        }
    }

    .modal-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(20);
    }

    .modal-text {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }

    .carfax-history-header {
        margin-bottom: rem-calc(20);

        @include breakpoint(small only) {
            margin-top: 18px;
        }
    }

    .license-plate-wrapper {
        background-color: map-get($foundation-palette, light-gray);
        padding: 12px 16px;

        .license-plate-header {
            font-size: rem-calc(18);
            margin-bottom: rem-calc(20);
        }

        .license-plate-content {
            column-gap: rem-calc(20);
            display: flex;
            justify-content: space-between;

            @include breakpoint(small only) {
                flex-direction: column;
            }

            .column {
                flex: 1;
                margin-bottom: 0;
                padding: 0;
            }

            .license-plate-inner {
                margin-bottom: 20px;

                .plate-number-input {
                    margin-top: 8px;
                }

                .error:not(.licensePlate) > * {
                    max-width: 232px;
                }
            }

            .form-row {
                margin-bottom: 0;
            }
        }

        .error-vin {
            background-color: map-get($foundation-palette, unavailable);
            color: map-get($foundation-palette, error);
            display: none;
            padding: 6px;
        }
    }

    .input-custom {
        padding-left: 2px;
    }

    .or-break {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        padding: 0 10px;
        text-transform: uppercase;
    }

    .or-break::before,
    .or-break::after {
        border-bottom: 2px solid map-get($foundation-palette, light-gray);
        content: "";
        flex: 1 1;
        margin: auto;
    }

    .or-break::before {
        margin-right: 10px;
    }

    .or-break::after {
        margin-left: 10px;
    }

    .vin-wrapper {
        background-color: map-get($foundation-palette, light-gray);
        padding: 12px 16px;

        .vin-header {
            font-size: rem-calc(18);
            margin-bottom: rem-calc(20);
        }

        .vin-number-input {
            margin-top: 6px;
        }
    }

    .vehicle-information {
        font-size: rem-calc(14);
        line-height: rem-calc(16);
        margin-bottom: 10px;
    }
}

#carfax-delete-modal {
    @include font-gotham-bold;
    max-width: rem-calc(500);
    padding: 20px;

    width: 100%;

    .modal-title {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(22);
    }

    .modal-text {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }

    .buttons-wrap {
        column-gap: 60px;
        display: flex;
        justify-content: space-between;
        margin-top: rem-calc(20);

        button {
            flex: 50%;
        }
    }
}

#carfax-history-modal,
#carfax-delete-modal {
    .carfax-modal-close {
        cursor: pointer;
        font-size: 32px;
        line-height: 1;
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        z-index: 5;
    }

    button {
        align-items: center;
        display: inline-flex;
        font-size: 12px;
        height: 42px;
        justify-content: center;
    }
}

#carfax-delete-form,
#vehicle-fieldset {
    min-height: 42px;
    position: relative;
}

.grey-line {
    border-bottom: 2px solid map-get($foundation-palette, light-gray);
    content: "";
    margin: 12px 0;
}

.find-vin-wrapper {
    .item-content {
        display: flex;

        ul {
            list-style-type: disc;
        }
    }
}

.modal-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .button {
        width: 200px;
    }
}

.service-unavailable-section {
    background-color: map-get($foundation-palette, light-gray);
    margin: 8px 0 24px;
    padding: 12px 16px;

    .section-title {
        display: none;
        font-size: rem-calc(18);
        margin-bottom: rem-calc(18);
    }

    .unavailable-text {
        background-color: map-get($foundation-palette, unavailable);
        color: map-get($foundation-palette, error);
        padding: 6px;
    }
}

.vehicle-data-section {
    background-color: map-get($foundation-palette, light-gray);
    margin: 0;
    padding: 20px;

    .section-title {
        font-size: rem-calc(18);
        margin-bottom: 20px;
    }

    .checkbox-form {
        margin-bottom: 0;
    }

    .vehicle-data-content {
        .save-vehicle-container {
            margin-top: 9px;

            .save-vehicle-checkbox {
                transform: scale(1.5);
            }
        }
    }
}

.modal-buttons {
    .carflex-logo {
        height: 50px;
        width: 160px;
    }

    #carfax-search-button {
        background-color: map-get($foundation-palette, white-default);
        border: 3px solid map-get($foundation-palette, darkgrey1);
        height: 45px;
        margin-left: auto;
        max-width: 150px;
        padding: 0;
    }

    #carfax-service-button {
        height: 45px;
        margin-left: 14px;
        max-width: 235px;
        padding: 0;
    }
}

.no-service-history {
    padding: 20px;

    .section-title {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(20);
    }

    p {
        margin-bottom: rem-calc(20);
    }

    .no-service-button {
        margin-bottom: 0;
    }
}
