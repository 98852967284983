.self-service-entry-point-title {
    @include font-gotham-bold;

    font-size: rem-calc(21);
    margin: rem-calc(30 0 20);
    text-transform: uppercase;

    @include breakpoint(medium up) {
        font-size: rem-calc(26);
        margin: rem-calc(50 0);
    }
}

.self-service-entry-point-order {
    position: relative;

    // Remove input's arrows/spinners box
    input[type="number"] {
        appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }
}

.self-service-entry-point-wrapper {
    margin-bottom: rem-calc(100);
    padding: rem-calc(5 0);

    @include breakpoint(medium up) {
        border: rem-calc(5) solid rgba(map-get($foundation-palette, light-gray), 0.8);
        display: flex;
        padding: rem-calc(80 0 40);
    }

    .has-tip-custom {
        border: 0;
        margin-left: rem-calc(7);
        position: absolute;
        top: rem-calc(-2);
    }

    .questionmarkcircle {
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        height: rem-calc(18);
        margin-right: 0;
        width: rem-calc(18);
    }
}

.find-my-appointment {
    margin-left: rem-calc(15);

    @include breakpoint(medium up) {
        margin-left: rem-calc(10);
    }
}

.season-tire-popup {
    width: 600px !important;

    .dialog-content {
        padding: 0;
    }

    .season-tire-popup-title {
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        padding: rem-calc(25 60 25 25);
    }

    .ui-dialog-titlebar-close {
        .ui-button-icon-primary {
            &::after {
                color: map-get($foundation-palette, white-default);
                font-size: rem-calc(20);
            }

            &:hover {
                &::after {
                    color: map-get($foundation-palette, white-default);
                }
            }
        }
    }

    .season-tire-popup-wrapper {
        display: flex;
        flex-flow: column-reverse;
        padding: rem-calc(25 60 25 10);

        @include breakpoint(medium up) {
            flex-flow: row;
            padding: rem-calc(25 60 25 15);
        }
    }

    .confirm-cancelation-wrapper {
        padding: rem-calc(25 10);

        @include breakpoint(medium up) {
            padding: rem-calc(25 15);
        }

        .button {
            width: 100%;

            @include breakpoint(medium up) {
                width: inherit;
            }

            &:focus {
                background: map-get($foundation-palette, primary);
            }
        }

        .keep-appointment-button {
            background: map-get($foundation-palette, white-default);
            border: rem-calc(3) solid map-get($foundation-palette, primary);
            color: $black;
            margin-left: 0;
            padding: rem-calc(13 15);

            @include breakpoint(medium up) {
                margin-left: rem-calc(10);
            }
        }
    }

    .apifailed-popup-wrapper {
        padding: rem-calc(25 10);

        @include breakpoint(medium up) {
            padding: rem-calc(25 15);
        }
    }

    .button[disabled] {
        background: $grey-disabled;
        opacity: 1;
    }

    .input-custom,
    input[type="radio"] {
        border: 1px solid map-get($foundation-palette, very-light-grey);
        height: rem-calc(18);
        width: rem-calc(18);
    }

    input[type="radio"] {
        &:checked + .input-custom {
            background: map-get($foundation-palette, white-default);
            border: rem-calc(4) solid map-get($foundation-palette, primary);
        }
    }

    img {
        margin-bottom: rem-calc(30);
        max-width: rem-calc(120);

        @include breakpoint(medium up) {
            float: right;
        }
    }
}

.cancel-appointment-survey-wrapper {
    .field-wrapper {
        @include font-gotham-book;

        font-size: rem-calc(15);
    }

    .radio-form {
        margin-bottom: rem-calc(10);
    }

    .input-custom,
    input[type="radio"] {
        border: 1px solid map-get($foundation-palette, very-light-grey);
        height: rem-calc(18);
        width: rem-calc(18);
    }

    input[type="radio"] {
        &:checked + .input-custom {
            background: map-get($foundation-palette, white-default);
            border: rem-calc(4) solid map-get($foundation-palette, primary);
        }
    }

    .cancel-appointment-why {
        margin-bottom: rem-calc(15);
    }

    .cancel-appointment-survey-submit {
        margin-top: rem-calc(30);
        padding: rem-calc(15 50);
    }

    .button[disabled] {
        background: $grey-disabled;
        opacity: 1;
    }
}

.tooltip {
    .season-tire-popup-order {
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        padding: rem-calc(15);
    }
}

.appointment-reschedule-message-box {
    background-color: map-get($foundation-palette, light-gray);
    margin: rem-calc(0 10 20);
    padding: rem-calc(30 30 30 60);
    position: relative;

    @include breakpoint(medium up) {
        padding: rem-calc(30 60);
    }

    .fa-check-circle {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(20);
        left: rem-calc(30);
        position: absolute;
    }

    .fa-check {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(11);
        left: rem-calc(30);
        margin-top: rem-calc(5);
        position: absolute;

        &::before {
            border: 3px solid map-get($foundation-palette, primary);
            border-radius: 50%;
            padding: rem-calc(3);
        }
    }
}

.appointment-reschedule-message-box-keep {
    background-color: map-get($foundation-palette, light-gray);
    margin: rem-calc(0 10 20);
    padding: rem-calc(30 20);

    @include breakpoint(medium up) {
        padding: rem-calc(30);
    }
}

.appointment-finder-error-wrapper {
    background-color: map-get($foundation-palette, light-gray);
    margin: rem-calc(0 10 20);
    padding: rem-calc(30 30 30 60);
    position: relative;

    @include breakpoint(medium up) {
        padding: rem-calc(30 60);
    }

    .fa-exclamation-triangle,
    .fa-check {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(20);
        left: rem-calc(30);
        position: absolute;
    }

    .fa-check {
        font-size: rem-calc(11);
        margin-top: rem-calc(5);

        &::before {
            border: 3px solid map-get($foundation-palette, primary);
            border-radius: 50%;
            padding: rem-calc(3);
        }
    }

    .contact-us,
    .talk-member {
        font-weight: bold;
    }

    .talk-member {
        margin: 0;
    }

    .phone-number {
        color: map-get($foundation-palette, midgrey);
    }
}

.cancel-appointment-success-wrapper {
    background-color: map-get($foundation-palette, light-gray);
    margin: rem-calc(25 0 35);
    padding: rem-calc(30 30 30 60);
    position: relative;

    @include breakpoint(medium up) {
        padding: rem-calc(30 60);
    }

    .fa-check {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(11);
        left: rem-calc(30);
        margin-top: rem-calc(5);
        position: absolute;

        &::before {
            border: 3px solid map-get($foundation-palette, primary);
            border-radius: 50%;
            padding: rem-calc(3);
        }
    }
}

.appointment-dashboard-title {
    @include font-gotham-bold;

    font-size: rem-calc(21);
    margin: rem-calc(30 0 20);
    text-transform: uppercase;
    white-space: nowrap;

    @include breakpoint(medium up) {
        font-size: rem-calc(26);
        margin: rem-calc(50 0 20);
    }

    &::before {
        color: map-get($foundation-palette, primary);
        content: "|";
        margin-right: rem-calc(8);
    }
}

.appointment-dashboard-order-summary {
    margin: rem-calc(20 0);
}

.appointment-dashboard-starttime {
    color: map-get($foundation-palette, primary);
    margin-bottom: rem-calc(20);
}

.appointment-dashboard-starttime-day {
    text-transform: uppercase;
}

.appointment-dashboard-location-confirm {
    margin-bottom: rem-calc(5);
}

.appointment-dashboard-location-confirm-province {
    margin-bottom: rem-calc(40);
}

.appointment-dashboard-location {
    margin-top: rem-calc(10);
    white-space: pre-line;
}

.appointment-dashboard-location-distance {
    color: map-get($foundation-palette, midgrey);
    font-size: rem-calc(12);
    margin-bottom: 0;
    padding: rem-calc(5) 0 rem-calc(10);
}

.appointment-dashboard-space {
    margin: rem-calc(10 0 20);
}

.appointment-dashboard-reschedule {
    margin-top: rem-calc(20);
}

.appointment-dashboard-cancel {
    color: map-get($foundation-palette, primary);
    display: block;
    line-height: inherit;
    margin-bottom: rem-calc(100);
    vertical-align: text-top;

    @include breakpoint(medium up) {
        display: inline-block;
        margin-left: rem-calc(15);
    }
}

.appointment-calendar-select-new-date {
    font-size: rem-calc(18);
    margin-bottom: 0;
    margin-top: rem-calc(30);
}

.appointment-dashboard-location-calendar-name {
    font-size: rem-calc(16);
}

.appointment-dashboard-location-calendar-address {
    font-size: rem-calc(14);
    margin-bottom: 0;
    margin-top: rem-calc(10);
}

.appointment-calendar-drop {
    color: map-get($foundation-palette, primary);
    display: block;
    font-size: rem-calc(14);

    &:hover {
        color: #db5a00;
    }
}

.store-details-box-wrapper {
    margin-top: rem-calc(40);

    .store-details-box {
        margin-top: 0;

        .more-info-title {
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(13);
        }
    }
}

.appointment-calendar-plain-text {
    margin-bottom: rem-calc(20);
}

.appointment-calendar-button-wrapper {
    .button[disabled] {
        background: $grey-disabled;
        opacity: 1;
    }

    .button {
        width: 100%;

        @include breakpoint(medium up) {
            width: inherit;
        }
    }

    .appointment-calendar-keep {
        background: map-get($foundation-palette, white-default);
        border: rem-calc(3) solid map-get($foundation-palette, primary);
        color: $black;
        margin-left: 0;
        padding: rem-calc(11 15);

        @include breakpoint(medium up) {
            margin-left: rem-calc(10);
        }
    }
}

.appointment-active {
    text-transform: uppercase;
}

.appointment-date {
    text-transform: uppercase;
}

.appointment-dashboard-date {
    flex: 0 0 100%;
    margin-left: rem-calc(10);
    padding: 0;
    text-transform: none;
}

.reveal.appointment-calendar-modal {
    height: auto;
    min-height: auto;

    .appointment-calendar-keep {
        margin-top: rem-calc(20);

        @include breakpoint(medium up) {
            margin-top: 0;
        }
    }
}

.appointment-finder-error-block {
    .appointment-finder-error-wrapper {
        margin: 0;
    }
}

@media print {
    .information-box {
        h3 {
            width: 100%;
        }
    }
}
