
.responsive-embed,
.flex-video {
    height: 0;
    margin-bottom: 1rem;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.responsive-embed iframe {
    border: 0;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
    padding-bottom: 56.25%;
}
