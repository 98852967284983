$grid-border: 1px solid map-get($foundation-palette, border-grey);

.sel-info.left-arrow {
    min-height: 126px;
}

.brakes-details {
    justify-content: space-between;
    margin: 50px auto;

    h2 {
        margin-bottom: 20px;
        text-transform: capitalize;
    }

    .brakes-details-wrapper {
        @include breakpoint(medium down) {
            padding: 0;
        }
    }

    .brakes-grid-wrapper {
        background: map-get($foundation-palette, light-gray);
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px 15px;

        h2 {
            min-width: 290px;

            @include breakpoint(small only) {
                font-size: 18px;
            }
        }

        .grid-maker {
            font-weight: bold;

            span {
                padding-left: 2px;
            }
        }

        .brakes-grid {
            background-color: map-get($foundation-palette, white-default);
            border-right: $grid-border;
            border-top: $grid-border;
            display: grid;
            grid-template-columns: repeat(3, minmax(50px, 1fr));
            margin-bottom: 15px;

            .grid-item {
                align-items: center;
                border-bottom: $grid-border;
                border-left: $grid-border;
                display: flex;
                font-size: 16px;
                font-weight: 400;
                padding: 10px;

                @include breakpoint(medium down) {
                    font-size: 14px;
                }
            }

            .grid-header,
            .grid-vehicle-type {
                font-weight: bold;
            }
        }

        .brakes-disclaimer {
            margin-bottom: 15px;
            width: 90%;

            p {
                font-size: 12px;
                margin-block-end: 0;
            }
        }

        .brakes-no-results {
            background-color: map-get($foundation-palette, white-default);
            margin: 20px 0;
            padding: 10px;

            p {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.5rem;
                margin-block-end: 0;
                padding: 15px;
            }
        }

        .brakes-included {
            margin-top: 10px;

            .included-block {
                display: flex;
            }

            p {
                font-size: 16px;
                font-weight: bold;
                margin-block-end: 5px;
            }

            ul {
                font-size: 14px;
                font-weight: 400;
                margin-left: 0;

                i {
                    color: map-get($foundation-palette, primary-accessible);
                    margin-right: 10px;
                }
            }
        }
    }

    .brakes-inspection-wrapper {
        background-color: map-get($foundation-palette, white-default);
        border: $grid-border;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 25px 0;

        @include breakpoint(medium down) {
            margin-top: 25px;

            h2 {
                text-wrap: nowrap;
            }

            img {
                max-width: 90%;
            }
        }

        p {
            width: 95%;
        }

        .button {
            width: 75%;

            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        .brakes-image-container {
            margin-top: 50px;

            p {
                display: flex;
                justify-content: center;
                margin-bottom: 0;
            }
        }
    }
}

.brakes-packages-wrapper {
    background-image: url("../images/interface/tire-mark.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50%;
    border: $grid-border;
    padding: 15px;

    @include breakpoint(medium down) {
        background-position: top right;
    }

    h2 {
        text-align: center;
        text-transform: capitalize;
    }

    .brakes-packages {
        display: flex;

        @include breakpoint(medium down) {
            flex-direction: column;
        }

        .packages-block {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0 15px;
            width: 33%;

            @include breakpoint(medium down) {
                flex-direction: row;
                margin: 0 0 15px;
                width: 100%;
            }

            p {
                text-align: center;

                @include breakpoint(medium down) {
                    text-align: left;

                    &.packages-title {
                        margin-bottom: 5px;
                    }
                }

                &.packages-title {
                    font-weight: bold;
                }
            }

            i {
                color: map-get($foundation-palette, primary);
                font-size: 50px;
            }
        }
    }
}

#stickyCTA {
    bottom: -100%;
    box-shadow: 0 0 10px 2px map-get($foundation-palette, darkgrey1);
    left: 0;
    position: fixed;
    transition: bottom 0.4s;
    width: 100%;
    z-index: 5;

    &.slide {
        bottom: 0;
    }

    a {
        margin-bottom: 0;
        width: 100%;
    }
}
