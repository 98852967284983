@media print {
    a[href]::after {
        content: none;
    }

    .pt_order-confirmation {
        margin: 5mm;
        width: 100%;

        .header-banner,
        .header-nav,
        .print-link,
        .login-create-account,
        .need-help-checkout,
        .footer-wrapper,
        .footer-copy-wrapper,
        .top-bar-right,
        .add-to-calendar {
            display: none;
        }

        .atroot,
        .confirmation-page {
            display: block;
            margin-top: 0;
        }

        .checked-icon {
            box-shadow: 0 0 0 4px #ff6900 inset !important;
        }

        .header-main-nav {
            &::after {
                display: none;
            }

            .top-bar-left {
                .header-logo-image {
                    float: right;
                    height: 50%;
                    width: 20%;
                }
            }
        }

        .confirmation-page {
            float: left;
            left: 0;
            top: 0;
            width: 100%;

            .confirmation-message {
                h1 {
                    margin-top: -15mm;
                }
            }

            .section-title-box {
                left: 0;
                margin: 0;
                padding-left: 0;
            }

            .section-box-wrap {
                width: 100%;

                .section-box {
                    flex: 0 0 41.66667%;
                    max-width: 41.66667%;
                }
            }

            .order-information {
                .order-date {
                    left: 0;
                    margin-left: 0;
                    padding-left: 0;
                }
            }

            .main-section-header {
                .productname-column {
                    flex: 0 0 33.66667%;
                    max-width: 33.66667%;
                }

                .productvalues-column {
                    flex: 0 0 66.33333%;
                    max-width: 66.33333%;
                }
            }

            .product-row,
            .service-row {
                .orderdetails-productname {
                    flex: 0 0 33.66667%;
                    max-width: 33.66667%;
                }

                .orderdetails-productvalues {
                    flex: 0 0 66.33333%;
                    max-width: 66.33333%;
                }

                .line-item-price,
                .line-item-quantity,
                .line-item-total {
                    .section-header {
                        display: none;
                    }
                }
            }

            .main-section-header {
                left: 0;
                margin: 0 0 5mm;
                padding: 2mm 0;
            }
        }

        .confirmation-message-wrapper {
            margin: 0 auto 10px !important;
        }

        .appointment-details-wrapper {
            margin-bottom: 0 !important;
        }

        .order-information {
            margin: 10px 0;
        }

        .personal-payment-info {
            .section-title {
                &.show-for-medium {
                    display: none;
                }
            }

            .small-12 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        .payment-verification .section-box {
            padding-left: 14px !important;
        }

        .order-confirmation-details .order-payment-summary {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .product-specific-messages {
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .need-changes {
        display: none;
    }

    #inside_holder,
    .mainNavLiveChatCTA {
        display: none;
    }
}
