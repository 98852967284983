.visualizer-wrapper {
    border: 6px solid map-get($foundation-palette, light-gray);
    margin-bottom: 1.875rem;
}

.visualizer-container {
    padding: 0.625rem 0.75rem;

    @include breakpoint(medium up) {
        .visualizer-image {
            bottom: 4.175rem;
            position: absolute;
            right: 0.625rem;
        }
    }

    .visualizer-tools {
        color: map-get($foundation-palette, midgrey);
        float: right;
        font-size: 13px;

        a {
            color: map-get($foundation-palette, midgrey);

            &:hover {
                text-decoration: underline;
            }
        }

        .minimize {
            display: none;
        }
    }

    .product-tile {
        @include font-gotham-bold;
    }

    .brand-name {
        font-size: 17px;
    }

    .product-name {
        font-size: 22px;
        line-height: 1;
        margin-bottom: 1.5rem;
    }

    .finish {
        margin-bottom: 2.625rem;
        text-transform: capitalize;
    }

    .finish,
    .attribute {
        @include font-gotham-book;

        font-size: 0.875rem;

        span {
            @include font-gotham-bold;
        }
    }

    .attribute {
        color: map-get($foundation-palette, primary);
        margin-bottom: 1.5rem;

        span {
            color: map-get($foundation-palette, secondary);
            display: block;
        }

        em {
            font-style: normal;

            &::after {
                content: "\f10e";
                font-family: fontawesome;
                font-size: 6px;
                vertical-align: super;
            }
        }
    }

    .product-pricing {
        float: none;
        width: 100%;

        .price-standard {
            @include breakpoint(medium up) {
                display: inline-block;
            }

            + .price-sales {
                @include breakpoint(medium up) {
                    float: none;
                }
            }
        }
    }

    .product-price {
        @include clearfix;
        color: map-get($foundation-palette, primary);
        margin-bottom: 1.25rem;
    }

    .visualizer-tip {
        @include font-gotham-book;
        border: 0;
        color: map-get($foundation-palette, primary);
        font-size: 11px;

        margin-bottom: 1.25rem;

        @include breakpoint(medium up) {
            bottom: 5px;
            position: absolute;
            right: 0.625rem;
        }
    }

    .relative {
        position: relative;
    }
}

.visualizer-carousel {
    border-top: 6px solid map-get($foundation-palette, light-gray);
    padding: 1.25rem 3.5rem 0;
    position: relative;

    .carousel-wrapper {
        height: 14rem;
    }

    .carousel-container {
        margin: 0;
    }
}

.v-carousel-item {
    @include font-gotham-bold;
    cursor: pointer;
    font-size: 12px;

    height: 14rem;
    outline: none;
    text-align: center;

    img {
        outline: none;
    }

    a {
        display: block;
        margin: 0 auto;
        max-width: 120px;
        outline: none;
    }

    .price {
        color: map-get($foundation-palette, midgrey);

        .currency-code {
            font-size: 100%;
            top: 0;
        }
    }

    &.active,
    &:hover {
        border-bottom: 6px solid map-get($foundation-palette, primary);
    }
}

.visualizer-colors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.25rem 0 0.625rem;

    @include breakpoint(medium up) {
        bottom: 0.625rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    li {
        border: 1px solid map-get($foundation-palette, midgrey);
        border-radius: 10px;
        box-sizing: border-box;
        color: map-get($foundation-palette, white-default);
        cursor: pointer;
        height: 1rem;
        line-height: 1rem;
        margin: 5px 2px;
        min-width: 1rem;
        overflow: hidden;
        position: relative;
        text-indent: -9999px;
        width: 1rem;

        @include breakpoint(small only) {
            margin: 5px 2px;
        }

        &::before {
            content: "\f111";
            font-family: fontawesome;
            font-size: 20px;
            left: 50%;
            margin: -0.5rem 0 0 -0.5rem;
            position: absolute;
            text-indent: 0;
            top: 50%;
        }

        &.active,
        &:hover {
            border-color: map-get($foundation-palette, primary);
        }
    }
}

#v-visualizer-large {
    @include breakpoint(medium up) {
        .product-tile {
            min-height: 24rem;
        }
    }

    .visualizer-tools {
        .minimize {
            display: block;
        }

        .expand {
            display: none;
        }
    }

    .product-tile {
        @include font-gotham-bold;
    }
}

.carousel-btn {
    border: 3px solid map-get($foundation-palette, primary);
    color: map-get($foundation-palette, secondary);
    height: 2rem;
    left: 1rem;
    line-height: 1.75rem;
    position: absolute;
    text-align: center;
    top: 4rem;
    width: 2rem;

    &.slick-disabled {
        border-color: $light-gray;
        color: $light-gray;
    }

    &.carousel-next {
        left: auto;
        right: 1rem;
    }
}

#v-pdp-toplevelcomponent {
    position: relative;

    @include breakpoint(medium up) {
        #v-vehicle-image {
            min-height: 17.5rem;
        }

        .relative {
            padding-bottom: 3.125rem;
        }
    }
}

#v-visualizer-default-vehicle-small {
    text-align: center;
}
