body,
html {
    height: auto;
}

body {
    box-sizing: border-box;
    margin: 0;
}

ol,
ul {
    list-style: none;
}

.fullbleed {
    margin: 0 auto;
    max-width: 1800px;
    width: 100%;
}

.fullbleed-mobile {
    padding: 0 20px;

    @include breakpoint(large up) {
        padding: 0 30px;
    }

    @include breakpoint(small only) {
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }
}

.row {
    &.atroot {
        padding: 0 15px;

        @include breakpoint(medium up) {
            padding: 0 20px;
        }

        @include breakpoint(large up) {
            padding: 0 30px;
        }

        &.sel-info-wrapper {
            @include breakpoint(small only) {
                padding: 0;

                .nopaddingsmall {
                    padding: 0;
                }
            }
        }
    }
}

.no-js {
    .top-bar {
        visibility: hidden;
    }

    .title-bar {
        display: none;
    }

    #mainNavWrapper {
        display: none;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


.reveal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.visibility-hidden {
    visibility: hidden;
}
