.login-modal {
    height: 160px;
    min-height: 160px; // Over write the min-height from _reveal.scss
    position: absolute !important;
    width: 400px;
    
    @include breakpoint(medium up) {
        height: 175px;
        min-height: 175px; // Over write the min-height from _reveal.scss
    }

    @include breakpoint(medium down) {
        margin-top: 70px !important;
        max-width: calc(100vw - 20px);
    }

    .close-button {
        color: map-get($foundation-palette, secondary);
    }

    .login-modal-title {
        margin-bottom: 15px;
    }

    p {
        font-size: 14px;
        margin-bottom: 15px;
        padding: 10px 0;

        @include breakpoint(medium up) {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }

    a {
        margin: 0;
    }

    .no-thanks {
        padding: 8px;
        
        a {
            color: map-get($foundation-palette, secondary);
            font-size: 14px;
        }

        :hover {
            text-decoration: underline;
        }
    }

    .cb-cta.button-full-white {
        padding: 8px 60px;
    }
}
