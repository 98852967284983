[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
    height: 40px;
    line-height: 1.125rem;
    margin: 0;
    padding: 10px 15px;
}

input::-ms-clear {
    display: none;
}

select {
    background-image: url("../images/icons/select-icon.png");
    background-position: right center;
    height: 40px;
    padding: 0 15px;

    @media screen and (min-width:0\0) {
        background-image: url("../images/icons/select-icon.png");
    }
}

.sort-select {
    background-origin: border-box;
    background-position: right 1rem center;
    padding: 0 35px 0 10px;
}

label {
    @include font-gotham-medium;

    font-size: rem-calc(14);
    line-height: 1;
    margin: 0 0 0.5rem;
}

textarea {
    resize: none;
}

.dialog-required {
    line-height: 1;
}

.required-indicator {
    @include font-gotham-book;
    color: map-get($foundation-palette, secondary);

    font-size: rem-calc(14);
}

input,
select,
textarea {
    &.error {
        border: 1px solid map-get($foundation-palette, error);
    }
}

//div.error,
span.error,
.error-message,
.couponError,
.name-length-error {
    @include font-gotham-book;
    background: none;
    clear: both;
    color: map-get($foundation-palette, error);
    display: inline-block;
    float: left;

    font-size: rem-calc(12);
    line-height: 1.2;
    padding: 7px 0 0;
    width: 100%;
}

.error-form {
    @include font-gotham-book;
    color: map-get($foundation-palette, error);

    font-size: rem-calc(12);
    line-height: 1.2;
    margin: 10px 0;
}

.form-row {
    @include clearfix;
    margin: 0 0 20px;
}

.form-caption.error-message {
    a {
        text-decoration: underline;
    }
}

// Custom checkbox and radio inputs
.checkbox-form,
.radio-form {
    align-items: center;
    display: flex;

    .field-wrapper {
        align-items: center;
        display: flex;
        position: relative;

        + label {
            @include font-gotham-book;

            margin: 0;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        margin: 2px 0.3125rem 0 0;
        opacity: 0;
        position: relative;
        z-index: 2;

        &:focus {
            outline: none;
        }
    }

    .input-custom {
        background: map-get($foundation-palette, white-default);
        display: block;
        left: 0;
        position: absolute;
        top: 2px;
        z-index: 1;
    }
}

.checkbox-form {
    input[type="checkbox"] {
        &:checked + .input-custom {
            border-color: map-get($foundation-palette, secondary);

            &::after {
                content: "\f00c";
            }
        }

        &:checked:disabled + .input-custom {
            color: map-get($foundation-palette, secondary);
        }
    }

    .input-custom {
        border: 1px solid #d9d9d9;
        color: map-get($foundation-palette, primary);
        line-height: 17px;

        &::after {
            content: "";
            font-family: fontawesome;
            position: absolute;
        }
    }

    .input-custom,
    input[type="checkbox"] {
        height: 17px;
        width: 17px;
    }
}

.radio-form {
    input[type="radio"] {
        &:checked + .input-custom {
            background: map-get($foundation-palette, primary);
            border-color: map-get($foundation-palette, primary);
        }
    }

    .input-custom {
        border: 2px solid #d9d9d9;
        border-radius: 100%;
        line-height: 14px;
    }

    .input-custom,
    input[type="radio"] {
        height: 14px;
        width: 14px;
    }
}

//Custom style for Commercial Fleet Vehicles checkbox buttons
.commercial-fleet-wrapper {
    .input-custom,
    input[type="checkbox"] {
        border-color: map-get($foundation-palette, secondary);
        border-radius: 50%;
    }

    input[type="checkbox"] {
        &:checked + .input-custom {
            background: map-get($foundation-palette, primary);
            border-color: map-get($foundation-palette, primary);

            &::after {
                color: map-get($foundation-palette, white-default);
            }
        }
    }
}
