#productSelectorSlotWrapper {
    &.expanded {
        #productSelectorContent {
            &.service-selector-content {
                max-width: 774px;
            }
        }
    }
}

#productSelectorContent.service-selector-content {
    .choice-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;

        @include breakpoint(medium up) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    .choice {
        align-items: center;
        background-color: map-get($foundation-palette, light-gray);
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
        margin-top: 15px;
        min-height: 182px;
        position: relative;

        @include breakpoint(medium up) {
            flex: 1 1 50%;
            margin-bottom: 2px;
            margin-left: 2px;
            margin-top: 30px;
            width: 347px;
        }

        img {
            max-width: 100px;
        }

        &:hover {
            background-color: map-get($foundation-palette, primary);
            color: $white;
            cursor: pointer;

            .check-icon {
                border: 2px solid map-get($foundation-palette, white-default);
            }
        }

        &[data-value="Yes"] {
            order: 1;
        }

        &[data-value="No"] {
            order: 2;
        }
    }

    .check-icon {
        border: 2px solid #dedede;
        border-radius: 50%;
        height: 19px;
        margin: 10px 10px 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 19px;

        @include breakpoint(medium up) {
            height: 27px;
            margin: 15px 15px 0;
            width: 27px;
        }

        &.selected {
            background-color: map-get($foundation-palette, white-default);

            &::before {
                color: map-get($foundation-palette, primary);
                content: "\f00c";
                display: block;
                font-family: fontawesome;
                font-size: 11px;
                text-align: center;
                width: 16px;

                @include breakpoint(medium up) {
                    font-size: 16px;
                    width: 25px;
                }
            }
        }
    }

    .productSelector {
        h3 {
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(14);
            margin: 0;
            text-align: center;

            @include breakpoint(medium up) {
                font-size: rem-calc(20);
                padding: 5px 0 0;
                text-align: left;
            }
        }
    }

    .selector-tab {
        padding: 20px 10px;

        @include breakpoint(medium up) {
            padding: 28px 0;
        }
    }

    .selector-image {
        @include breakpoint(medium up) {
            margin-left: 30px;
            max-width: 250px;
            min-width: 250px;
        }
    }

    .selector-description {
        margin-right: 10px;

        @include breakpoint(medium up) {
            max-width: 187px;
        }

        h3 {
            margin-bottom: 10px;

            @include breakpoint(medium up) {
                font-size: rem-calc(20);
                line-height: 24px;
                margin-bottom: 20px;
            }
        }

        .button {
            color: map-get($foundation-palette, secondary);
            font-size: rem-calc(10);
            max-width: 137px;

            @include breakpoint(medium up) {
                font-size: rem-calc(14);
            }

            @include breakpoint(small down) {
                margin-top: 0;
                padding: 12px 10px;
            }
        }
    }

    .tire_mount {
        p {
            @include font-gotham-medium;

            color: $medium-dark-gray;
            font-size: 14px;
            margin: 0;
            padding-left: 15px;

            @include breakpoint(medium up) {
                font-size: 18px;
                padding-left: 24px;
            }
        }

        .choice {
            &:first-child {
                order: 1;

                @include breakpoint(medium up) {
                    margin-right: 0;
                }
            }

            &:last-child {
                order: 0;

                @include breakpoint(medium up) {
                    margin-right: 14px;
                }
            }
        }
    }

    .VEHICLE_TYPE_1 {
        .choice {
            flex-direction: column;

            img {
                max-width: 180px;
            }

            p {
                margin: 0;
                padding: 0;
            }

            &:first-child {
                order: 0;

                @include breakpoint(medium up) {
                    margin-right: 14px;
                }
            }

            &:last-child {
                order: 1;
            }
        }
    }
}

#choiceModal {
    &.reveal {
        @include breakpoint(medium up) {
            max-width: 467px;
        }
    }

    strong {
        display: block;
        padding-bottom: 20px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            padding-bottom: 25px;
        }
    }

    .modal-content {
        padding: 30px 20px;
        text-align: center;

        @include breakpoint(medium up) {
            padding: 40px 0;
        }
    }

    .button {
        @include breakpoint(medium up) {
            max-width: 130px;
        }
    }
}
