.contactus-landing-wrapper {
    .contact-us-map {
        width: 100%;

        @include breakpoint(small only) {
            padding: 0 30px;
        }
    }

    .contactus-banner {
        background-size: cover;
        color: map-get($foundation-palette, white-default);
        margin-bottom: 1.75rem;
        padding: 4rem 0 3rem;

        @include breakpoint(medium up) {
            margin-bottom: 3rem;
            padding: 8.75rem 0 8.25rem;

            p {
                font-size: 18px;
                line-height: 1.5rem;
            }
        }

        .contactus-banner-text-block {
            margin-top: 3rem;
        }

        p {
            @include font-gotham-medium;
        }

        h2 {
            font-size: 42px;
            margin-bottom: 1.5rem;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: 67px;
                margin-bottom: 1rem;
            }
        }
    }

    .cards-title {
        @include breakpoint(small only) {
            font-size: 18px !important;
        }
    }

    .contact-block {
        margin-bottom: rem-calc(20);
    }

    .contact-text {
        @include font-gotham-medium;

        font-size: 16px;
    }

    .contact-openingdays {
        color: #999;
        margin-bottom: 0;
    }

    .contact-divider {
        border-top: 1px solid #e8e8e9;
    }

    .contactus-help {
        margin-top: 4rem;
    }

    .contact-align-complement {
        font-family: $gotham-a-b !important;
        font-size: 14px;
        font-weight: 400 !important;
        margin-bottom: 0;
        padding-left: 24px;
    }

    .contactus-banner-wrapper {
        margin-bottom: 2.75rem;
        min-height: 3.3125rem;
        position: relative;

        @include breakpoint(medium up) {
            margin-bottom: 5rem;
            min-height: 3.75rem;
        }

        .contactus-banner {
            margin-bottom: 0;
            padding-bottom: 5.625rem;

            @include breakpoint(medium up) {
                padding: 8.125rem 0 8.25rem;
            }
        }
    }

    .contactus-page-title {
        border-left: 3px solid map-get($foundation-palette, primary);
        font-size: 26px;
        line-height: 1.125rem;
        margin-bottom: 2.75rem;
        padding-left: 1.125rem;
        text-transform: uppercase;

        @include breakpoint(small only) {
            font-size: 18px;
        }
    }

    .contactus-help-cards {
        margin-bottom: 2rem;
        margin-top: 2rem;

        .cards-title {
            font-size: rem-calc(22);
            margin-top: rem-calc(15);
        }

        .card-block {
            @include breakpoint(small only) {
                margin-bottom: 1rem;
            }
        }

        .card-icon {
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1em;
            margin-right: 0.2em;
            speak: none;
            text-align: center;
            text-decoration: inherit;
            text-transform: none;
            width: 1em;
        }

        .cards-description {
            font-size: rem-calc(14);
        }

        a {
            @include font-gotham-bold;

            font-size: rem-calc(14);
        }

        .assistance-block {
            background: #ededed;
            margin-top: rem-calc(24);
            padding: rem-calc(20);

            .assistance-text {
                @include font-gotham-medium;

                color: #999;
                margin-bottom: rem-calc(5);
            }

            .assistance-number {
                @include font-gotham-medium;

                color: #f60;
                margin-bottom: rem-calc(5);
            }
        }
    }
}

.contactus-address {
    display: block;

    @include breakpoint(medium up) {
        display: none;
    }

    .address-title {
        font-size: rem-calc(18);
        margin-top: rem-calc(15);
    }

    .address-location {
        @include font-gotham-book;
        font-size: rem-calc(14);

        margin-bottom: 0;
    }

    .address-directions {
        @include font-gotham-bold;

        font-size: rem-calc(13);
    }

    .address-workinghours {
        margin-bottom: 2rem;
    }
}
