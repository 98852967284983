.footer-faq-wrapper {
    margin: 0 0 50px;

    hr {
        border-bottom-color: #e0e1e1;
        margin: 0 0 50px;
    }

    h2 {
        @include font-gotham-bold;

        font-size: rem-calc(24);
        margin-bottom: 20px;
    }

    h5 {
        @include font-gotham-medium;

        margin-bottom: 16px;
    }

    p {
        font-size: rem-calc(14);
    }

    a.faq-cta {
        @include font-gotham-bold;

        font-size: rem-calc(14);
    }


    .columns {

        @include breakpoint(small only) {
            margin-bottom: 30px;
        }
    }
}

.guest-checkout-text {
    @include font-gotham-book;

    margin-top: 23px;

    p {
        margin: 0.1875rem 0 1.75rem;

        @include breakpoint(medium up) {
            font-size: 0.875rem;
            line-height: 1.1875rem;
            margin-bottom: 2.875rem;
        }
    }
}

.checkout-title {
    @include font-gotham-bold;

    font-size: 18px;
    line-height: 1em;
    margin-bottom: 25px;
}

.pt_checkout {
    legend {
        margin: 0;
    }

    select {
        margin: 0;
    }

    .dialog-required {
        display: block;
        margin: 0 0 1.25rem;
        text-align: right;
    }

    .step-back-link {
        @include font-gotham-bold;
        display: block;

        font-size: rem-calc(13);
        line-height: 1;
        margin: 20px 0;
    }

    .form-row-button {
        margin: 0 0 1.875rem;
        text-align: right;

        @include breakpoint(large up) {
            margin: 0;
        }

        button {
            margin: 0;
            width: 100%;

            @include breakpoint(medium up) {
                width: auto;
            }
        }
    }
}

.payment-form {
    display: none;

    &.active {
        display: block;
    }
}

.checkout-main-title {
    @include font-gotham-bold;

    font-size: rem-calc(26);
    margin: 0 0 0.625rem;
    text-transform: uppercase;

    @include breakpoint(medium up) {
        margin: 0 0 1.563rem;
    }
}

.checkout-step {
    h2 {
        @include font-gotham-bold;

        font-size: rem-calc(20);
        line-height: 1;

        span {
            color: map-get($foundation-palette, primary-accessible);
        }
    }
}

p.select-vehicle {
    padding-top: rem-calc(10);
}

.header-title-wrapper {
    align-items: center;
    margin-bottom: 50px;

    .checkout-step-title {
        font-size: rem-calc(16);
        margin-bottom: 20px;

        @include breakpoint(medium up) {
            font-size: rem-calc(20);
            margin: 0;
            padding: 15px 0;
        }
    }

    .form-row-button {
        margin: 0;
    }
}

.checkout-main-row {
    margin-top: 1.25rem;

    @include breakpoint(large up) {
        position: relative;
    }
}

.checkout-progress-indicator {
    margin-bottom: 65px;

    @include breakpoint(medium up) {
        margin-bottom: 87px;
    }

    .checkout-main-title {
        padding: 45px 0 15px;
        text-transform: none;
    }

    .steps-wrapper {
        margin: 0 auto;
        max-width: 270px;

        @include breakpoint(medium up) {
            max-width: 454px;
        }
    }

    .steps {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .step {
        background: map-get($foundation-palette, white-smoke);
        border-radius: 100%;
        display: flex;
        height: 32px;
        justify-content: center;
        position: relative;
        width: 32px;

        @include breakpoint(medium up) {
            height: 46px;
            width: 46px;
        }

        &::before {
            background-color: map-get($foundation-palette, white-smoke);
            content: "";
            height: 2px;
            position: absolute;
            right: 32px;
            top: 16px;
            width: 48px;
            z-index: -1;

            @include breakpoint(medium up) {
                right: 46px;
                top: 22px;
                width: 54px;
            }
        }

        &:first-child {

            @include breakpoint(medium up) {
                margin-left: 54px;

                &::before {
                    width: 54px;
                }
            }

            &::before {
                width: 24px;
            }
        }

        &:last-child {
            @include breakpoint(medium up) {
                margin-right: 54px;
            }

            &::after {
                background-color: map-get($foundation-palette, white-smoke);
                content: "";
                height: 2px;
                left: 32px;
                position: absolute;
                top: 16px;
                width: 24px;
                z-index: -1;

                @include breakpoint(medium up) {
                    left: 46px;
                    top: 22px;
                    width: 54px;
                }
            }
        }

        &.active,
        &.passed {
            background: map-get($foundation-palette, primary);
            color: map-get($foundation-palette, primary);

            &::before {
                background-color: map-get($foundation-palette, primary);
            }

            &:last-child {
                &::after {
                    background-color: map-get($foundation-palette, primary);
                }
            }
        }

        &.passed {
            background-color: $white;
            box-shadow: 0 0 0 2px map-get($foundation-palette, primary) inset;
            color: map-get($foundation-palette, primary);

            .icon {
                color: map-get($foundation-palette, primary);
            }
        }

        &.inactive {
            color: map-get($foundation-palette, very-light-grey);
        }
    }

    .step-name {
        bottom: -15px;
        font-size: 8px;
        left: -50%;
        position: absolute;
        right: -50%;

        @include breakpoint(medium up) {
            bottom: -20px;
            font-size: rem-calc(10);
        }
    }

    .icon {
        align-self: center;
        color: $white;
        font-size: rem-calc(15);

        @include breakpoint(medium up) {
            font-size: rem-calc(22);
        }

        &::before {
            align-items: center;
            display: flex;
            font-family: fontawesome;
            justify-content: center;
        }

        &-appointment {
            &::before {
                content: "\f271";
                padding-left: 1px;
            }
        }

        &-information {
            &::before {
                content: "\f007";
            }
        }

        &-verification {
            &::before {
                content: "\f09d";
            }
        }

        &-review {
            &::before {
                content: "\f15c";
            }
        }
    }
}

// Order Summary section
.checkout-summary {
    .section-title {
        @include font-gotham-bold;

        font-size: rem-calc(18);
        line-height: 1;
        margin: 0 0 0.625rem;
    }
}

.product-specific-messages {
    background: map-get($foundation-palette, white-ghost);
    margin: rem-calc(20);
    padding: rem-calc(10 20 20 20);

    @include breakpoint(medium down) {
        margin: rem-calc(10);
        padding: rem-calc(2 15 15 15);
    }
}

.checkout-order-totals {
    border: 1px solid $medium-gray;
    margin: 0 0 1.5625rem;
    padding: 1rem 1.25rem;

    @include breakpoint(medium up) {
        margin: 0 0 2.5rem;
    }

    @include breakpoint(large up) {
        margin: 0 0 1rem;
    }

    .order-totals-table {
        margin: 0;

        tbody {
            border: 0;

            tr:nth-child(even) {
                background: transparent;
            }

            td {
                padding: 0 0 0.625rem;
            }
        }

        tr {
            @include font-gotham-book;

            font-size: rem-calc(14);
            line-height: 1;

            @include breakpoint(medium up) {
                font-size: rem-calc(15);
            }

            td:first-child {
                padding-right: 0.625rem;
                text-align: left;
            }

            td:last-child {
                text-align: right;
            }

            &.bold {
                @include font-gotham-bold;
            }

            &.amount-total-paid {
                border-top: 2px solid #f2f2f3;

                td {
                    margin-top: 1rem;
                    padding-top: 1rem;
                }
            }

            &.amount-store-total,
            &.amount-total-paid {
                &.bold {
                    font-size: rem-calc(15);

                    @include breakpoint(medium up) {
                        font-size: rem-calc(16);
                    }
                }
            }

            &.vehicle-info {
                @include font-gotham-medium;

                font-size: rem-calc(14);

                @include breakpoint(medium up) {
                    font-size: rem-calc(15);
                }

                td {
                    padding: 0 0 2.188rem;
                    text-align: left;
                }
            }
        }
    }

    .rebate-message {
        margin: 0.625rem 0;

        @include breakpoint(medium up) {
            margin: 0.625rem 0 0;
        }

        p {
            @include font-gotham-book;
            color: map-get($foundation-palette, midgrey);

            font-style: italic;
            margin: 0;
        }
    }

    .levy-message-minisummary {
        border-top: 2px solid #f2f2f3;
        color: $dark-gray;
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        margin: rem-calc(18) 0 0 0;
        padding-top: rem-calc(16);
    }

    .step-back-link {
        margin: 0.625rem 0 0;
    }
}

// Need Help box
.need-help-checkout {
    h3 {
        font-size: rem-calc(18);
        margin: 0 0 0.625rem;
    }

    p {
        font-size: rem-calc(12);
        margin: 0 0 0.3125rem;
    }

    a {
        font-size: rem-calc(14);
    }
}

// Step 2 - Your Information & Commercial Fleet Vehicles
.checkout-shipping {
    legend {
        width: 100%;
    }

    .dialog-required {
        display: inline-block;
        text-align: left;

        @include breakpoint(medium up) {
            margin: 0 0 1.875rem;
        }
    }

    .save-for-billing {
        @include breakpoint(medium up) {
            margin: 0 0 1.875rem;
        }
    }

    .checkout-fields-container {
        @include font-gotham-bold;

        margin: 0 0 1.25rem;

        @include breakpoint(medium up) {
            margin: 0 0 1.875rem;
        }
    }

    .selection-box {
        margin: 0 0 1.875rem;

        @include breakpoint(medium up) {
            margin: 0 0 2.5rem;
        }

        .section-title {
            @include font-gotham-medium;

            font-size: rem-calc(16);
            line-height: 1.5;
            margin: 0 0 0.625rem;

            @include breakpoint(medium up) {
                font-size: rem-calc(18);
            }
        }

        .radio-form,
        .checkbox-form {
            margin: 0 0 8px;
        }

        .radio-form {
            .field-wrapper + label {
                margin-left: 2px;
            }
        }
    }

    .commercial-fleet-wrapper {
        .commercial-fleet-form {

            @include breakpoint(medium up) {
                width: 50%;
            }
        }

        .section-title {
            font-weight: $global-weight-bold;
        }

        h1 {
            @include font-gotham-medium;
            background: map-get($foundation-palette, primary-accessible);
            color: map-get($foundation-palette, white-default);

            font-size: 13px;
            line-height: 1rem;
            margin: 0;
            padding: 7px 15px;
        }

        .has-tip {
            border-bottom: 0;
            left: 8px;
            top: 0;

            .questionmarkcircle {
                color: map-get($foundation-palette, primary-accessible);
            }
        }
    }

    .vehicle-information {
        margin: 20px 0 30px;
        max-width: 590px;

        @include breakpoint(medium up) {
            margin: 20px 0 70px;
        }

        p {
            margin: 0;
        }

        .informations {
            @include font-gotham-book;

            font-size: rem-calc(14);
            font-weight: normal;
            line-height: rem-calc(21);
            padding-bottom: 20px;

            @include breakpoint(medium up) {
                font-size: rem-calc(15);
                padding-bottom: 30px;
            }
        }

        .vehicle {
            @include font-gotham-medium;
            font-size: rem-calc(14);

            text-transform: uppercase;
        }

        .add-vehicle {
            margin: 0;

            @include breakpoint(medium up) {
                max-width: 141px;
            }
        }

        .edit-button {
            font-size: rem-calc(13);
            text-transform: capitalize;
        }
    }
}

// Step 2 warning modal
#psmodal {
    &.warning-modal {
        padding: 30px;

        h3 {
            font-size: rem-calc(21);
            margin: 0;
        }

        p {
            @include font-gotham-book;

            font-size: rem-calc(16);
            margin: 0;
            padding: 10px 0 30px;
        }

        .close-button {
            top: 23px;
        }

        .button {
            margin: 0;

            @include breakpoint(medium up) {
                max-width: 210px;
            }
        }

        .keep-vehicle {
            margin: 0 0 10px;

            @include breakpoint(medium up) {
                margin: 0 10px 0 0;
            }
        }
    }
}

// Step 2 Your Information & Step 3 Payment - custom styles for City, Province, Postal code and Country fields
.checkout-fields-container {
    .form-row {

        @include breakpoint(small only) {
            &.city-row,
            &.postal-row {
                clear: left;
                padding-right: 0.3125rem;
            }

            &.state-row,
            &.country-row {
                padding-left: 0.3125rem;
            }
        }

        &.city-row,
        &.state-row,
        &.postal-row,
        &.country-row {
            float: left;
            width: 50%;

            @include breakpoint(medium up) {
                clear: none;
                padding: 0 0.625rem;
                width: 25%;
            }
        }

        &:not(.city-row),
        &:not(.postal-row),
        &:not(.state-row),
        &:not(.checkbox-form),
        &:not(.country-row) {
            @include breakpoint(medium up) {
                width: 50%;

                &:nth-child(odd) {
                    clear: left;
                    float: left;
                    padding: 0 0.625rem 0 0;
                }

                &:nth-child(even) {
                    float: right;
                    padding: 0 0 0 0.625rem;
                }
            }
        }

        &.city-row,
        &.state-row,
        &.postal-row,
        &.country-row {
            float: left;
            width: 50%;

            @include breakpoint(medium up) {
                clear: none;
                padding: 0 0.625rem;
                width: 25%;
            }
        }

        &:not(.city-row),
        &:not(.postal-row),
        &:not(.state-row),
        &:not(.country-row) {
            @include breakpoint(medium up) {
                width: 50%;

                &:nth-child(odd) {
                    clear: left;
                    float: left;
                    padding: 0 0.625rem 0 0;
                }

                &:nth-child(even) {
                    float: right;
                    padding: 0 0 0 0.625rem;
                }
            }
        }

        &.city-row {
            @include breakpoint(medium up) {
                clear: left;
                padding-left: 0;
            }
        }

        &.country-row {
            @include breakpoint(medium up) {
                padding-right: 0;
            }
        }

        &.postal-row {
            .field-wrapper {
                @include breakpoint(medium up) {
                    width: 50%;
                }
            }
        }

        .field-wrapper.has-tooltip {
            position: relative;

            i {
                color: $dark-gray;
                font-size: rem-calc(18);
                position: absolute;
                right: rem-calc(14);
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
            }
        }

        &.form-indent.checkbox-form {
            padding: 0;
            width: 100%;
        }
    }
}

// Step 3 - Payment
.checkout-billing {
    .top-payment-info {
        background-color: #f2f2f2;
        margin-bottom: 16px;
        padding: 30px;

        @include breakpoint(small only) {
            padding: 20px;
        }

        p {
            margin: 0;
        }

        .due-payment-title {
            color: map-get($foundation-palette, primary-accessible);
            font-size: rem-calc(20);
            font-weight: bold;
            padding-bottom: 15px;

            @include breakpoint(small only) {
                font-size: rem-calc(17);
                line-height: rem-calc(23);
                padding-bottom: 10px;
            }
        }
    }

    .section-title {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1;
        margin: 0 0 1.25rem;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: rem-calc(15);
            margin: 0 0 1.875rem;
        }
    }

    .checkout-fields-container {
        margin: 0 0 0.625rem;

        @include breakpoint(medium up) {
            margin: 0 0 1.875rem;
        }

        .form-row.address-row {
            @include breakpoint(medium up) {
                padding: 0 !important;
                width: 100% !important;
            }
        }
    }

    .payment-method {
        margin: 0 0 2.813rem;
    }

    .credit-card-logos {
        margin: 0 0 1.875rem;
        max-width: 150px;
    }

    #monerisFrame {
        height: 235px;
        width: 100%;
    }

    #monerisMsg {
        @include font-gotham-book;
        color: map-get($foundation-palette, error);
        float: left;

        font-size: rem-calc(12);
        line-height: 1;
        width: 100%;
    }
}

//Step 4 and 5 - common style
.order-review-page,
.confirmation-page {
    .line-item-details {
        .product-brand {
            font-size: rem-calc(14);
            line-height: 18px;

            @include breakpoint(medium up) {
                font-size: rem-calc(15);
            }
        }

        .product-name {
            line-height: 18px;

            @include breakpoint(medium up) {
                font-size: rem-calc(20);
                line-height: 22px;
            }
        }
    }

    .line-item-price {
        font-size: rem-calc(14);

        @include breakpoint(medium up) {
            font-size: rem-calc(15);
        }
    }

    .line-item-quantity {
        .item-quantity {
            font-size: rem-calc(14);

            @include breakpoint(medium up) {
                font-size: rem-calc(15);
            }
        }
    }

    .address1 {
        float: left;
    }

    .zip-code {
        text-transform: uppercase;
    }
}

// Step 4 - Order Review
.order-review-page {
    address {
        font-style: normal;
    }

    .section-title-box {
        background: map-get($foundation-palette, light-gray);
        display: flex;
        justify-content: space-between;
        margin: 0 0 1.875rem;
        padding: 0.9375rem 0.625rem;

        @include breakpoint(medium up) {
            background: none;
            border-top: 1px solid #f2f2f3;
            margin: 0 0 1.563rem;
            padding: 1.563rem 0 0;
        }

        .main-section-title {
            @include font-gotham-bold;
            display: inline-block;

            font-size: rem-calc(16);
            line-height: 1;
            margin: 0;
        }

        .edit-section-link {
            font-size: rem-calc(13);
            line-height: 1;
        }
    }

    .section-title {
        @include font-gotham-bold;

        font-size: rem-calc(14);
        line-height: 1;
        margin: 0 0 0.3125rem;
    }

    .section-box-wrap {
        margin-bottom: 0.9375rem;

        @include breakpoint(medium up) {
            margin-bottom: 1.563rem;
        }

        .section-box {
            margin-bottom: 1.25rem;

            @include breakpoint(small only) {
                padding-left: 0.625rem;
                padding-right: 0.625rem;

                &.columns {
                    padding-left: 1.563rem;
                    padding-right: 1.563rem;
                }
            }

            @include breakpoint(medium up) {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 14px;
            line-height: 1;
            margin: 0 0 0.3125rem;
        }

        .appointment-time {
            color: map-get($foundation-palette, midgrey);
        }

        .right-info-box {
            .section-box {
                @include breakpoint(medium up) {
                    margin-bottom: 1.25rem;
                }
            }
        }
    }

    .disclaimer-checkout {
        @include font-gotham-book;
        color: map-get($foundation-palette, midgrey);

        font-size: rem-calc(14);
        line-height: 22px;

        p {
            font-size: rem-calc(14);
            margin: 0;
        }
    }

    .order-summary-footer {
        margin: 1.563rem 0;

        @include breakpoint(large up) {
            margin: 3.125rem 0 0;
        }
    }

    .button {
        font-size: 14px;
    }
}

// Order confirmation page
.confirmation-page {
    margin: 1.25rem 0 0;

    @include breakpoint(medium up) {
        margin: 3.438rem 0 0;
    }

    // Create account box
    .login-box {
        background-color: map-get($foundation-palette, light-gray);
        margin: 0 0 1.875rem;
        padding: 1.25rem 0.9375rem;

        @include breakpoint(medium up) {
            padding: 1.563rem 1.25rem;
        }

        &.login-create-account {
            .login-box-fieldset {
                display: flex;
                flex-wrap: wrap;

                .form-row {
                    flex-basis: 100%;

                    @include breakpoint(medium up) {
                        flex-basis: 30%;
                        margin-right: 20px;
                    }
                }
            }

            form {
                margin-bottom: 15px;
            }

            .login-box-submit-button {
                flex-basis: 100%;
            }

            .privacy-policy {
                color: map-get($foundation-palette, midgrey);
                font-size: 13px;

                &.link {
                    text-decoration: underline;
                }
            }
        }

        .button {
            margin: 0;
        }
    }

    .create-account-title {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1.3;
        margin: 0 0 0.9375rem;

        @include breakpoint(medium up) {
            font-size: rem-calc(18);
            margin: 0 0 1.563rem;
        }
    }

    .create-account-description {
        @include font-gotham-book;

        font-size: rem-calc(14);
        margin: 0 0 0.625rem;

        @include breakpoint(medium up) {
            font-size: rem-calc(15);
        }
    }

    // Order confirmation main content
    .confirmation-message {
        margin: 0 0 0.625rem;

        @include breakpoint(medium up) {
            margin: 0 0 2.188rem;
        }

        h1 {
            @include font-gotham-bold;

            font-size: rem-calc(18);
            line-height: 1;
            margin: 0 0 1.25rem;

            @include breakpoint(medium up) {
                font-size: rem-calc(22);
                margin: 0 0 1.875rem;
            }
        }

        p {
            font-size: rem-calc(14);
            line-height: 1.3;
            margin: 0 0 0.9375rem;

            @include breakpoint(medium up) {
                margin: 0 0 1.563rem;
            }
        }
    }

    .section-title-box {
        justify-content: space-between;
        margin: 0 0 1.875rem;

        @include breakpoint(medium up) {
            margin: 0 0 1.563rem;
            padding: 1.563rem 0 0;
        }

        .main-section-title {
            @include font-gotham-bold;
            display: inline-block;

            font-size: rem-calc(16);
            line-height: 1;
            margin: 0;
        }

        .edit-section-link {
            font-size: rem-calc(13);
            line-height: 1;
        }

        &.action-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .phone-number {
                color: map-get($foundation-palette, primary-accessible);
            }

            .orderSummaryLiveChat {
                color: map-get($foundation-palette, primary-accessible);
                cursor: pointer;
            }

            .action-icon {
                .card-icon {
                    max-height: 16px;
                }
            }
        }
    }

    .section-title {
        @include font-gotham-bold;

        font-size: rem-calc(14);
        line-height: 1;
        margin: 0 0 0.3125rem;

        &--top-margin {
            margin-top: 1.25rem;
        }
    }

    .section-box-wrap {
        margin-bottom: 0.9375rem;

        @include breakpoint(medium up) {
            margin-bottom: 1.563rem;
        }

        .section-box {
            margin-bottom: 1.25rem;

            @include breakpoint(small only) {
                padding-left: 0.625rem;
                padding-right: 0.625rem;

                &.columns {
                    padding-left: 1.563rem;
                    padding-right: 1.563rem;
                }
            }

            @include breakpoint(medium up) {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 14px;
            line-height: 1;
            margin: 0 0 0.3125rem;
        }

        .appointment-time {
            color: map-get($foundation-palette, midgrey);
        }
    }

    .print-link {
        @include font-gotham-bold;

        font-size: rem-calc(14);
        line-height: 1;
    }

    .sms-reminder-box {
        p {
            @include font-gotham-book;
            color: map-get($foundation-palette, midgrey);

            font-style: italic;
            margin: 0 0 1.563rem 0.625rem;

            @include breakpoint(medium up) {
                margin: 0 0 1.875rem;
            }
        }
    }

    .appt-info-box {
        align-items: center;
        border: 3px solid #f2f2f3;
        display: flex;
        padding: 0.9375rem 0.625rem;

        @include breakpoint(medium up) {
            border-width: 6px;
            padding: 1.25rem 0.9375rem;
        }

        .img-box {
            margin: 0 0.4375rem 0 0;
            width: 50px;

            @include breakpoint(medium up) {
                margin: 0 1.25rem 0 0;
            }
        }

        .text-box {
            width: calc(100% - 57px);

            @include breakpoint(medium up) {
                width: calc(100% - 70px);
            }
        }

        h3 {
            @include font-gotham-medium;

            font-size: rem-calc(14);
            margin: 0;

            @include breakpoint(medium up) {
                font-size: rem-calc(15);
            }
        }

        p {
            font-size: rem-calc(14);
            margin: 0;
        }
    }

    .order-information {
        border-bottom: 1px solid map-get($foundation-palette, light-gray);
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
        }

        .order-date {
            @include font-gotham-book;

            font-size: rem-calc(13);
            margin: 0 0 0 0.625rem;

            @include breakpoint(medium up) {
                margin: 0;
            }
        }

        .section-title-box {
            flex: 0 0 100%;
            margin-bottom: 20px;

            @include breakpoint(medium up) {
                border: 0;
                flex: 1 1 auto;
                margin: 0;
                padding: 0;
            }

            .amount-due-at-store {
                @include breakpoint (small only) {
                    @include font-gotham-book;
                }
            }
        }

        &-block {
            display: flex;
            flex-wrap: wrap;
        }

        &-locale {
            .section-title-box {
                margin-bottom: 20px;

                @media print {
                    margin-bottom: 5px;
                }

                .main-section-title {
                    margin-bottom: 20px;

                    @media print {
                        margin-bottom: 0;
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .cart-summary-box {
        @include breakpoint(small only) {
            padding: 1.5625rem 1.875rem;
        }

        @include breakpoint(medium up) {
            @include clearfix;
            border: 2px solid #f2f2f3;
            border-bottom-width: 0;
        }
    }

    .mini-billing-address {
        margin-bottom: 1.25rem;

        + .section-box {
            padding: 0;
        }
    }

    .confirmation-message-wrapper {
        margin: 20px auto 50px;

        @include breakpoint(medium up) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 auto 50px;
        }

        .header {
            @include breakpoint(medium up) {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }

        .checked-icon {
            border-radius: 50%;
            height: 27px;
            margin-right: 15px;
            width: 27px;

            @include breakpoint(medium up) {
                align-self: center;
                box-shadow: 0 0 0 3px map-get($foundation-palette, primary-accessible) inset;
                height: 28px;
                width: 28px;
            }

            &::before {
                align-items: center;
                border: 4px solid map-get($foundation-palette, primary-accessible);
                border-radius: 50%;
                color: map-get($foundation-palette, primary-accessible);
                content: "\f00c";
                display: flex;
                font-family: fontawesome;
                font-size: 15px;
                height: 27px;
                justify-content: center;
                margin: 0;
                width: 27px;

                @include breakpoint(medium up) {
                    font-size: rem-calc(15);
                    height: 28px;
                    width: 28px;
                }
            }
        }

        .title {
            align-items: baseline;
            display: flex;
            font-size: rem-calc(21);
            justify-content: flex-start;
            line-height: 29px;
            margin: 0;
            padding-bottom: 10px;
            text-align: left;

            @include breakpoint(medium up) {
                align-self: center;
                font-size: rem-calc(26);
                line-height: 33px;
            }
        }

        .subtitle {
            font-size: rem-calc(15);
            line-height: 25px;
            margin: 0;
            text-align: center;

            @include breakpoint(medium up) {
                text-align: left;
            }
        }
    }

    .appointment-details-wrapper {
        margin-bottom: 35px;

        .section-title-box {
            border: 0;
            margin-bottom: 20px;

            @include breakpoint(medium up) {
                padding: 0;
            }
        }

        .section-title {
            line-height: 20px;
            margin-bottom: 10px;
        }

        .appointment-data-time-locale {
            margin-bottom: 15px;
        }

        .appointment-data-time {
            p {
                @include font-gotham-medium;
                color: map-get($foundation-palette, primary-accessible);

                font-size: 20px;
                line-height: 27px;
                margin-bottom: 0;
            }
        }

        .appointment-time {
            display: none;
        }

        .location-box {
            margin-bottom: 15px;

            p {
                line-height: 22px;
                margin: 0;
            }
        }

        .appointment-button {
            @include breakpoint(medium up) {
                display: flex;
            }

            .button {
                margin-bottom: 15px;
            }

            .add-to-calendar {
                font-size: rem-calc(14);

                @include breakpoint(medium up) {
                    margin-right: 15px;
                    max-width: 196px;
                }
            }

            .print-link {
                @include breakpoint(medium up) {
                    max-width: 270px;
                }
            }
        }

        .section-box-wrap {
            margin-bottom: 10px;

            @include breakpoint(medium up) {
                margin-bottom: 30px;
            }
        }

        .reminder-info {
            color: map-get($foundation-palette, midgrey);
            font-size: rem-calc(14);
            line-height: 22px;
        }

        .main-section-title {
            @include breakpoint(medium up) {
                font-size: rem-calc(16);
                line-height: 20px;
            }
        }
    }

    .customer-care {
        flex: 0 0 100%;
        padding-top: 30px;

        @include breakpoint(medium up) {
            flex: 0 0 50%;
            padding-top: 0;
        }

        h3 {
            font-size: 14px;

            @include breakpoint(medium up) {
                margin-bottom: 15px;
            }
        }

        p {
            font-size: rem-calc(14);
            margin-bottom: 20px;
        }
    }

    .information-assets {
        border: 2px solid map-get($foundation-palette, white-ghost);
        display: flex;
        flex-wrap: wrap;
        padding: 30px 20px;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
            padding: 30px;
        }

        h3 {
            @include font-gotham-medium;

            font-size: rem-calc(16);
            line-height: 18px;
            margin-bottom: 20px;
        }

        p {
            line-height: 22px;
        }

        .appt-info {
            flex: 0 0 100%;

            .information-box,
            div {
                align-items: center;
                display: flex;
                margin-bottom: 10px;
            }

            div p {
                font-size: rem-calc(14);
                margin: 0;
                padding-left: 20px;
            }
        }

        .information-box {
            margin-bottom: 25px;

            div {
                align-items: center;
                display: flex;
            }

            p {
                font-size: rem-calc(14);
                margin: 0;
                padding-left: 20px;
            }

            img {
                max-height: 60px;
                max-width: 60px;
            }

            &:last-child:not(:first-child) {
                @include breakpoint(medium up) {
                    margin-bottom: 0;
                }
            }
        }

        &.assets-pdf {
            .appt-info-pdf {
                border-right: 1px solid map-get($foundation-palette, nobel);
            }

            .customer-care {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .order-confirmation-details {
        border: 2px solid map-get($foundation-palette, white-ghost);

        @media print {
            border: 0;
        }

        .order-summary-title {
            display: none;
        }

        .cart-summary-box {
            border-bottom: 2px solid map-get($foundation-palette, bright-grey);
            padding-bottom: 30px;

            @include breakpoint(medium up) {
                border: 0;
                border-bottom: 2px solid map-get($foundation-palette, white-ghost);
                padding-bottom: 0;
            }

            @media print {
                border: 2px solid map-get($foundation-palette, white-ghost);
            }
        }

        .order-payment-summary {
            border: 0;
            margin-bottom: 50px;
            padding: 1.875rem 1.875rem 0;

            @include breakpoint(medium up) {
                margin-bottom: 30px;
            }

            .levy-message-orderdetails {
                color: map-get($foundation-palette, midgrey);
                font-size: rem-calc(12);
                font-weight: bold;
                max-width: 360px;

                @include breakpoint(small only) {
                    margin-top: rem-calc(20);
                }
            }
        }

        .order-summary-header {
            padding: 1.5625rem 1.875rem;

            @media print {
                border: 2px solid map-get($foundation-palette, white-ghost);
                page-break-after: always;
            }
        }

        .line-items {
            .product-brand,
            .product-name {
                @include font-gotham-bold;

                @include breakpoint(medium up) {
                    @include font-gotham-book;
                }
            }

        }

        .productname-column {
            .line-items-title {
                display: none;
            }

            .order-summary-products-title {
                display: block;
            }
        }
    }

    .mini-address-location {
        display: flex;
        flex-wrap: wrap;

        p {
            flex: 0 0 100%;
        }

        .full-name {
            order: 0;
        }

        .email {
            order: 1;
        }

        .phone-number {
            order: 2;
        }

        .postal-code {
            display: none;
        }
    }

    .personal-payment-info {
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;

        @include breakpoint(medium up) {
            margin-top: 50px;

            .information-box {
                padding: 20px 0 0;

                &--full-padding {
                    padding: 20px;
                }

                &--grey-background {
                    background-color: map-get($foundation-palette, white-ghost);
                }
            }
        }

        .section-title-box {
            &--no-margin {
                margin: 0;
            }
        }

        .title {
            @include breakpoint(medium up) {
                font-size: rem-calc(16);
                line-height: 20px;
                margin-bottom: 10px;
            }

            &--orange {
                color: map-get($foundation-palette, primary-accessible);
            }
        }

        .payment-verification {
            @include breakpoint(medium down) {
                margin-left: 0;
                margin-right: 0;
            }

            .section-box {
                @include breakpoint(medium down) {
                    background-color: map-get($foundation-palette, white-ghost);
                    padding: 0 12px 15px;
                }
            }
        }

        .need-changes {
            display: flex;
            flex-wrap: wrap;

            @media print {
                display: none;
            }
        }
    }

    .payment-verification {
        p {
            display: inline;
        }
    }

    .order-totals-table {
        tr {
            font-size: rem-calc(14);
            @include breakpoint(medium up) {
                font-size: rem-calc(15);
            }
        }
    }
}

.product-detailed-info {
    line-height: 1.5;
    margin-top: 12px;
    text-transform: capitalize;

    .attribute-data {
        font-size: 0.875rem;
        margin: 0;
    }
}

.save-for-billing {
    display: none;
}

.monerisInput {
    border-color: rgb(204, 204, 204);
    border-style: solid;
    border-width: 1px;
    display: block;
    height: 40px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}

#monerisNameInput {
    width: 255px;
}

.checkout-billing .checkout-fields-container div:first-child,
.checkout-billing .checkout-fields-container div:first-child + div {
    display: none;
}

// Mini header style
.mini-header {
    background-color: map-get($foundation-palette, white-ghost);

    .header-logo {
        padding: 16px 0;

        @include breakpoint(medium up) {
            padding: 26px 0;
        }
    }
}

.checkout-personal-info {
    .account-info-checkout {
        @include font-gotham-book;
        font-size: 14px;

        line-height: 1px;
    }

    .account-info-checkout-label {
        @include font-gotham-medium;
        display: block;
        font-size: 14px;

        line-height: 20px;
        margin-top: 20px;
    }

    .account-info-form {
        display: none;
    }

    &.expanded {
        .account-info-form {
            display: block;
        }

        .account-info-checkout,
        .account-info-checkout-label,
        .name-length-error {
            display: none;
        }

        .account-info-form-toggle {
            display: none;
        }
    }
}

.required-field::after {
    content: "*";
    margin-left: 3px;
}
