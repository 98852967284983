//Buttons

.button {
    @include font-gotham-bold;

    background: map-get($foundation-palette, primary);
    border: 3px solid map-get($foundation-palette, primary);
    color: map-get($foundation-palette, secondary);
    font-size: 13px;
    padding: 15px 18px;
    text-transform: uppercase;

    &.button-dark {
        background: $button-dark;
    }

    &:hover {
        background: $button-accessible-hover;
    }

    &.button-dark:hover {
        background: $button-hover-dark;
    }

    &.hollow {
        background: transparent;
        border: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, secondary);

        &:hover,
        &:focus {
            background: $button-accessible-hover;
            border: 3px solid map-get($foundation-palette, primary);
            color: map-get($foundation-palette, secondary);
        }
    }

    &.button-dark.hollow {
        color: map-get($foundation-palette, white-default);
    }

    &.close-hollow-btn {
        .active-btn-text {
            display: none;
        }

        &.is-active {
            background: map-get($foundation-palette, primary-accessible);
            border-color: map-get($foundation-palette, primary-accessible);
            color: map-get($foundation-palette, white-default);
            position: relative;

            &::after {
                @include vertical-center;
                content: "\e807";
                font-family: "fontello";
                font-size: 17px;
                position: absolute;
                right: 15px;
            }

            .active-btn-text {
                display: block;
            }

            .btn-text {
                display: none;
            }
        }
    }

    &.secondary {
        background: map-get($foundation-palette, white-default);

        &.hollow {
            background: transparent;
            border: 3px solid map-get($foundation-palette, primary);
            color: map-get($foundation-palette, white-default);

            &:hover {
                background: map-get($foundation-palette, primary);
                border: 3px solid map-get($foundation-palette, primary);
                color: map-get($foundation-palette, secondary);
            }
        }

        &:hover {
            background: #313131;
        }
    }

    &.button-dark.secondary.hollow {
        color: map-get($foundation-palette, white-default);
    }

    &.disabled,
    &:disabled,
    &[disabled] {
        background: map-get($foundation-palette, midgrey);
        opacity: 0.5;

        &:hover {
            background: map-get($foundation-palette, midgrey);
            opacity: 0.5;
        }
    }

    &.small {
        font-size: 11px;
        padding: 13px 15px;
    }

    &.medium {
        font-size: 13px;
        padding: 15px 18px;
    }

    &.large {
        font-size: 14px;
        padding: 16px 20px;
    }

    &.full-width {
        margin-top: 1rem;
        width: 100%;
    }

    &.hellobar {
        @include font-gotham-medium;

        color: map-get($foundation-palette, white-default);
        margin: 0;
        padding: 0;
        text-transform: none;

        @include breakpoint(medium up) {
            @include font-gotham-bold;
            background: map-get($foundation-palette, white-default);
            color: map-get($foundation-palette, primary-accessible);

            font-size: 11px;
            font-size: 11px;
            margin: 0 15px;
            padding: 12px 14px;
            text-transform: uppercase;
        }
    }

    &.alternate {
        color: $white;

        &:hover {
            color: $black;
        }
    }
}

// view on vehicle button
.v-view-on-vehicle {
    &.disabled {
        .button {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .link-above-product {
        background: map-get($foundation-palette, midgrey);
    }
}

// Badges
.tested-bykal-badge {
    margin: 0 0 10px;

    p.tested-bykal-text {
        @include font-gotham-bold;
        align-items: center;
        display: flex;

        font-size: 12px;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
    }

    .icon-tested-by-kal {
        color: map-get($foundation-palette, primary);
        font-size: 20px;
    }
}

.tested-bykal-container {
    display: inline-block;

    p {
        display: inline-block;
        margin: 0;
        padding: 0;

        .icon-tested-by-kal {
            color: map-get($foundation-palette, primary);
            font-size: 20px;
            vertical-align: middle;

            &::before {
                margin-right: 0;
            }
        }

        &.tested-bykal-text {
            @include font-gotham-bold;

            font-size: 12px;
            line-height: 1;
            text-transform: uppercase;
        }
    }
}

.product-badge {
    margin: 0 0 10px;

    span {
        @include font-gotham-bold;
        background: map-get($foundation-palette, light-gray);
        display: inline-block;

        font-size: 12px;
        line-height: 1;
        margin: 0;
        margin: 7px 0;
        padding: 10px;
        text-transform: capitalize;

        &.product-image-badge {
            display: inline-block;
            line-height: initial;
            padding: 5px 5px 4px;

            img {
                height: 23px;
                width: 23px;
            }
        }
    }
}

.pt_product-details,
.pt_product-compare {
    .product-winter-use {
        position: relative;
        right: initial;
    }
}

.pt_product-compare {
    .product-winter-use {
        text-align: right;
        top: 5px;
    }
}

.product-winter-use {
    position: absolute;
    right: 8px;
    top: 3px;
    z-index: 0;

    i {
        color: map-get($foundation-palette, primary);
        font-size: 23px;
    }
}

.overview {
    i.icon-snowflake {
        margin-left: -3px;
    }
}

// Loader
.loader {
    animation: rotate 0.8s infinite linear;
    animation: rotate 0.8s infinite linear;
    border: 8px solid map-get($foundation-palette, primary-accessible);
    border-radius: 50%;
    border-right-color: transparent;
    bottom: 0;
    display: none;
    height: 3em;
    left: 0;
    margin: 0 auto 50px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 3em;

    &.store-details-loader {
        bottom: -50px;
        top: auto;
    }
}

.error-text-box {
    background-color: map-get($foundation-palette, unavailable);
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 10px 10px 9px;

    p.text {
        color: map-get($foundation-palette, unavailable-text);
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        padding: 0;
    }
}

#productSelectorSlotWrapper .selector .loader {
    z-index: -1;
}

#productSelectorSlotWrapper.expanded .selector .loader {
    z-index: 99;
}

#psmodal #productSelectorSlotWrapper .selector .loader {
    z-index: 99;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// Fluid Width Video
.videoWrapper {
    height: 0;
    padding-bottom: 56.25%; // 16:9
    padding-top: 25px;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Category indicator
.product-attributes {
    .category {
        display: inline-block;
        line-height: 1;
        text-transform: capitalize;

        &:first-of-type {
            margin: 0 10px 0 0;
        }

        &::before {
            content: "";
            display: inline-block;
            height: 10px;
            margin: 0 5px 0 0;
            width: 10px;
        }
    }

    .cat-3-season,
    .cat-all-season {
        &::before {
            background: #fbb116;
        }
    }

    .cat-performance {
        &::before {
            background: #6353a3;
        }
    }

    .cat-all-weather {
        &::before {
            background: #bf1a8d;
        }
    }

    .cat-winter {
        &::before {
            background: #20c3f3;
        }
    }

    .cat-touring {
        &::before {
            background: #00a651;
        }
    }

    .cat-sport-truck {
        &::before {
            background: #7c86c2;
        }
    }

    .cat-all-terrain {
        &::before {
            background: #7a5226;
        }
    }

    .cat-mud-terrain {
        &::before {
            background: #422800;
        }
    }

    .cat-commercial {
        &::before {
            background: #a7a9ac;
        }
    }

    .cat-ultra-high-performance {
        &::before {
            background: #a499ca;
        }
    }
}


// general styles for accordion with plus/minus icons
.plus-minus-accordion {
    .is-accordion-submenu-parent {
        border-bottom: 1px solid #d9d9da;

        &:first-child {
            border-top: 1px solid #d9d9da;
        }

        > a {
            @include font-gotham-bold;
            color: #000;

            font-size: rem-calc(16);
            padding: 25px 0;
            text-transform: uppercase;

            &::after {
                border: 0;
                color: map-get($foundation-palette, primary-accessible);
                content: "\e80b";
                font-family: "fontello";
                font-size: rem-calc(16);
                height: auto;
                margin: 0;
                margin-top: -8px;
                position: absolute;
                right: 0;
                top: 50%;
                width: auto;
            }
        }
    }

    .is-accordion-submenu-parent[aria-expanded="true"] > a::after {
        content: "\e80c";
        font-family: "fontello";
    }

    .submenu {
        display: none;
        margin: 0 0 20px;
    }
}


a.inactive {
    cursor: default;
}


// Separator line (in the middle of a column) between two sections
.line-sections-separator {
    margin: 50px 0;
    position: relative;

    @include breakpoint(medium up) {
        margin: 0;
    }

    &::before {
        background: rgba(128, 130, 133, 0.3);
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        margin: auto 0;
        right: 0;
        top: 0;
        width: 100%;

        @include breakpoint(medium up) {
            height: 100%;
            margin: 0 auto;
            position: absolute;
            width: 1px;
        }
    }
}

// Modal styles
.globalModalHeader {
    align-items: center;
    background: map-get($foundation-palette, primary-accessible);
    color: map-get($foundation-palette, white-detault);
    display: flex;
    height: 72px;
    min-height: 72px;
    padding: 0 60px 0 25px;
    position: relative;

    .close-button {
        bottom: 0;
        color: map-get($foundation-palette, white-default);
        margin: auto;
        position: absolute;
        right: 25px;
        top: 0;
    }

    h2 {
        @include font-gotham-bold;
        align-items: center;
        color: map-get($foundation-palette, white-default);
        display: flex;
        flex-direction: inherit;
        flex-wrap: nowrap;

        font-size: rem-calc(21);
        margin: 0;
        text-transform: uppercase;

        span.questionmarkcircle {
            border: 3px solid map-get($foundation-palette, white-default) !important;
            font-size: rem-calc(20);
            height: 32px;
            margin-right: 10px;
            min-width: 32px;
            width: 32px;
        }
    }
}

.financingModal {
    .globalModalHeader {
        h2 {
            font-size: rem-calc(18);

            span.questionmarkcircle {
                font-size: rem-calc(16);
                height: 26px;
                min-width: 26px;
                width: 26px;
            }
        }
    }
}

.globalModalContent {
    padding: 30px;

    p {
        @include font-gotham-book;

        font-size: rem-cal(16);

        strong {
            @include font-gotham-medium;
        }
    }
}

.financingModal {
    .globalModalContent {
        padding: 30px 62px;

        @include breakpoint(small down) {
            padding: 30px;
        }
    }
}

.financingModalButton {
    float: right;
    margin-bottom: 15px !important;
    padding-right: 20px;

    @include breakpoint(small down) {
        float: none;
        padding-right: 0;
        text-align: center;
    }

    a {
        font-size: 11px;
        min-width: 170px;

        @include breakpoint(small down) {
            min-width: 140px;
        }
    }

    .cb-cta {
        margin-top: 0;
        text-align: center;
    }

    .cb-cta {
        &.button-hollow-black {
            padding: 7px 0 8px;
        }
    }

    .button {
        padding: 12px 18px;
        vertical-align: unset;
    }
}

// Product tile - pricing and size
// Those styles are used for grid/compare page/Replace products Pop-up
.product-tile {
    .product-size,
    .product-pricing {
        float: left;
        line-height: 1;
        width: 50%;

        &.trailer-tile-info {
            float: none;
            padding: 0;
            width: auto;

            .attribute {
                font-size: 12px;
                margin-bottom: 15px;
            }

        }

        &.trailer-pricing {
            margin-top: 35px;

            .price-label {
                font-size: 12px;
                margin-bottom: 15px;
            }
        }
    }

    .product-size {
        .attribute {
            font-size: rem-calc(14);
            line-height: 1;
            margin: 0;
        }

        .attr-column {
            .attribute {
                margin: 0 0 5px;
            }
        }
    }

    .product-pricing {
        @include font-gotham-bold;

        font-size: rem-calc(35);

        .currency-indices {
            font-size: rem-calc(17);
        }

        .price-sales {
            color: map-get($foundation-palette, secondary);
        }

        .price-standard {
            color: map-get($foundation-palette, primary);
            display: block;

            + .price-sales {
                @include font-gotham-medium;
                color: map-get($foundation-palette, midgrey);
                float: left;

                font-size: rem-calc(13);
                margin: 2px 0 0;
                text-decoration: line-through;

                .currency-indices {
                    font-size: rem-calc(13);
                }
            }
        }

        .rebate {
            @include font-gotham-medium;
            color: map-get($foundation-palette, primary);

            font-size: rem-calc(12);
        }
    }

    .size-label,
    .price-label {
        @include font-gotham-bold;
        color: #7a7c80;
        display: block;

        font-size: rem-calc(11);
        line-height: 1;
        margin: 0 0 8px;
        text-transform: uppercase;
    }
}

sub,
sup {
    font-size: 65%;
}

// Main Live Chat Bubble Icon
.mainNavLiveChatCTA.openLiveChatButton {
    @include font-gotham-bold;
    align-content: center;
    align-items: center;
    background-color: $black;
    bottom: calc(30% - 17px);
    color: $white;
    display: flex;
    font-size: rem-calc(12);
    height: rem-calc(34);
    justify-content: center;
    line-height: rem-calc(14);
    padding: rem-calc(10);
    position: fixed;
    right: 0;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    z-index: 999;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    span {
        vertical-align: text-bottom;
    }
}

.header-nav.mobile-chat-btn {
    height: 0;

    .headerCartWrapper,
    .headerSearchWrapper {
        display: none;
    }
}

// Live Chat
.openLiveChatButton {
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

// CSC Agent Message
.csc-agentmessage {
    color: map-get($foundation-palette, error);
    font-size: 20px;
    margin: 15px 0;
    text-align: center;
}

// Accordion content assets
.accordion {
    border-top: 1px solid $light-gray-over;
    margin-top: rem-calc(21);
    width: 100%;

    .toggle-item {
        border-bottom: 1px solid $light-gray-over;
        padding: rem-calc(23) 0;
        position: relative;

        input[type="checkbox"] {
            cursor: pointer;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .item-content {
            margin-top: rem-calc(15);
        }

        summary {
            font-size: rem-calc(16);
        }

        summary::before {
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(20);
            line-height: rem-calc(16);
            margin-inline-end: 5px;
        }

        input[type="checkbox"]:checked ~ .item-content {
            display: none;
        }

        input[type="checkbox"]:checked ~ summary::before {
            content: "+";
        }

        input[type="checkbox"] ~ summary::before {
            content: "-";
        }
    }
}
