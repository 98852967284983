/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?62751503#fontello') format('svg');
  }
}
*/
[class^="icon-"]::before,
[class*=" icon-"]::before {

    display: inline-block;
    font-family: "fontello";

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    font-weight: normal;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    margin-right: 0.2em;
    speak: never;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    width: 1em;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-alert::before { content: "\e800"; } /* '' */
.icon-cart::before { content: "\e801"; } /* '' */
.icon-customercareplan::before { content: "\e802"; } /* '' */
.icon-livechat::before { content: "\e803"; } /* '' */
.icon-location::before { content: "\e804"; } /* '' */
.icon-search::before { content: "\e805"; } /* '' */
.icon-severeweatherdesignation::before { content: "\e806"; } /* '' */
.icon-cancel::before { content: "\e807"; } /* '' */
.icon-wheelwarranty::before { content: "\e808"; } /* '' */
.icon-myaccount::before { content: "\e809"; } /* '' */
.icon-contact::before { content: "\e80a"; } /* '' */
.icon-plus::before { content: "\e80b"; } /* '' */
.icon-minus::before { content: "\e80c"; } /* '' */
.icon-star::before { content: "\e80d"; } /* '' */
.icon-up-open::before { content: "\e80e"; } /* '' */
.icon-down-open::before { content: "\e80f"; } /* '' */
.icon-accessibility::before { content: "\e810"; } /* '' */
.icon-circle-checked::before { content: "\e811"; } /* '' */
.icon-print::before { content: "\e812"; } /* '' */
.icon-left-open::before { content: "\e813"; } /* '' */
.icon-right-open::before { content: "\e814"; } /* '' */
.icon-thumbs-down::before { content: "\e815"; } /* '' */
.icon-tire::before { content: "\e816"; } /* '' */
.icon-calendar::before { content: "\e817"; } /* '' */
.icon-phone::before { content: "\e818"; } /* '' */
.icon-lock::before { content: "\e819"; } /* '' */
.icon-tested-by-kal::before { content: "\e820"; } /* '' */
.icon-thumbs-up::before { content: "\e821"; } /* '' */
.icon-star-empty::before { content: "\e822"; } /* '' */
.icon-play::before { content: "\e823"; } /* '' */
.icon-wheel::before { content: "\e824"; } /* '' */
.icon-maintenance::before { content: "\e825"; } /* '' */
.icon-seasonal-all::before { content: "\e826"; } /* '' */
.icon-gears::before { content: "\e827"; } /* '' */
.icon-book::before { content: "\e828"; } /* '' */
.icon-filter::before { content: "\e829"; } /* '' */
.icon-calendar-icon::before { content: "\e82c"; } /* '' */
.icon-snowflake::before { content: "\e82d"; } /* '' */
.icon-buynowpaylater::before { content: "\e82e"; } /* '' */
.icon-angle-left::before { content: "\f104"; } /* '' */
.icon-angle-right::before { content: "\f105"; } /* '' */
.icon-angle-up::before { content: "\f106"; } /* '' */
.icon-angle-down::before { content: "\f107"; } /* '' */
.icon-circle::before { content: "\f111"; } /* '' */
.icon-circle-thin::before { content: "\f1db"; } /* "" */
.icon-star-half-alt::before { content: "\f123"; } /* '' */
.icon-wifi::before { content: "\f1eb"; } /* '' */
.icon-bus::before { content: "\f207"; } /* '' */
.icon-exchange::before { content: "\e82f"; } /* '' */
.icon-price-match-guarantee-line::before { content: "\e860";} /* '' */
.icon-tick::before {content: "\e831";} /* '' */
.icon-customer-care-plan-line::before {content: "\e832";} /* '' */
.icon-brakes-quality-parts::before {content: "\e833";} /* '' */
.icon-brakes-no-surprises::before {content: "\e834";} /* '' */
