// Begin: Appointment Calendar
.appointments {
    &.appointments-wrap {
        margin: 0 0 1.875rem;

        @include breakpoint(medium up) {
            border-left: 1px solid #979797;
            margin: 50px 0 75px;
            padding-left: 44px;
        }
    }

    .main-title {
        border-top: 1px solid #d9d9da;
        font-size: rem-calc(16);
        line-height: 1;
        margin: 0 0 0.625rem;
        padding-top: 50px;

        @include breakpoint(medium up) {
            border: 0;
            font-size: rem-calc(18);
            padding-top: 0;
        }
    }

    .appointment-duration {
        color: map-get($foundation-palette, midgrey);
        font-size: rem-calc(14);
        margin: 0 0 1.563rem;
    }

    .appointment-calendar {
        position: relative;

        .loader {
            bottom: -4em;
            top: auto;
        }
    }

    .clndr {
        border: 1px solid map-get($foundation-palette, white-smoke);
        padding-bottom: 18px;
    }

    .clndr-controls {
        @include clearfix;
        border-bottom-width: 0;
        display: flex;
        justify-content: center;
        padding: 1.875rem 0 1.25rem;
        position: relative;

        .month {
            @include font-gotham-medium;

            font-size: rem-calc(14);
        }
    }

    .clndr-previous-button,
    .clndr-next-button {
        cursor: pointer;
        float: left;
        text-indent: -9999px;
    }

    .clndr-previous-button,
    .clndr-next-button {
        width: 20px;

        &::before {
            color: map-get($foundation-palette, midgrey);
            font-family: "fontello";
            font-size: 12px;
            padding-top: 3px;
            position: absolute;
            text-indent: 0;

            @include breakpoint(medium up) {
                color: $black;
            }
        }
    }

    .clndr-previous-button {
        &::before {
            content: "\e813";
            left: 15px;

            @include breakpoint(medium up) {
                left: 20px;
            }
        }
    }

    .clndr-next-button {
        &::before {
            content: "\e814";
            right: 15px;

            @include breakpoint(medium up) {
                right: 20px;
            }
        }
    }

    .clndr-table {
        border-collapse: separate;
        margin: 0;

        thead {
            background: transparent;
            border: 1px solid #ddddde;
            border-top-width: 0;
        }

        tbody {
            tr {
                background: transparent;
            }

            td {
                cursor: pointer;
                padding: 0;
            }
        }

        td {
            font-size: rem-calc(14);
            line-height: 1;
            text-align: center;
        }

        .clndr-controls {
            &.week {
                padding: 20px 0 15px;
            }
        }

        .header-day {
            @include font-gotham-book;
            color: map-get($foundation-palette, midgrey);

            font-size: rem-calc(10);
            padding: 0 0 0.625rem;

            &:first-child {
                border-left: 1px solid #ddddde;
            }

            &:last-child {
                border-right: 1px solid #ddddde;
            }
        }

        .event {
            .w-day,
            .month-day {
                @include font-gotham-bold;
            }
        }

        .day {
            .month-view {
                @include breakpoint(medium up) {
                    padding: 11px;
                }

                @include breakpoint(large up) {
                    font-size: rem-calc(18);
                    padding: 15px;
                }

                @media only screen and (min-width: 1024px) and (max-width: 1100px) {
                    padding: 7px;
                }

                @media only screen and (min-width: 1100px) and (max-width: 1319px) {
                    padding: 9px;
                }
            }

            &.event:hover {
                .day-contents {
                    border: 1px solid map-get($foundation-palette, primary-accessible);
                }
            }

            &:not(.event) {
                color: map-get($foundation-palette, midgrey);
                cursor: not-allowed;
            }

            &.active-event {
                .day-contents {
                    background-color: map-get($foundation-palette, primary-accessible);
                    border-color: map-get($foundation-palette, primary-accessible);
                    color: map-get($foundation-palette, white-default);
                    padding: 0;
                }
            }
        }

        .first-day {
            padding: 0;
        }

        .month-day {
            font-size: 10px;
            line-height: 1;
            margin: 0;
            width: 100%;
        }

        .day-contents {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            height: 29px;
            justify-content: center;
            margin: auto;
            width: 29px;

            @include breakpoint(medium up) {
                height: 33px;
                width: 33px;
            }
        }

        .w-day {
            font-size: rem-calc(16);
            margin: 0;
        }

        .week-view {
            .controll-wrapper {
                padding: 0;
            }
        }
    }

    .clndr-tabs {
        display: flex;

        .tab {
            @include font-gotham-medium;
            border-bottom: 1px solid map-get($foundation-palette, white-smoke);
            color: map-get($foundation-palette, midgrey);
            cursor: pointer;

            flex: 0 0 50%;
            font-size: rem-calc(13);
            padding: 10px 0;

            &.active {
                background-color: map-get($foundation-palette, primary-accessible);
                border-color: map-get($foundation-palette, primary-accessible);
                color: $white;
            }
        }

        .month-tab {
            border-left: 0;
        }
    }

    .appointment-error-message p {
        @include font-gotham-book;
        color: map-get($foundation-palette, error);

        font-size: rem-calc(14);
        line-height: 1.2;
    }

    .appointment-date .appointments,
    .appointment-date-time,
    .no-available-appointments {
        border: 1px solid #ddddde;
        border-top-width: 0;
        padding: 1.563rem 1.25rem 0;

        @include breakpoint(medium up) {
            padding: 1.875rem 1.875rem 0;
        }

        .appointment-date-title {
            @include font-gotham-bold;
            display: block;

            font-size: rem-calc(14);
            line-height: 1;
            margin: 0 0 0.625rem;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
            }
        }

        span:not(.appointment-date-title) {
            @include font-gotham-book;

            font-size: rem-calc(14);
            line-height: 1;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
            }
        }
    }

    .appointment-date {
        span:not(.appointment-date-title) {
            border: 1px solid map-get($foundation-palette, white-default);
            cursor: pointer;
            float: left;
            margin: 0 0 0.3125rem;
            padding: 0.625rem 0.9375rem;
            text-align: center;
            width: 50%;

            @include breakpoint(medium up) {
                margin: 0 0 0.9375rem;
                width: 16.6%;
            }

            &:hover {
                border: 1px solid map-get($foundation-palette, primary-accessible);
            }
        }

        .appointments {
            display: flex;
            flex-wrap: wrap;
            padding: 20px;

            .appointment-date-title {
                flex: 0 0 100%;
            }

            span {
                flex: 0 0 33%;
                padding: 10px 0;

                @include breakpoint(medium up) {
                    flex: 0 0 25%;
                }

                &.appointment-active {
                    background-color: map-get($foundation-palette, primary-accessible);
                    border: 1px solid map-get($foundation-palette, primary-accessible);
                    color: $white;
                }
            }
        }
    }

    .appointment-date-time {
        display: none;
        padding-bottom: 1.563rem;

        @include breakpoint(medium up) {
            border: 0;
            padding: 1.875rem 0 0.9375rem;
        }

        .appointment-date-title {
            margin: 0;

            @include breakpoint(medium up) {
                display: inline-block;
            }
        }
    }

    .edit-appointment {
        @include font-gotham-bold;

        font-size: rem-calc(13);

        @include breakpoint(medium up) {
            font-size: rem-calc(15);
        }
    }

    .appt-expiry {
        margin: 1.25rem 0 0;

        p {
            color: map-get($foundation-palette, midgrey);
            font-size: rem-calc(14);
            margin: 0;
        }
    }

    .need-help-checkout-appointment {
        display: flex;
        flex-wrap: wrap;
        margin: 50px 0 20px;

        @include breakpoint(medium up) {
            margin: 20px 0 0;
        }

        p {
            margin-bottom: 10px;

            @include breakpoint(medium up) {
                margin: 0;
                padding-right: 5px;
            }
        }

        a {
            font-size: rem-calc(13);
            font-weight: bold;
        }
    }
}

.appointment-buttons-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 0 50px;

    @include breakpoint(medium up) {
        flex-wrap: nowrap;
    }

    @include breakpoint(large up) {
        margin: 0;
    }

    .button {
        margin: 0;
    }

    &.with-appointment-date-time {
        @include breakpoint(medium up) {
            justify-content: space-between;
        }
    }

    .appointment-date-time {
        margin-right: auto;
    }

    .appointment-continue {
        @include breakpoint(small only) {
            width: 100%;
        }
    }

    .appointment-date-time {
        margin-bottom: 20px;
        max-width: 376px;

        @include breakpoint(medium up) {
            margin-bottom: 0;
        }
    }

    .appt-expiry {
        p {
            @include font-gotham-medium;

            font-size: 14px;
            margin: 0;
        }
    }
}
// End: Appointment Calendar

.appointment-page {
    .appointment-page-title {
        @include font-gotham-bold;

        margin: 0 0 1.25rem;

        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }

        @include breakpoint(medium up) {
            margin: 0 0 10px;
        }
    }

    .vertical-align {
        flex-wrap: wrap;
    }

    .start_location {
        border-right: 0;
        flex: 1;

        @include breakpoint(medium up) {
            height: 45px;
        }
    }

    .search-store {
        margin: 0;
        max-width: 40px;
        padding: 13px 0;

        @include breakpoint(medium up) {
            height: 45px;
            max-width: 134px;
        }
    }

    #map-box {
        margin: 0.625rem 0;
        width: 100%;

        @include breakpoint(medium up) {
            margin: 1.25rem 0 0.625rem;
        }
    }

    .toggle-map {
        flex: 0 0 100%;
        line-height: 39px;
        margin: 15px 0 0;
        max-width: 100%;
        padding: 0;

        @include breakpoint(medium up) {
            margin: 0 0 0 15px;
            max-width: 139px;
            padding: 0 15px;
        }
    }

    .map-view {
        max-height: 360px !important;

        @include breakpoint(medium up) {
            max-height: 500px !important;
        }
    }

    .list-view-items {
        .location-item {
            border-bottom: 1px solid #d9d9da;
            position: relative;
        }

        .location-item-wrapper {
            padding: 50px 0 55px;
        }

        .more-times,
        .book-now {
            .button {
                margin-top: 20px;

                @include breakpoint(medium up) {
                    margin: 15px 0 0;
                }
            }
        }

        .more-times {
            &:not(.stored-here-btn) {
                padding-left: 7px;
            }

            .hollow {
                padding: 13px 15px;
            }
        }

        .stored-here-btn {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0 0;

            @include breakpoint(medium up) {
                margin: 0;
            }

            .button {
                margin: 0;

                @include breakpoint(medium up) {
                    max-width: 241px;
                }
            }
        }

        .stored-tires {
            align-self: center;
        }

        .book-now {
            padding-right: 7px;
        }

        .show-store-box {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }

        .show-store-info {
            background-image: url("../images/kaltire/listviewArrow.png");
            background-position: right center;
            background-repeat: no-repeat;
            height: 16px;
            width: 10px;
        }

        .highlight-outofstock-store {
            border: 2px solid map-get($foundation-palette, primary);
            transition: border-width 0.3s ease-in-out;

            &.transition-to-end {
                border-width: 0;
            }
        }
    }

    .list-view-items,
    .store-details-box {
        p {
            @include font-gotham-book;

            font-size: rem-calc(14);
            line-height: 1.3;
            margin: 0;

            @include breakpoint(medium up) {
                margin: 0 0 0.3125rem;
            }
        }
    }

    .location-item-name {
        @include font-gotham-bold;

        font-size: rem-calc(16);
        line-height: 1.3;

        @include breakpoint(medium up) {
            margin: 0 0 0.3125rem;
        }

        .nickname {
            @include font-gotham-book;
        }
    }

    .location-item-distance {
        color: map-get($foundation-palette, midgrey);
        display: block;
        margin-top: 5px;

        @include breakpoint(medium up) {
            font-size: rem-calc(12);
            margin-top: 0;
        }
    }

    .store-availability {
        @include font-gotham-book;

        font-size: rem-calc(14);
        line-height: 1.3;
        margin: 0.625rem 0 0;

        .availability-message {
            color: map-get($foundation-palette, midgrey);
            font-size: rem-calc(15);
        }

        .earliest-available {
            .slot-info {
                @include font-gotham-bold;
            }

            .searching-for-availability {
                color: $red;
                display: block;
                font-weight: bold;
            }

            .float-clear-both {
                clear: both;
            }
        }
    }

    .no-trailer-service {
        color: map-get($foundation-palette, midgrey);
        font-size: rem-calc(15);
        line-height: 1.3;
    }

    .rating-distance-box {
        @include breakpoint(medium up) {
            align-self: flex-start;
            text-align: right;
        }
    }

    .back-and-pagination-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 1.875rem 0;

        @include breakpoint(medium up) {
            justify-content: space-between;
            margin: 1.875rem 0 -1.875rem;
        }

        @include breakpoint(medium only) {
            margin-bottom: 50px;
        }

        .step-back-link {
            @include breakpoint(medium up) {
                margin: 0;
            }
        }

        .pagination {
            margin: 0;
        }
    }

    // Store Details
    .map-infobox {
        .store-back-box {
            margin: 20px 0 0;
        }

        .store-back {
            @include font-gotham-bold;

            font-size: rem-calc(13);
        }
    }

    .store-details-box {
        margin-top: 50px;

        .location-distance {
            padding: 20px 0;

            @include breakpoint(medium up) {
                padding: 10px 0;
            }
        }

        .more-info-box {
            margin: 0.625rem 0 1.875rem;

            .is-accordion-submenu-parent {
                border: 0;
            }
        }

        .more-info-title {
            font-size: rem-calc(14);
            padding: 0 0 0 1.25rem;
            text-transform: none;

            &::after {
                font-size: rem-calc(14);
                left: 0;
                right: auto;
            }
        }

        .submenu {
            margin: 0.625rem 0 0 1.25rem;
        }

        .phone-number {
            @include breakpoint(medium up) {
                @include font-gotham-bold;

                margin: 0.9375rem 0;
            }
        }
    }

    .booking-not-available {
        border-bottom: 1px solid #d9d9da;
        margin: 0 0 2.188rem;
        padding: 0 0 1.875rem;

        @include breakpoint(medium up) {
            border: 0;
            padding: 0;
        }

        h3 {
            @include font-gotham-bold;

            font-size: rem-calc(16);
            line-height: 1.3;
            margin: 0 0 0.3125rem;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: rem-calc(18);
            }
        }
    }

    .location-filters {
        padding-top: 15px;

        @include breakpoint(medium up) {
            max-width: 236px;
        }

        label {
            @include font-gotham-medium;

            font-size: rem-calc(14);
            margin: 0;
            padding: 13px 0 0 16px;
            position: absolute;
        }

        select {
            @include font-gotham-book;

            font-size: rem-calc(14);
            padding-left: 80px;
        }
    }

    .select-location-wrapper {
        position: relative;
    }
}


// Appointment time has taken - popup
// Appointment Released Messaging - popup
.appointment-popup-wrap {
    padding: 1.25rem;

    @include breakpoint(medium up) {
        padding: 1.875rem;
    }

    .close-button {
        color: map-get($foundation-palette, primary-accessible);
        right: 1.125rem;
        top: 0.9375rem;

        @include breakpoint(medium up) {
            right: 1.75rem;
            top: 1.438rem;
        }
    }

    h3 {
        @include font-gotham-bold;

        font-size: rem-calc(18);
        line-height: 1.2;
        margin: 0 0 1.563rem;
        width: 80%;
    }

    p {
        font-size: rem-calc(14);
        margin: 0 0 1.563rem;
    }

    .button {
        margin: 0;

        @include breakpoint(small only) {
            width: 100%;
        }
    }
}

// Style for storage modal from appointment step
#storageModal,
#trailerTireModal {

    @include breakpoint(medium up) {
        .bringing-traler-btn {
            order: 1;
        }

        .bringing-wheel-btn {
            order: 2;
        }
    }

    &.trailer-appointment-modal {
        height: auto;
        max-height: unset;
        min-height: unset;
    }

    .modal-content {
        padding: 30px 20px;

        @include breakpoint(medium up) {
            padding: 30px 0;
        }

        &.trailer-modal-content {
            padding-top: 0;

            @include breakpoint(medium up) {
                .title.step-title {
                    background-color: transparent;
                    color: map-get($foundation-palette, secondary);
                }
            }

            .close-button {
                color: map-get($foundation-palette, secondary);
                display: none;
            }

            .title {
                background-color: transparent;
                color: map-get($foundation-palette, secondary);
                font-size: 20px;
                text-align: center;
                text-transform: uppercase;

                @include breakpoint(medium up) {
                    background-color: $medium-light-gray;
                    color: map-get($foundation-palette, white-default);
                    font-size: 18px;
                    text-align: left;
                    text-transform: unset;
                }

                &.title-large {
                    display: none;
                }

                &.title-small {
                    border: 1px solid map-get($foundation-palette, nobel);
                    text-transform: none;
                }

                &.second-step-modal-title {
                    color: map-get($foundation-palette, white-default);
                    font-size: 16px;
                    padding-left: 12px;
                    text-align: left;
                }
            }

            .close-button,
            .return-step-button {
                @include breakpoint(medium up) {
                    background-color: $medium-light-gray;
                    color: map-get($foundation-palette, white-default);
                }
            }
        }
    }

    .main-modal-title {
        @include breakpoint(medium up) {
            &.second-step {
                display: none;
            }
        }
    }

    .secondary-modal-title {
        display: none;

        @include breakpoint(medium up) {
            &.second-step {
                display: block;
            }
        }
    }

    img:not(.trailer-image) {
        max-width: 68px;

        @include breakpoint(medium up) {
            max-width: 86px;
        }
    }

    p {
        @include font-gotham-book;
        font-size: rem-calc(16);

        padding: 10px 0 20px;
    }

    .button-wrapper {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
            justify-content: center;
        }

        .info-btn {
            @include font-gotham-book;
            background-color: $dark-gray-over;

            font-size: 14px;
            margin: 10px 0;
            padding: 15px 0;
            text-align: center;
            width: 100%;

            @include breakpoint(medium up) {
                background-color: transparent;
                margin: 0;
                max-width: 50%;
                padding: 0;
                text-align: left;
                width: auto;
            }
        }

        &.trailer-button {
            align-items: center;
            justify-content: space-around;
            min-height: 233px;

            @include breakpoint(medium up) {
                height: 233px;
            }

            &.trailer-info-buttons {
                align-items: flex-start;
                border-bottom: 1px solid map-get($foundation-palette, nobel);
                border-left: 1px solid map-get($foundation-palette, nobel);
                border-right: 1px solid map-get($foundation-palette, nobel);
                flex-direction: column;
                padding: 0 35px;
                text-align: left;

                @include breakpoint(medium up) {
                    border: 0;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    .are-stored-btn {
        margin-bottom: 10px;

        @include breakpoint(medium up) {
            margin: 0 15px 0 0;
            max-width: 152px;
        }
    }

    .not-stored-btn {
        @include breakpoint(medium up) {
            max-width: 225px;
        }
    }

    .bringing-traler-btn,
    .bringing-wheel-btn {
        background-color: map-get($foundation-palette, primary-accessible);
        color: map-get($foundation-palette, white-default);
        padding: 16px 10px;

        @include breakpoint(medium up) {
            background-color: transparent;
            border-color: map-get($foundation-palette, midgrey);
            color: map-get($foundation-palette, secondary);
            height: 100%;
            max-height: 48px;
            max-width: 236px;
            padding: 7px 10px;
        }
    }

    .trailer-modal-step {
        display: none;

        &.current {
            display: block;

            @include breakpoint(medium up) {
                .return-step-button {
                    display: none;
                }
            }

            .title.title-small {
                color: map-get($foundation-palette, primary-accessible);
            }

            .modal-header,
            .button-wrapper {
                display: flex;

                &.mobile-title-tell-us-more {
                    display: none;
                }
            }
        }

        .mobile-title-tell-us-more {
            background-color: map-get($foundation-palette, primary-accessible);
            color: map-get($foundation-palette, white-default);

            &.second-step-active {
                display: flex;
            }
        }

        &.next-step.trailer-info {
            display: block;

            @include breakpoint(medium up) {
                display: none;
            }

            .title.title-small {
                color: map-get($foundation-palette, nobel);
            }

            .trailer-info-buttons {
                display: none;
            }
        }

        &.previous-step {
            display: block;
            padding-top: 50px;

            @include breakpoint(medium up) {
                display: none;
            }

            &.trailer-info {
                padding-top: 0;

                .trailer-info-buttons {
                    display: none;
                }
            }

            .title.title-small {
                color: map-get($foundation-palette, secondary);
            }
            
            .button-wrapper {
                display: flex;
                height: unset;

                .trailer-modal-btn {
                    pointer-events: none;
                }

                .bringing-traler-btn,
                .bringing-wheel-btn {
                    background-color: $medium-light-light-gray;
                    border: 0;
                    color: map-get($foundation-palette, white-default);
                    order: 1;

                    &.selected-step-option {
                        background-color: transparent;
                        border: 3px solid map-get($foundation-palette, primary-accessible);
                        color: map-get($foundation-palette, secondary);
                        margin-top: 50px;
                        order: 2;
    
                    }
                }

                .selected-step-option {
                    display: block;
                    pointer-events: none;

                }
            }
        }
    }

    .return-step-button {
        cursor: pointer;
        font-size: 2em;
        line-height: 1;
        padding-right: 27px;
        position: unset;
        right: 1rem;
        top: 0.5rem;

        @include breakpoint(medium up) {
            display: none;

            &.not-step-one {
                display: block;
            }
        }
    }
}

#licensePlateModal {
    .modal-content {
        padding: 0 25px;
    }

    #licensePlateSubmit {
        max-width: 250px;
    }

    #license-plate-number {
        padding: 0 65px;
    }

    p {
        font-family: $gotham-a-b;
        font-size: rem-calc(14);
        font-weight: 400;
        padding: 20px 0;
    }
}

#trailerTireModal {
    &.reveal {
        @include breakpoint(medium up) {
            width: 775px;
        }
    }

    .modal-header {
        @include breakpoint(medium up) {
            align-items: center;
            display: flex;
            margin: 30px 30px 0;
        }
    }

    .trailer-tire-header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .modal-title {
            font-size: 22px;

            @include breakpoint(small only) {
                padding-top: 10px;
            }
        }

        .close-button {
            background-color: transparent;
            color: map-get($foundation-palette, secondary);
        }
    }

    .modal-content.trailer-modal-content {
        .active-title .step-message {
            border-bottom: 2px solid map-get($foundation-palette, primary);
            color: $black;
            padding: 4px 0 2px;
        }

        .secondary-modal-title.disabled {
            color: map-get($foundation-palette, disabled-grey);
            cursor: auto;
        }

        .step-title {
            cursor: pointer;
            font-size: 16px;

            @include breakpoint(small only) {
                padding: 20px;
            }
        }

        .second-title {
            @include font-gotham-bold;

            display: none;
            font-size: 20px;
            font-weight: bold;
            line-height: 18px;

            @include breakpoint(small only) {
                border: 1px solid map-get($foundation-palette, nobel);
                border-top: 0;
                font-size: 14px;
                padding: 20px 0;
                text-align: center;
            }

            @include breakpoint(medium up) {
                margin: 30px 30px 0;
            }

            &.active {
                display: block;
            }
        }

        &.trailer-modal-content {
            @include breakpoint(medium up) {
                height: 400px;
                overflow-y: hidden;
            }

            button.close-button {
                background-color: transparent;
                color: map-get($foundation-palette, secondary);
                display: block;
            }
        }
    }

    .trailer-modal-step {
        display: block;

        .trailer-info-buttons {
            display: none;
        }
    }

    .secondary-modal-title {
        align-items: center;
        display: flex;
        font-size: 16px;

        @include breakpoint(small only) {
            justify-content: center;
        }

        .step-arrow {
            color: map-get($foundation-palette, disabled-grey);
            padding: 0 13px;
        }
    }

    #trailerTireModalExit {
        padding-right: 0;

        @include breakpoint(medium up) {
            left: 300px;
            position: relative;
            top: 0;
        }
    }

    .bringing-traler-btn,
    .bringing-wheel-btn {
        color: $black;
    }

    #bringingTrailer,
    #bringingTrailerWheel {
        align-items: center;
        display: flex;
        max-height: none;
        max-width: none;

        @include breakpoint(medium up) {
            border-left: 10px solid transparent;
        }

        @include breakpoint(small only) {
            margin: 10px 0;
            padding: 30px 20px;
        }

        &:hover {
            border-left: 10px solid map-get($foundation-palette, primary);
            padding-right: 10px;
        }

        img {
            height: auto;
            max-height: 90px;
            pointer-events: none;

            @include breakpoint(small only) {
                max-height: 60px;
                padding-right: 10px;
            }
        }
    }

    .trailer-modal-btn {
        background-color: $lighter-gray;
        border: 0;

        &:hover {
            border-left: 10px solid map-get($foundation-palette, primary);
            padding-right: 28px;
        }
    }

    .choose-action {
        &.current {
            .button-wrapper {
                @include breakpoint(medium up) {
                    display: grid;
                    grid-template-columns: repeat(2, 360px);
                }
            }
        }
    }

    .trailerType {
        &.current {
            .button-wrapper {
                @include breakpoint(medium up) {
                    display: grid;
                    grid-template-columns: repeat(2, 270px);
                    grid-template-rows: repeat(2, 76px);
                }
            }
        }
    }

    .trailerLength {
        &.current {
            .second-title {
                margin-top: 0;
            }

            .button-wrapper {
                @include breakpoint(medium up) {
                    display: grid;
                    grid-template-columns: repeat(3, 170px);
                }
            }
        }
    }
}

// Style for storage modal from appointment step
#appointmentSecure,
#selecteNewAppointment {
    .modal-content {
        padding: 20px;
    }

    .close-button {
        outline: none;
    }

    .title {
        @include font-gotham-medium;

        font-size: rem-calc(14);
        margin: 0;
    }

    img {
        max-width: 68px;

        @include breakpoint(medium up) {
            max-width: 86px;
        }
    }

    p {
        @include font-gotham-book;
        font-size: rem-calc(14);

        padding: 0 0 10px;
    }

    .button-wrapper {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
            justify-content: center;
        }
    }

    .are-stored-btn {
        margin-bottom: 10px;

        @include breakpoint(medium up) {
            margin: 0 15px 0 0;
            max-width: 152px;
        }
    }

    .not-stored-btn {
        @include breakpoint(medium up) {
            max-width: 225px;
        }
    }
}

.appointment-confirmation {
    margin-top: 50px;

    .title-wrapper {
        align-items: baseline;
        background-color: map-get($foundation-palette, light-gray);
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include breakpoint(medium up) {
            background-color: $white;
            margin-bottom: 10px;
        }
    }

    .title {
        @include font-gotham-medium;

        font-size: rem-calc(14);
        margin: 0;
    }

    .secudred-title {
        padding: 14px 0 14px 10px;

        @include breakpoint(medium up) {
            font-size: rem-calc(20);
            font-weight: bold;
            padding: 0;
        }
    }

    .restart-button {
        @include font-gotham-bold;
        color: map-get($foundation-palette, primary-accessible);

        font-size: rem-calc(13);
        margin: 0;
        padding-right: 10px;
    }

    .expiry {
        border-bottom: 1px solid map-get($foundation-palette, nobel);
        font-size: rem-calc(14);
        line-height: 22px;
        margin-bottom: 30px;
        padding: 0 10px 30px;

        @include breakpoint(medium up) {
            font-size: rem-calc(16);
            margin-bottom: 50px;
            padding: 0 0 50px;
        }
    }

    .appointment-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
        }
    }

    .appointment-date-time {
        flex: 0 0 100%;
        padding: 0 10px;

        @include breakpoint(medium up) {
            flex: 0 0 50%;
            padding: 0;
        }
    }

    .appointment-address-wrapper {
        flex: 0 0 100%;
        padding: 0 10px;

        @include breakpoint(medium up) {
            flex: 0 0 50%;
            padding: 0 0 0 20px;
        }
    }

    .location-header {
        @include breakpoint(medium up) {
            display: flex;
            justify-content: space-between;
        }
    }

    .selected-appointment-info {
        @include font-gotham-medium;
        color: map-get($foundation-palette, primary-accessible);

        font-size: rem-calc(20);
        padding: 10px 0;
    }

    .appointment-buttons {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 60px;
        }
    }

    .confirm-button {
        flex: 0 0 100%;
        margin: 0 0 20px;

        @include breakpoint(medium up) {
            flex: 0 0 50%;
            margin: 0;
            max-width: 196px;
            order: 1;
        }
    }

    .back-button {
        flex: 0 0 100%;
        margin: 0 0 50px;

        @include breakpoint(medium up) {
            flex: 0 0 50%;
            margin: 0;
            max-width: 196px;
            order: 0;
        }
    }

    .appointment-duration {
        color: map-get($foundation-palette, midgrey);
        margin: 0;
        padding: 0 0 10px;
    }

    .location-item-name,
    .location-item-address {
        @include font-gotham-book;

        font-size: rem-calc(14);
        line-height: 22px;
        margin: 0;
        padding: 0;
    }

    .location-item-name {
        text-transform: capitalize;
    }

    .location-title {
        padding-bottom: 10px;
    }

    .restart-appointment {
        cursor: pointer;
    }

    .city {
        text-transform: capitalize;
    }

    .zip-code,
    .state {
        text-transform: uppercase;
    }
}
