// In this file we will hide some sections that will not be delivered for the moment or will be some CR's in the future for those sections

// hide recommended badges from the top of tiles (grid page)
.search-result-content .product-tile .recommended-bagde {
    display: none !important;
}

// hide "How would you like to be reminded of your appointment?" section from Checkout Step 2
.checkout-shipping .selection-box.reminded-appointment {
    display: none !important;
}

// remove select arrow for Country field
// now we have just one country added automatically and the select field is not editable
.checkout-fields-container .country-row .input-select {
    background-image: none;
}

@media print {
    #inside_holder {
        display: none;
    }
}
