.pt_psresults {
    .header-main-nav::after {
        @include breakpoint(small only) {
            background-color: #ccc;
        }
    }

    .sel-info-wrapper {
        .small-12.columns.psresults-title-border {
            @include breakpoint(medium up) {
                padding: 0;
            }
        }
    }
}

span.questionmarkcircle {
    align-items: center;
    border: 2px solid map-get($foundation-palette, primary) !important;
    border-radius: 50%;
    color: map-get($foundation-palette, secondary);
    display: flex;
    font-size: rem-calc(12);
    font-weight: bold;
    height: 22px;
    justify-content: center;
    margin-right: 5px;
    width: 22px;

    &.questionmark-white {
        border-color: map-get($foundation-palette, white-default) !important;
    }
}

#GSPNoResults {
    display: none;
}

#psmodal {
    max-width: 700px;
    padding: 0;
    width: 100%;

    .close-button {
        color: map-get($foundation-palette, primary);
        top: 15px;
        z-index: 99;
    }

    .productSelector {
        &[data-selectortype="wheel_size"] .add-rear-size-cta,
        &[data-selectortype="tire_size"] .add-rear-size-cta {
            display: block !important;
        }

        .vehicleSelectorFindTire {
            display: none;

            &.trailer-tire-hint {
                display: block;
            }
        }

        .step.current {
            .vehicleSelectorFindTire {
                display: block;

                &.trailer-tire-hint {
                    display: block;
                }
            }
        }

        &[data-selectortype="tire_vehicle"],
        &[data-selectortype="wheel_vehicle"] {

            @include breakpoint(small only) {
                margin-top: 30px;
            }
        }
    }

    .selector {

        overflow: hidden;
        @include breakpoint(small only) {
            &[data-selectortype="wheel_size"] .add-rear-size-cta,
            &[data-selectortype="tire_size"] .add-rear-size-cta {
                display: block !important;
            }
        }
    }
}

.modal-content {
    #productSelectorSlotWrapper {
        padding: 0;

        .selector_options {
            margin-top: 0;
        }
    }

    #productSelectorContent {
        max-width: 100%;

        .selector {
            padding: 30px;
        }

        .selector_steps {
            margin-bottom: 10px;
        }
    }
}

.findTireSizeModalHeader {
    align-items: center;
    background: map-get($foundation-palette, primary);
    color: map-get($foundation-palette, white-detault);
    display: flex;
    height: 72px;
    min-height: 72px;
    padding: 0 60px 0 25px;
    position: relative;

    .close-button {
        bottom: 0;
        color: map-get($foundation-palette, white-default);
        margin: auto;
        position: absolute;
        right: 25px;
        top: 0;
    }

    h2 {
        @include font-gotham-bold;
        align-items: center;
        color: map-get($foundation-palette, white-default);
        display: flex;
        flex-direction: inherit;
        flex-wrap: nowrap;

        font-size: rem-calc(21);
        margin: 0;
        text-transform: uppercase;

        span.questionmarkcircle {
            border: 3px solid map-get($foundation-palette, white-default) !important;
            font-size: rem-calc(20);
            height: 32px;
            margin-right: 10px;
            min-width: 32px;
            width: 32px;
        }
    }
}

.findTireSizeModalContent {
    padding: 30px;

    p {
        @include font-gotham-book;

        font-size: rem-calc(16);

        strong {
            @include font-gotham-medium;
        }
    }
}

@include breakpoint(medium down) {
    .pt_storefront {
        .header-main-nav,
        .info-tile-wrapper,
        .info-tile-wrapper-spacearound,
        .footer-wrapper {
            background: #fff;
        }
    }
}

#productSelectorSlotWrapper,
#newSearchModal {
    padding: 35px 0;
    position: relative;

    @include breakpoint(medium up) {
        &.expanded {
            #PSOverlay {
                background: rgba(0, 0, 0, 0.5);
            }

            .main-selector-tabs {
                @include clearfix;
                display: block;

                .main-selector-tab {
                    margin: 0;
                    min-width: 200px;
                    padding: 0 40px;
                }
            }

            .ps-right {
                justify-content: center;

                #productSelectorContent {
                    max-width: 600px;
                }
            }

            .selector.active {
                min-height: 316px;
            }
        }
    }

    @include breakpoint(large up) {
        background-size: cover;
        padding: 180px 0;

        &.expanded {
            .ps-left {
                left: -50%;
                margin-left: -100%;
                opacity: 0;

            }

            .ps-right {
                left: 50%;
                width: 100%;

                #productSelectorContent {
                    max-width: 750px;
                }
            }

        }
    }

    .product-wrapper-background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;

        @include breakpoint(medium up) {
            height: 100%;
            position: absolute;
            width: 100%;
        }

        @include breakpoint(medium down) {
            height: 100vh;
            left: 0;
            position: fixed;
            width: 100vw;
            z-index: -1;
        }
    }

    .insideSelector {
        >a::after {
            content: "\f00d";
            font-family: fontawesome;
        }
    }

    .main-selector-tab {
        cursor: pointer;

        &.active {
            cursor: default;

            a {
                cursor: default;
            }
        }
    }

    &.expanded {
        .main-selector-tabs {
            @include breakpoint(medium up) {
                display: none !important;
            }
        }
    }

    &.no-tabs {
        padding: 60px 0;

        @include breakpoint(medium up) {
            padding: 70px 0;
        }

        @include breakpoint(large up) {
            padding: 90px 0;
        }

        .main-selector-tabs {
            display: block;

            @include breakpoint(medium up) {
                display: none;
            }
        }

        .is-accordion-submenu-parent > a {
            display: none;
        }

        &.expanded {
            .main-selector-tabs {
                @include breakpoint(medium up) {
                    display: none;
                }
            }
        }
    }

    .add-rear-size,
    .findTireSizeModalTrigger,
    .looking-for-other {
        @include font-gotham-medium;
        align-items: center;
        color: map-get($foundation-palette, primary);
        display: flex;
        flex-direction: inherit;
        flex-wrap: wrap;

        font-size: rem-calc(13);
        margin-top: 20px;
        text-transform: none;

        @include breakpoint(medium up) {
            bottom: -22px;
            left: 0;
            margin: 0;
            position: absolute;
        }
    }

    .looking-for-other {
        display: block;
        font-size: rem-calc(16);
        position: initial;
    }

    .add-rear-size {
        left: auto;
        right: 0;

        a {
            @include font-gotham-book;

            color: map-get($foundation-palette, primary);
            font-size: rem-calc(13);

            &::after {
                content: "\f105";
                font-family: "fontello";
                padding-left: 5px;
            }
        }
    }

    .rootlevelps {
        @include breakpoint(medium up) {
            display: block !important;
        }
    }

    .orbit-container {
        height: auto !important;
        outline: none;

        .orbit-slide {
            height: 100% !important;
            max-height: 100% !important;

            @include breakpoint(small down) {
                display: block !important;
            }

            &.is-active {
                display: block !important;
            }
        }
    }

    .pagedirect {
        @include breakpoint(medium up) {
            color: map-get($foundation-palette, midgrey);
            display: inline-block;

            i.icon-angle-left::before {
                margin-left: 0;
                padding-right: 6px;
                width: auto;
            }
        }
    }

    .selector_options {
        @include clearfix;
        overflow: hidden;

        @include breakpoint(medium up) {
            margin-top: 15px;
        }

        .paginated_results {
            @include clearfix;
            display: none;
            position: relative;

            @include breakpoint(medium up) {
                display: block;
            }

            &.columns-1 {
                .resultsCol {
                    width: 100%;
                }
            }

            &.columns-2 {
                .resultsCol {
                    width: 50%;
                }
            }

            &.columns-3 {
                .resultsCol {
                    width: 33.3%;
                }
            }

            &.columns-4 {
                .resultsCol {
                    width: 25%;
                }
            }

            &.columns-5 {
                .resultsCol {
                    width: 20%;
                }
            }

            .resultsCol {
                border-right: 1px solid map-get($foundation-palette, light-gray);
                float: left;
                list-style-type: none;
                margin: 0;
                padding: 0 6px;

                &:last-child {
                    border: 0;
                }


                a {
                    @include font-gotham-medium;
                    display: block;

                    font-size: rem-calc(13);
                    padding: 2px 6px;

                    &:hover {
                        background: map-get($foundation-palette, light-gray);
                        color: map-get($foundation-palette, primary);
                    }
                }
            }
        }
    }

    >.row {
        position: relative;
        z-index: 2;
    }
}

.wheeltype-selector-container {
    h3 {
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(17);
        line-height: rem-calc(17);
        margin: 0;
        padding-bottom: 15px;
        text-align: center;

        @include breakpoint(medium up) {
            font-size: rem-calc(20);
            line-height: rem-calc(20);
            padding: 5px 0 0;
            text-align: left;
        }
    }

    .wheeltype-options {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        @include breakpoint(medium up) {
            flex-wrap: nowrap;
        }

        .wheeltype-option {
            align-items: center;
            background-color: map-get($foundation-palette, light-gray);
            border-left: 10px solid transparent;
            display: flex;
            flex: 0 0 100%;
            flex-wrap: wrap;
            justify-content: center;
            margin: 25px 20px 20px 0;
            min-height: 182px;
            padding: 8px 10px 8px 0;
            position: relative;

            @include breakpoint(medium up) {
                flex: 1 1 50%;
                flex-wrap: nowrap;
            }

            &:last-child {
                margin: 25px 0 20px;
            }

            &:hover {
                border-left: 10px solid map-get($foundation-palette, primary);
            }

            img {
                max-width: 160px;
                padding: 15px;

                @include breakpoint(medium up) {
                    max-width: 100%;
                    min-width: 50%;
                    padding: 0 30px 0 10px;
                }
            }

            h3 {
                font-size: rem-calc(17);
                line-height: rem-calc(17);
                padding-bottom: 5px;
                text-align: left;

                @include breakpoint(medium up) {
                    font-size: rem-calc(16);
                    font-size: rem-calc(17);
                    margin-top: 10px;
                    padding: 0;
                }
            }

            p {
                font-size: rem-calc(16);
                line-height: rem-calc(18);
                margin: 0;
                padding: 6px 6px 15px 0;
                text-align: left;

                @include breakpoint(medium up) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(16);
                    margin: 3px 0 0;
                }
            }
        }
    }
}

.wheelsForWinterConditionsModalTrigger {
    @include font-gotham-medium;
    align-items: center;
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;

    font-size: rem-calc(13);
    margin-top: 20px;
    text-transform: none;

    @include breakpoint(medium up) {
        bottom: -22px;
        left: 0;
        margin: 0;
        position: absolute;
    }
}

#PSOverlay {
    background: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    z-index: 1;

    &.visible {
        background: rgba(0, 0, 0, 0.5);
    }
}

.trailer-banner-selector {
    #PSOverlay {
        z-index: 0;
    }
}

h2.mobile-modal-title {
    align-items: center;
    color: map-get($foundation-palette, secondary);
    display: flex;
    font-size: 22px;
    height: 64px;
    margin: 0;
    padding: 0 20px;
    text-transform: uppercase;
}

#psMobileModal,
#psMobile {
    background: map-get($foundation-palette, white-default);
    margin: 0;

    > li > a {
        @include font-gotham-bold;
        align-items: center;
        background: map-get($foundation-palette, white-default);
        color: map-get($foundation-palette, primary);
        display: flex;

        font-size: 21px;
        height: 64px;
        padding: 0 20px;

        @include breakpoint(medium up) {
            display: none;
        }
    }

    >li {
        border-top: 1px solid map-get($foundation-palette, light-gray);

        @include breakpoint(medium up) {
            border: 0;
        }

        &:first-child {
            border: 0;
        }

        a {
            color: map-get($foundation-palette, secondary);
            font-size: 22px;

            &.wheelsForWinterConditionsModalTrigger {
                color: map-get($foundation-palette, primary);
                font-size: rem-calc(13);
            }
        }
    }

    .menu.nested {
        margin: 0;
    }
}

#productSelectorWrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(large up) {
        flex-direction: row;
    }

    .ps-left {
        color: map-get($foundation-palette, white-default);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 0;
        margin: 0 auto 35px;
        margin-bottom: 35px;
        max-width: 600px;
        position: relative;
        text-align: center;
        transition: all 0.5s ease-in-out;
        width: 100%;

        @include breakpoint(medium up) {
            margin: 0 auto 45px;
        }

        @include breakpoint(large up) {
            align-items: center;
            justify-content: left;
            margin: 0;
            max-width: 100%;
            padding-right: 50px;
            text-align: left;
            width: 50%;
        }

        h2 {
            @include font-gotham-bold;

            font-size: rem-calc(46);
            line-height: 1.2em;
            text-transform: uppercase;


            @include breakpoint(large up) {
                font-size: rem-calc(67);
            }
        }

        p {
            @include font-gotham-medium;

            font-size: rem-calc(18);
            line-height: 1.2em;
            margin: 10px 0 0;

            @include breakpoint(medium up) {
                font-size: rem-calc(16);
            }

            @include breakpoint(large up) {
                font-size: rem-calc(18);
            }
        }
    }

    .ps-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        left: 0;
        position: relative;
        transition: all 0.5s ease-in-out;

        @include breakpoint(large up) {
            align-items: center;
            justify-content: flex-end;
            width: 50%;
        }
    }

}


#productSelectorContent {
    max-width: 100%;
    transition: all 0.5s ease-in-out;
    width: 100%;

    @include breakpoint(medium up) {
        margin: 0;
        max-width: 100%;
    }

    @include breakpoint(large up) {
        margin: 0;
        max-width: 610px;
    }

    &.inpage {
        .vehicleSelectorFindTire {
            display: none;

            @include breakpoint(medium up) {
                display: none;

                &.trailer-tire-hint {
                    display: block;
                }
            }

            &.trailer-tire-hint {
                display: block;
            }
        }
    }

    .main-selector-tabs {
        align-items: flex-end;
        display: flex;
        width: 100%;
    }

    .selector {
        background: map-get($foundation-palette, white-default);
        padding: 15px;
        position: relative;
        width: 100%;

        @include breakpoint(medium up) {
            display: none;
            padding: 30px;
        }

        &.active {
            background: map-get($foundation-palette, white-default);
            display: block;
        }
    }

    .selector-tab {
        align-items: center;
        background: map-get($foundation-palette, light-gray);
        cursor: pointer;
        display: flex;
        margin-top: 15px;
        padding: 15px;
        position: relative;

        div:not(.selector-description),
        button {
            align-items: center;
            display: flex;
        }

        &:first-child {
            margin-top: 0;
        }

        &:hover::before {
            border-left: 10px solid map-get($foundation-palette, primary);
            bottom: 0;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .selector-image {
        display: none;
        float: left;
        margin-right: 15px;
        max-width: 110px;
        overflow: hidden;
        width: 100%;

        @include breakpoint(medium up) {
            display: block;
            float: left;
            max-width: 220px;
            min-width: 120px;
            overflow: hidden;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .selector-description {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        float: left;
        justify-content: center;
        width: 100%;

        @include breakpoint(medium up) {
            align-items: flex-start;
            width: 56%;
        }

        h3 {
            @include font-gotham-bold;
            color: map-get($foundation-palette, secondary);

            font-size: 17px;
            line-height: 17px;
            max-width: 100%;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: 20px;
            }
        }

        p {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: 15px;
            line-height: 18px;
            margin: 0;
            max-width: 100%;
            padding: 0;
        }
    }

    .main-selector-tab {
        @include font-gotham-bold;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-grow: 1;

        float: left;
        font-size: rem-calc(19);
        height: 44px;
        justify-content: center;
        margin-left: 2px;
        padding: 0 20px;

        &:first-child {
            bottom: 0;
            margin: 0;
        }

        &.active {
            background: map-get($foundation-palette, white-default);
            height: 48px;

            button {
                color: map-get($foundation-palette, secondary);
            }
        }

        button {
            color: map-get($foundation-palette, white-default);
            text-transform: uppercase;
        }
    }

    .selector-tabs {
        width: 100%;
    }

    .looking-for-other-tires {
        padding-bottom: 15px;
        padding-top: 30px;

        &.trailer-page-redirect {
            bottom: -22px;
            left: auto;
            padding-bottom: 0;
            padding-top: 0;
            right: 0;

            @include breakpoint(medium up) {
                position: absolute;
            }

            .looking-for-other {
                @include font-gotham-medium;

                font-size: rem-calc(13);
            }
        }
    }

    .productSelector {
        display: none;
        position: relative;

        @include breakpoint(small only) {
            &.staggered-active {
                &[data-frontrear="front"] .add-rear-size-cta {
                    display: none !important;
                }

                &[data-frontrear="rear"] .add-rear-size-cta {
                    display: block !important;
                }
            }
        }

        @include breakpoint(medium up) {
            min-height: 220px;

            &[data-categoryid="tires"] {
                padding-bottom: 15px;

                .add-rear-size,
                .findTireSizeModalTrigger {
                    bottom: -15px;
                }
            }
        }

        &[data-selectortype="wheel_size"] .findTireSizeModalTrigger {
            display: none;
        }

        &.active {
            display: block;
        }

        .front-rear-mobile {
            display: none;
        }

        &.staggered-active {
            @include breakpoint(small only) {
                display: block !important;

                .front-rear-mobile {
                    display: block;
                }
            }
        }
    }

    .selector_close {
        color: map-get($foundation-palette, primary);
        cursor: pointer;
        display: none;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(medium up) {
            display: block;
            line-height: 25px;
        }
    }

    .front-rear {
        @include clearfix;
        display: none;

        @include breakpoint(medium up) {

            position: absolute;
            top: 12px;


            a {
                @include font-gotham-medium;

                color: #999;
                font-size: 13px;
            }

            >div {
                border-left: 1px solid #ccc;
                float: left;
                line-height: 1em;

                a {
                    padding: 0 10px;
                }

                &:first-child {
                    border: 0;

                    a {
                        padding-left: 0;
                    }
                }
            }
        }

        a {
            @include font-gotham-bold;

            font-size: 14px;
        }

        &.staggered-active {
            @include breakpoint(small only) {
                display: none !important;
            }
        }

        &.active {
            display: block;
        }

        >div.active a {
            background: transparent;
            color: map-get($foundation-palette, primary);
        }
    }

    .front-rear-mobile {
        @include font-gotham-bold;

        font-size: 14px;
        margin-bottom: 20px;
        text-transform: uppercase;

        .rear {
            margin-top: 20px;
        }
    }

    .productSelector {
        &[data-selectortype="tire_size"],
        &[data-selectortype="wheel_size"] {
            .add-rear-size-cta {
                display: block;
            }
        }

    }

    .add-rear-size-cta {
        display: none;

        &.mobile-only {
            margin-bottom: 15px;

            .add-rear-size {
                bottom: 0;
                margin: 0;
                position: relative;

                a {
                    font-size: 16px;
                    text-transform: none;

                    &::after {
                        content: "\f105";
                        font-family: "fontello";
                        font-size: 12px;
                        padding: 0 5px;
                        position: relative;
                    }
                }
            }
        }
    }

    .selector_options_mobile {
        border: 1px solid map-get($foundation-palette, light-gray);
        border-top: 0;
        display: flex;
        padding: 15px 15px 10px;

        .findTireSizeModalTrigger {
            display: none !important;
        }

        a {
            @include font-gotham-book;
            align-items: center;
            background: map-get($foundation-palette, light-gray);
            color: map-get($foundation-palette, secondary) !important;
            display: flex;
            font-size: 14px;
            justify-content: center;
            line-height: rem-cal(18);
            margin: 0 0 5px;

            min-height: 40px;
            padding: 0 5px;
            text-align: center;

            &.wheelsForWinterConditionsModalTrigger {
                background: transparent;
                color: map-get($foundation-palette, primary) !important;
                flex-wrap: nowrap;
                font-size: rem-calc(16);
                font-weight: bold;
                line-height: rem-calc(18);
                padding: 15px 0 6px;
                text-align: left;

                .questionmarkcircle {
                    color: map-get($foundation-palette, primary);
                    margin-right: 10px;
                    width: 30px;
                }
            }

            &.pagedirect {
                display: none;
            }
        }

        .resultsCol {
            border: 0;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }

    .selector_steps {
        @include clearfix;

        @include breakpoint(medium up) {
            padding-right: 30px;
        }

        .step {
            color: map-get($foundation-palette, primary);
            float: left;
            width: 100%;

            @include breakpoint(medium up) {
                width: auto;

                &::before {
                    color: map-get($foundation-palette, midgrey);
                    content: "\f105";
                    font-family: "fontello";
                    font-size: 12px;
                    padding: 0 10px;
                    position: relative;
                    top: -2px;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }

            &[data-current-key=VEHICLE_NAME] {
                display: none;
            }

            &.chosen a {
                cursor: pointer;
            }

            > a {
                @include font-gotham-medium;

                color: #999;
                cursor: default;
                font-size: 18px;

                @include breakpoint(medium up) {
                    font-size: 16px;
                    text-transform: none;
                }
            }

            .selector_options_mobile {
                display: none !important;
            }

            &:first-child {
                .steplink {
                    border-top: 1px solid map-get($foundation-palette, light-gray);

                    @include breakpoint(medium up) {
                        border: 0;
                    }
                }
            }
        }

        .current {
            .selector_options_mobile {
                display: flex !important;
                flex-direction: column;
                flex-wrap: wrap;

                @include breakpoint(medium up) {
                    display: none !important;
                }
            }

            a {
                color: map-get($foundation-palette, primary);
            }
        }

        .chosen {
            a {
                color: map-get($foundation-palette, secondary);
            }

            .selector_options_mobile {
                display: none !important;
            }
        }

        .steplink {
            align-items: center;
            border: 1px solid map-get($foundation-palette, light-gray);
            border-top: 0;
            display: flex;
            justify-content: center;
            min-height: 50px;
            text-align: center;

            @include breakpoint(medium up) {
                border: 0;
                display: inline-block;
                height: auto;
                min-height: 0;
                text-align: initial;
            }
        }
    }
}

.inline-question-mark {
    display: inline-box;
    margin: 0 0 0 10px;

    .questionmarkcircle {
        border-width: 2px;
        height: 18px !important;
        margin: 0;
        position: relative;
        top: -2px;
        width: 18px !important;
    }
}

.newsearch-tireselection {
    @include breakpoint(large up) {
        float: right;
        padding: 0 25px 0 20px;
    }
}

.pdp-main {
    .psresults-wrapper {
        @include breakpoint(medium down) {
            padding: 50px 0 10px;
        }
    }
}

.psresults-wrapper {
    padding: 50px 0 1.875rem;

    @include breakpoint(medium up) {
        padding-bottom: 2.5rem;
    }

    &.small-top {
        @include breakpoint(small only) {
            padding: 0 0 1.875rem;

            .psresults-title-border {
                border-bottom: 1px solid #ccc;
            }

            h2.psresults-title {
                margin: 0;
                padding: 18px 0;
            }
        }
    }

    h2 {
        @include font-gotham-bold;

        font-size: 21px;
        margin-bottom: 35px;
        text-transform: uppercase;

        @include breakpoint(medium up) {
            font-size: 26px;
        }
    }

    .selection-information {
        width: 100%;

        @include breakpoint(medium up) {
            display: block !important;
        }

    }

    .sel-info-wrap {
        background: map-get($foundation-palette, light-gray);
        padding: 10px;
        position: relative;

        .questionmarkcircle {
            @include font-gotham-bold;
            border-width: 3px;

            color: map-get($foundation-palette, primary);
            height: 21px;
            width: 21px;
        }
    }

    .car-image-col {
        @include breakpoint(small only) {
            position: absolute;
            right: 10px;
            top: 20px;
            z-index: 1;
        }

        @include breakpoint(medium up) {
            position: relative;
        }
    }

    .car-image {
        align-items: center;
        box-sizing: initial;
        display: flex;
        flex-wrap: wrap;
        height: 100% !important;
        justify-content: center;
        position: relative;


        @include breakpoint(medium up) {
            bottom: 0;
            padding: 0 30px 0 10px;
            position: absolute;
            top: 0;
            width: calc(100% - 50px);

            @media screen and (min-width:0\0) {
                width: 100%;
            }
        }

        @include breakpoint(large up) {
            display: flex;
            height: auto;
        }

        .loader {
            @include breakpoint(small only) {
                display: none !important;
            }
        }

        p {
            margin: 0;
        }

        .ps-results-image-tip {
            border: 0;
            position: absolute;
            right: -18px;
            top: -8px;
            width: 22px;

            @include breakpoint(medium up) {
                left: -10px;
                top: 0;
            }

            span.questionmarkcircle {
                height: 16px;
                width: 16px;
            }
        }
    }

    .minimized-vehicle-vizualizer {
        .sel-title {
            @include font-gotham-bold;

            font-size: 1rem;

            @include breakpoint(small only) {
                font-size: 0.875rem;
            }

            span {
                @include font-gotham-book;
            }

            a {
                font-size: 0.8125rem;

                @include breakpoint(small only) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    .sel-info.row {
        background: map-get($foundation-palette, white-default);
        padding-bottom: 12px;
        padding-top: 12px;
        position: relative;

        &.left-arrow {
            &::before {
                border-bottom: 12px solid transparent;
                border-right: 12px solid map-get($foundation-palette, white-default);
                border-top: 12px solid transparent;
                bottom: 0;
                content: "";
                display: none;
                height: 0;
                left: -12px;
                margin: auto;
                position: absolute;
                top: 0;
                width: 0;

                @include breakpoint(medium up) {
                    display: block;
                }
            }
        }

        .init-selector {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: 13px;

            @include breakpoint(large up) {
                margin-top: 29px;
            }
        }

        .info-modal {
            margin: 0;

            .findTireSizeModalTrigger {
                @include font-gotham-medium;
                align-items: center;
                color: map-get($foundation-palette, midgrey);

                display: flex;
                font-size: 13px;
                margin-top: 15px;
            }
        }

        .left-info {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            padding: 0 0 10px;

            @include breakpoint(medium) {
                padding: 10px;
            }

            @include breakpoint(large up) {
                padding: 10px 0;
            }

            .sel-title {
                @include font-gotham-bold;

                font-size: 17px;
                margin: 0;

                @include breakpoint(small only) {
                    min-height: 56px;
                }

                @include breakpoint(large up) {
                    font-size: 18px;
                }

                &.nominheight {
                    min-height: initial;
                }

                a {
                    color: map-get($foundation-palette, secondary);
                    font-size: 13px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .sel-subtitle {
            @include font-gotham-book;

            font-size: 13px;
            margin: 5px 0 0;
            padding-right: 50px;

            @include breakpoint(medium up) {
                padding: 0;
            }

            @include breakpoint(large up) {
                font-size: rem-calc(11.5);
                line-height: 16.5px;
            }

            strong {
                @include font-gotham-medium;

                font-weight: normal;
            }

            span {
                border-bottom: 2px dotted map-get($foundation-palette, primary);
            }

            .separator {
                border: 0;
                padding: 0 10px;
            }
        }

        .right-info {
            @include breakpoint(small only) {
                padding: 0;
            }

            > div {
                display: flex;
                flex-wrap: wrap;

                @include breakpoint(large up) {
                    border-left: 1px solid map-get($foundation-palette, light-gray);
                    height: 100%;
                }
            }
        }
    }
}

.tire-type-content {
    #productGridUrl {
        display: none;
    }

    .left-icon-wrapper {
        @include font-gotham-medium;

        font-size: 12px;
        line-height: 1em;
        margin: 0;

        [class^="icon-"] {
            color: map-get($foundation-palette, primary);
            float: left;
            font-size: 23px;
            margin-right: 12px;
        }
    }

    .vcenter {
        align-items: center;
        display: flex;
    }

    .tire-types {
        display: none;
        margin-top: 60px;
    }

    .tire-types.packages {
        display: block;
    }


    .tires-guided-path {
        margin-bottom: 30px;

        @include breakpoint(medium only) {
            > img {
                max-width: 50%;
                padding-right: 1.3rem;
            }
        }

        &.category-select-first-step {
            background: $white;
            border: 10px solid $light-gray;
            padding: 40px 20px 30px;
            position: relative;
            text-align: center;
            transition: background-color 100ms;

            @include breakpoint(large up) {
                .image {
                    display: inline-block;
                }
            }

            .image {
                display: none;
            }

            .guided-section-head {
                margin-bottom: 20px;

                h3 {
                    font-size: 20px;
                }
            }

            .arrow {
                background: $light-gray;
                bottom: -20px;
                display: none;
                height: 40px;
                left: 50%;
                margin-left: -30px;
                position: absolute;
                transform: rotate(45deg);
                width: 40px;
            }

            &.active {
                opacity: 1;

                + .tire-types {
                    opacity: 1;
                }
            }

            &:hover {
                background: $light-gray;

                .button {
                    background: $button-accessible-hover;
                }
            }
        }

        img {
            height: 50px;
            margin-bottom: 20px;
        }

        .button {
            text-transform: uppercase;
        }
    }

    .mid-line-copy {
        @include font-gotham-book;
        color: map-get($foundation-palette, secondary);
        font-size: 16px;

        margin: 25px 0;

        @include breakpoint(medium up) {
            font-size: 18px;
            margin: 50px 0;
        }

        @include breakpoint(large up) {
            font-size: 18px;
        }
    }

    .tire-types-section-head {
        @include clearfix;
        margin-bottom: 10px;

        h3 {
            font-size: 18px;

            @include breakpoint(large up) {
                font-size: 22px;
            }
        }

        p {
            font-size: 16px;
        }
    }

    .category-column-wrapper {
        padding-bottom: 15px;
    }

    .cat-types-cta {
        margin-top: 35px;
    }

    .result_btn {
        margin-bottom: 30px;

        @include breakpoint(large up) {
            margin-bottom: 0;
        }

        button {
            font-size: 13px;
            margin: 0;
            text-transform: uppercase;
        }

        .button[disabled] {
            background: map-get($foundation-palette, midgrey);
            border: 0;
        }
    }

    .selector_checkbox {
        border: 5px solid map-get($foundation-palette, light-gray);
        border-left-width: 8px;
        margin: 0 0 15px;
        padding: 15px 70px 15px 30px;
        position: relative;
        width: 100%;

        @include breakpoint(large) {
            &:hover {
                border-left-width: 13px;
                cursor: pointer;

                .tiretype-indicator {
                    left: -13px;
                    width: 13px;
                }
            }
        }

        label {
            display: none;
            left: -200px;
            position: relative;
            width: 0;
        }

        h3 {
            @include font-gotham-bold;
            display: inline-block;

            font-size: 16px;
            text-transform: uppercase;

            @include breakpoint(medium up) {
                font-size: 18px;
            }

            @include breakpoint(large up) {
                font-size: 20px;
            }

            [class^="icon-"] {
                color: map-get($foundation-palette, primary);
                font-size: 23px;
                position: relative;
                top: -3px;
            }
        }

        p,
        .has-tip {
            @include font-gotham-medium;
            color: map-get($foundation-palette, midgrey);

            font-size: 12px;
            margin: 0;

            @include breakpoint(medium up) {
                font-size: 14px;
            }
        }

        p {
            display: flex;

            @include breakpoint(medium up) {
                display: inline-flex;
                margin-inline-start: 12px;
            }
        }

        .has-tip {
            border-bottom: 0;
            color: map-get($foundation-palette, secondary);
            font-weight: normal;
            line-height: 1em;

            &:hover {
                border-bottom: 1px solid map-get($foundation-palette, secondary);
            }
        }

        .fa {
            align-items: center;
            bottom: 0;
            color: map-get($foundation-palette, light-gray);
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            margin: auto;
            position: absolute;
            right: 20px;
            top: 0;

            &::before {
                border: 3px solid map-get($foundation-palette, light-gray);
                border-radius: 50%;
                padding: 5px;
            }
        }

        .tiretype-indicator {
            background: map-get($foundation-palette, light-gray);
            height: calc(100% + 10px);
            left: -8px;
            position: absolute;
            top: -5px;
            width: 8px;
        }

        &.catcolor-all-weather {
            .tiretype-indicator {
                background: #bf1a8d;
            }
        }

        &.catcolor-winter {
            .tiretype-indicator {
                background: #20c3f3;
            }
        }

        &.catcolor-3-season,
        &.catcolor-all-season {
            .tiretype-indicator {
                background: #fbb116;
            }
        }

        &.catcolor-performance {
            .tiretype-indicator {
                background: #6353a3;
            }
        }

        &.catcolor-touring {
            .tiretype-indicator {
                background: #00a651;
            }
        }

        &.catcolor-mud-terrain {
            .tiretype-indicator {
                background: #422800;
            }
        }

        &.catcolor-sport-truck {
            .tiretype-indicator {
                background: #9ecf7c;
            }
        }

        &.catcolor-all-terrain {
            .tiretype-indicator {
                background: #7a5226;
            }
        }

        &.catcolor-commercial {
            .tiretype-indicator {
                background: #a7a9ac;
            }
        }

        &.catcolor-ultra-high-performance {
            .tiretype-indicator {
                background: #a499ca;
            }
        }
    }
}

.selector_checkbox.selected {
    .fa {
        color: map-get($foundation-palette, primary);
        display: flex;

        &::before {
            border-color: map-get($foundation-palette, primary);
        }
    }
}


.tooltip.oem-tip {
    @include font-gotham-book;

    background: map-get($foundation-palette, white-default);
    box-shadow: 0 0 55px -16px rgba(0, 0, 0, 0.75);
    color: map-get($foundation-palette, secondary);
    font-size: 12px;
    max-width: 230px !important;
    min-width: 230px !important;
    padding: 0;

    // by default appears below (bottom)
    &::before {
        border: inset 0.45rem;
        border-bottom-style: solid;
        border-color: transparent transparent map-get($foundation-palette, primary);
    }

    h2 {
        @include font-gotham-medium;
        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);

        font-size: 13px;
        line-height: 30px;
        margin: 0;
        padding: 0 15px;
    }

    p {
        @include font-gotham-book;

        font-size: 12px;
        margin: 0;
        padding: 15px;
    }

    &.top {
        &::before {
            border: inset 0.45rem;
            border-color: map-get($foundation-palette, white-default) transparent transparent transparent;
            border-top-style: solid;
        }
    }

    &.bottom {
        &::before {
            border: inset 0.45rem;
            border-color: transparent transparent map-get($foundation-palette, primary) transparent;
            border-top-style: solid;
        }
    }

    &.right {
        &::before {
            border: inset 0.45rem;
            border-color: transparent map-get($foundation-palette, white-default) transparent transparent;
            border-right-style: solid;
        }
    }

    &.left {
        &::before {
            border: inset 0.45rem;
            border-color: transparent transparent transparent map-get($foundation-palette, white-default);
            border-left-style: solid;
        }
    }
}

.tooltip.cattype-tip {
    @include font-gotham-book;

    background: map-get($foundation-palette, white-default);
    box-shadow: 0 0 55px -16px rgba(0, 0, 0, 0.75);
    color: #000;
    font-size: 12px;
    max-width: 380px !important;
    min-width: 230px !important;
    padding: 0;

    // by default appears below (bottom)
    &::before {
        border: inset 0.45rem;
        border-bottom-style: solid;
        border-color: transparent transparent map-get($foundation-palette, primary);
    }

    h2 {
        @include font-gotham-medium;
        border-bottom: 3px solid map-get($foundation-palette, primary);
        color: map-get($foundation-palette, secondary);

        font-size: 13px;
        line-height: 30px;
        margin: 0;
        padding: 0 15px;
    }

    p {
        @include font-gotham-book;

        font-size: 12px;
        margin: 0;
        padding: 15px;

        img {
            float: left;
            max-width: 130px;
        }
    }

    &.top {
        &::before {
            border: inset 0.45rem;
            border-color: map-get($foundation-palette, white-default) transparent transparent transparent;
            border-top-style: solid;
        }
    }

    &.bottom {
        &::before {
            border: inset 0.45rem;
            border-color: transparent transparent map-get($foundation-palette, primary) transparent;
            border-top-style: solid;
        }
    }

    &.right {
        &::before {
            border: inset 0.45rem;
            border-color: transparent map-get($foundation-palette, white-default) transparent transparent;
            border-right-style: solid;
        }
    }

    &.left {
        &::before {
            border: inset 0.45rem;
            border-color: transparent transparent transparent map-get($foundation-palette, white-default);
            border-left-style: solid;
        }
    }
}

.tooltip.disabled-email-tip {
    @include font-gotham-book;

    background: map-get($foundation-palette, white-default);
    box-shadow: 0 0 55px -16px rgba(0, 0, 0, 0.75);
    color: map-get($foundation-palette, secondary);
    font-size: rem-calc(12);
    max-width: 246px !important;
    min-width: 246px !important;
    padding: 15px;

    // by default appears below (bottom)
    &::before {
        border: inset 0.45rem;
        border-bottom-style: solid;
        border-color: transparent transparent $white;
    }

    p {
        @include font-gotham-book;

        font-size: rem-calc(12);
        line-height: rem-calc(15);
        margin: 0;
        padding: 0 0 2px;
    }

    .orange-phone,
    .pale-grey-text {
        padding: 0;
    }

    .orange-phone {
        @include font-gotham-bold;
        color: map-get($foundation-palette, primary);

        font-size: rem-calc(11);
    }

    .pale-grey-text {
        color: map-get($foundation-palette, midgrey);
    }
}

#guidedSellingPathWrapper {
    display: none;

    .gspAnswer {
        &.selected {
            border: 4px solid map-get($foundation-palette, primary);
        }
    }
}


.ps-landing-content-wrapper {
    padding: 60px 0;

    @include breakpoint(large up) {
        padding: 90px 0;
    }

    img {
        display: block;
        height: auto;
        margin-bottom: 20px;
        width: 100%;
    }

    h2 {
        @include font-gotham-bold;

        font-size: 22px;
        margin-bottom: 0;
        text-align: center;

        @include breakpoint(medium up) {
            font-size: 21px;
            margin-bottom: 25px;
            text-align: left;
        }

        @include breakpoint(large up) {
            font-size: 22px;
        }
    }

    h3 {
        @include font-gotham-bold;
        color: map-get($foundation-palette, secondary);

        font-size: 17px;

        @include breakpoint(medium up) {
            font-size: 18px;
        }

        @include breakpoint(large up) {
            font-size: 22px;
            padding: 0 10px;
        }
    }

    p {
        @include font-gotham-book;
        color: map-get($foundation-palette, secondary);

        font-size: 14px;

        @include breakpoint(large up) {
            padding: 0 10px;
        }

    }

    .row > div {
        margin-bottom: 45px;

        @include breakpoint(medium up) {
            margin-bottom: 0;
        }
    }
}

#oemarkingModal {
    border: 0;
    max-width: 380px;
    padding: 0;

    h2 {
        @include font-gotham-medium;

        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        font-size: rem-calc(13);
        margin-bottom: 10px;
        padding: 9px 15px;
        text-transform: uppercase;
    }

    p {
        font-size: rem-calc(12);
        padding: 0 15px;
    }

    .oemarkingModalCta {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(12);
        padding: 0 15px 15px;
        text-align: right;
    }
}

#fitmentModal {
    border: 0;
    max-width: 380px;
    padding: 0;

    h2 {
        @include font-gotham-medium;

        background: map-get($foundation-palette, primary);
        color: map-get($foundation-palette, white-default);
        font-size: rem-calc(13);
        margin-bottom: 10px;
        padding: 9px 15px;
        text-transform: uppercase;
    }

    p {
        font-size: rem-calc(12);
        padding: 0 15px;
    }

    .fitmentModalCta {
        color: map-get($foundation-palette, primary);
        font-size: rem-calc(12);
        padding: 0 15px 15px;
        text-align: right;
    }
}

.reveal.vcenter {
    left: 50% !important;
    position: absolute;
    top: 50% !important;
    transform: translate(-50%, -50%);
}
