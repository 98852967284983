.pt_product-search-result {
    .page-title {
        margin-bottom: 0;
    }

    .start-new-search-wrapper {
        text-align: end;

        .loader {
            z-index: 10;
        }
    }

    .start-new-search {
        margin-bottom: 0;
        margin-top: 10px;
        width: 100%;

        @include breakpoint(medium up) {
            margin-top: 20px 0;
            width: auto;
        }

        @include breakpoint(large up) {
            margin-top: 35px;
        }
    }

    .psresults-wrapper {
        margin-top: 10px;
        padding: 0 0 10px;
    }

    .matched-products {
        margin: 0 0 20px;

        p {
            @include font-gotham-bold;

            font-size: 14px;
            margin: 0;
        }

        span {
            color: map-get($foundation-palette, primary);
        }
    }

    .search-info {
        @include font-gotham-book;
        font-size: 18px;

        margin: 0 0 25px;
    }

    // search results tabs
    .tabs-content {
        border: 0;
    }

    .search-results-tabs {
        border-bottom: 6px solid map-get($foundation-palette, light-gray);
        display: block;
        margin: 0 0 30px;

        @include breakpoint(medium down) {
            display: flex;
        }

        .tabs-title {
            margin: 0 0 -6px;
            text-align: center;
            width: 50%;

            @include breakpoint(medium up) {
                width: auto;
            }

            @include breakpoint(medium down) {
                flex-grow: 1;
                width: auto;
            }

            > a {
                @include font-gotham-bold;
                color: map-get($foundation-palette, midgrey);

                font-size: 16px;

                @include breakpoint(medium down) {
                    font-size: rem-calc(14);
                    padding: 10px 0;
                }
            }

            a[aria-selected="true"] {
                background: transparent;
                color: map-get($foundation-palette, secondary);
            }

            &.is-active {
                border-bottom: 6px solid map-get($foundation-palette, primary);
            }
        }
    }

    .tabs-panel {
        padding: 0;

        .sort-by-wrapper {
            @include breakpoint(medium up) {
                justify-content: center;
            }

            @include breakpoint(large up) {
                background: transparent;
                justify-content: flex-end;
                margin: 0 0 30px;
                padding: 0;
            }
        }

        .sort-by {
            label {
                @include font-gotham-bold;

                color: map-get($foundation-palette, secondary);
            }
        }
    }

    .article-result {
        @include font-gotham-medium;

        border-bottom: 1px solid $medium-gray;
        margin-bottom: 20px;
        padding-bottom: 20px;

        p {
            @include font-gotham-medium;

            color: map-get($foundation-palette, midgrey);
            margin: 0;

            @include breakpoint(medium down) {
                @include font-gotham-book;

                color: $black;
            }
        }

        a {
            font-size: rem-calc(12);
        }
    }

    .sort-by {
        select {
            @include font-gotham-book;

            margin: 0;
        }
    }

    .sort-by-wrapper {
        margin-bottom: 10px;
    }

    #refinements-content-wrap {
        display: none;

        @include breakpoint(medium down) {
            background: map-get($foundation-palette, white-default);
            position: absolute;
            width: 100%;
            z-index: 3;
        }

        &.expanded {
            display: block;

            @include breakpoint(medium down) {
                box-shadow: 2px 4px 5px map-get($foundation-palette, disabled-grey);
            }
        }
    }

    #refinements-content-trigger {
        margin: 0;
        width: 100%;
    }

    .refinements-wrapper {
        margin: 0 0 25px;
        position: relative;
    }

    .refinements {
        [class^="icon-"]::before,
        [class*=" icon-"]::before {
            margin: 0;
        }

        .icon-circle-thin,
        .icon-circle {
            color: map-get($foundation-palette, primary-accessible);
        }

        .refinements-content {
            border: 1px solid map-get($foundation-palette, light-gray);
            border-bottom: 0;
            padding-top: 44px;
            position: relative;

            @include breakpoint(large up) {
                padding-top: 0; 
            }
        }

        .clear-filters {
            @include font-gotham-bold;
            border-bottom: 1px solid map-get($foundation-palette, light-gray);

            font-size: 14px;
            line-height: 1;
            padding: 15px 0 15px 15px;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .is-accordion-submenu-parent {
            border-bottom: 1px solid map-get($foundation-palette, light-gray);

            > a {
                font-size: 14px;
                padding: 15px 0 15px 37px;
            }
        }

        .is-accordion-submenu {
            margin: -5px 0 10px 15px;
        }

        .is-accordion-submenu-parent[aria-expanded="true"] > a::before {
            content: "\e80c";
            font-family: "fontello";
        }

        .is-accordion-submenu-parent > a {
            &::after {
                display: none;
            }

            &::before {
                color: map-get($foundation-palette, primary-accessible);
                content: "\e80b";
                font-family: "fontello";
                left: 15px;
                position: absolute;
            }
        }

        li {
            a {
                color: #000;
            }
        }

        .is-submenu-item {
            a {
                @include font-gotham-book;
                display: block;

                font-size: 14px;
                line-height: 1.2;
                padding: 5px 0;
                text-transform: capitalize;
            }

            i {
                float: left;
                padding: 0 5px 1px 0;
            }
        }
    }

    .is-accordion-submenu li.selected a {
        color: map-get($foundation-palette, secondary);
    }

    // Need Help and Resource Centre
    .info-box {
        margin: 50px 0 40px;

        @include breakpoint(large up) {
            background: map-get($foundation-palette, darkgrey1);
            color: map-get($foundation-palette, white-default);
            padding: 20px 14px;

            .button {
                margin: 0;
                padding: 15px 10px;
            }
        }

        @include breakpoint(xlarge up) {
            padding: 20px;
        }

        .button {
            span {
                font-size: 11px;
            }
        }

        h3 {
            font-size: 18px;
            margin: 0 0 10px;
        }

        p {
            font-size: 12px;
            margin: 0 0 15px;
        }
    }

    // Live Chat box
    .live-chat-box {
        background: map-get($foundation-palette, light-gray);
        border: 2px solid map-get($foundation-palette, border-grey);
        margin: 50px 0 40px;
        max-height: 375px;
        overflow: hidden;
        padding: 20px 14px 0;
        width: 210px;

        @include breakpoint(small only) {
            margin: 50px 20px 40px;
            max-height: 405px;
            width: 315px;
        }

        h3 {
            font-size: 18px;
        }

        .button-wrapper {
            display: flex;

            .button {
                margin: 0;
                padding: 15px 10px;
                width: 180px;

                @include breakpoint(small only) {
                    width: 280px;
                }

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
        }

        .image-wrapper {
            margin-bottom: 0;
            padding-top: 32px;

            @include breakpoint(small only) {
                max-height: fit-content;
                max-width: fit-content;
            }
        }
    }

    .live-chat-mobile {
        display: flex;
        justify-content: center;
    }

    .need-help-box {
        .button {
            position: relative;

            span {
                font-size: 11px;
                padding: 0 0 0 25px;
            }
        }

        .icon-livechat {
            bottom: 0;
            font-size: 18px;
            height: 18px;
            margin: auto;
            position: absolute;
            top: 0;
        }
    }

    // Pages tab
    .search-results-content {
        @include breakpoint(medium up) {
            padding: 0 10px 0 0;
        }

        .folder-content-list {
            margin: 0;

            li {
                margin: 0 0 50px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .content-title {
            font-size: calc-rem(18);
            line-height: 1;
            margin: 0 0 15px;
        }

        .readmore {
            @include font-gotham-bold;
        }
    }

    .resource-center {
        h3 {
            font-size: rem-calc(14);

            @include breakpoint(medium up) {
                font-size: rem-calc(22);
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }

        .button {
            margin: 30px 0 0;
        }
    }

    // Change location
    .change-location-box {
        background: rgba(0, 0, 0, 0.9);
        bottom: 0;
        color: map-get($foundation-palette, white-default);
        left: 0;
        min-height: 50px;
        padding: 10px 0;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: 10;

        @include breakpoint(small only) {
            text-align: left;
        }

        p {
            font-size: rem-calc(14);
            line-height: 35px;
            margin: 0;

            @include breakpoint(small only) {
                line-height: 1.4;
            }
        }

        .button {
            margin: 0 0 0 10px;
            padding: 10px 15px;

            @include breakpoint(small only) {
                margin: 10px 0 0;
            }
        }
    }

    .pagination {
        @include breakpoint(small only) {
            ul {
                text-align: center;
            }

            li {
                margin: 0;
                padding: 6px;
            }
        }
    }
}

#newSearchModal {
    padding: 0;

    @include breakpoint(large up) {
        padding: 0;
    }

    .selector-image {
        display: none;
    }

    .add-rear-size,
    .findTireSizeModalTrigger,
    .looking-for-other {
        @include font-gotham-medium;
        align-items: center;
        color: map-get($foundation-palette, primary);
        display: flex;
        flex-direction: inherit;
        flex-wrap: wrap;

        font-size: rem-calc(13);
        margin-top: 20px;
        text-transform: none;

        @include breakpoint(medium up) {
            bottom: -22px;
            left: 0;
            margin: 0;
            position: absolute;
        }
    }

    .looking-for-other {
        display: block;
        font-size: rem-calc(16);
        position: initial;
    }

    .close_button {
        color: map-get($foundation-palette, primary);
        cursor: pointer;
        display: none;
        font-size: 18px;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(medium up) {
            display: block;
            line-height: 25px;
        }
    }
}

@include breakpoint(medium up) {
    .pt_product-search-result {
        .matched-products {
            margin: 0;

            p {
                font-size: 18px;
            }
        }

        .refinements-wrapper {
            margin: 0 0 45px;
        }
    }
}

@include breakpoint(large up) {
    .pt_product-search-result {
        .sort-by-wrapper {
            background: map-get($foundation-palette, secondary);
            color: map-get($foundation-palette, white-default);
            margin-bottom: 55px;
            padding-bottom: 20px;

            padding-top: 20px;
        }

        .sort-by {
            label {
                color: map-get($foundation-palette, white-default);
                font-size: 18px;
            }
        }

        #refinements-content-wrap {
            display: block;
        }

        .refinements {
            .refinements-content,
            .is-accordion-submenu-parent,
            .clear-filters {
                border: 0;
            }

            .is-accordion-submenu-parent > a {
                padding: 12px 0 12px 20px;

                &::before {
                    left: 0;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .is-accordion-submenu {
                margin: 0;
            }

            .clear-filters {
                padding: 5px 0 10px;
            }
        }

        .refinement-title {
            font-size: 18px;
            margin: 0 0 15px;

            &.sizes-title {
                margin-top: 15px;
            }
        }
    }
}
