//Typography
h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-gotham-bold;

    line-height: 1em;
}

a {
    color: map-get($foundation-palette, primary-accessible);
}

.large-headline {
    @include font-gotham-bold;

    font-size: 40px;
    line-height: 40px;

    @include breakpoint(medium up) {
        font-size: 46px;
        line-height: 46px;
    }

    @include breakpoint(large up) {
        font-size: 66px;
        line-height: 66px;
    }
}

.intro-copy {
    @include font-gotham-medium;

    font-size: 16px;
    line-height: 20px;

    @include breakpoint(medium up) {
        font-size: 16px;
    }

    @include breakpoint(large up) {
        font-size: 18px;
        line-height: 22px;
    }
}

.small-intro-copy {
    @include font-gotham-medium;

    font-size: 16px;
    line-height: 18px;

    @include breakpoint(medium up) {
        font-size: 14px;
    }

    @include breakpoint(large up) {
        font-size: 16px;
        line-height: 20px;
    }
}

.light-intro-copy {
    @include font-gotham-book;

    font-size: 16px;
    line-height: 20px;

    @include breakpoint(large up) {
        font-size: 18px;
        line-height: 22px;
    }
}


.footer-links {
    @include font-gotham-bold;

    font-size: 14px;
    line-height: 18px;

    @include breakpoint(medium up) {
        @include font-gotham-medium;

        font-size: 13px;
    }

    @include breakpoint(large up) {
        font-size: 13px;
    }
}

.inline-link-alternate {
    color: map-get($foundation-palette, primary-accessible);
    text-decoration: underline;

    &:hover {
        color: map-get($foundation-palette, primary-dark-hover);
    }
}

.arrow-link {
    color: map-get($foundation-palette, secondary);
    font-weight: bold;

    &:hover {
        color: map-get($foundation-palette, secondary);
        text-decoration: underline;
    }

    &::after {
        color: map-get($foundation-palette, primary);
        content: " >";
    }
}

p,
.paragraph-copy {
    @include font-gotham-book;

    font-size: 14px;
    line-height: 18px;

    @include breakpoint(large up) {
        font-size: 15px;
        line-height: 20px;
    }
}

.small-copy {
    @include font-gotham-book;

    font-size: 12px;
    line-height: 16px;

    @include breakpoint(medium up) {
        font-size: 13px;
        line-height: 17px;
    }

    @include breakpoint(large up) {
        font-size: 14px;
        line-height: 18px;
    }
}

.tiny-copy {
    @include font-gotham-book;

    font-size: 10px;
    line-height: 14px;

    @include breakpoint(medium up) {
        font-size: 11px;
        line-height: 16px;
    }

    @include breakpoint(large up) {
        font-size: 12px;
    }
}

.tiny-headings {
    @include font-gotham-bold;
    font-size: 11px;
    line-height: 16px;

    text-transform: uppercase;
}

.page-title {
    @include font-gotham-bold;
    display: block;

    font-size: rem-calc(21);
    margin: 20px 0;
    text-transform: uppercase;

    @include breakpoint(medium up) {
        margin: 30px 0;
    }

    @include breakpoint(large up) {
        font-size: rem-calc(26);
        margin: 50px 0 40px;
    }
}

.pdp-main {
    .page-title {
        font-size: rem-calc(16);

        @include breakpoint(medium up) {
            margin: 30px 0 10px;
        }

        @include breakpoint(large up) {
            margin: 50px 0 10px;
        }
    }
}

.tip-symbol {
    $radius: rem-calc(17);
    $simbol-size: rem-calc(11);
    align-items: center;
    display: flex;

    @include breakpoint(medium up) {
        display: inline-flex;
        transform: translateY(rem-calc(-4));
    }

    &::before {
        align-items: center;
        border: 2px solid map-get($foundation-palette, primary);
        border-radius: $radius;
        color: map-get($foundation-palette, secondary);
        content: "?";
        display: inline-flex;
        font-size: $simbol-size;
        height: $radius;
        justify-content: center;
        line-height: $simbol-size;
        margin-right: 8px;
        width: $radius;
    }
}
